<template>
	<div v-if="internalComment" class="fw-bold text-uppercase text-truncate">
		<MessageIcon class="pe-1"/>
		{{ internalComment }}
	</div>
</template>

<script>
import MessageIcon from "@/core/icons/MessageIcon.vue";

export default {
	name: "ReservationInternalComment",
	components: {MessageIcon},
	props:{
		internalComment: {
			type: String,
			required: true
		}
	}
}
</script>

<style scoped>

</style>

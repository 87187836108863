<template>
	<div class="col-12">
		<ul class="nav nav-tabs align-items-center">

			<li class="nav-item" v-if="isTableBar">
				<button
					class="btn btn-outline-secondary btn-welcom btn-nav-tpv text-uppercase "
					style="height: 4.3rem;width: 5rem"
					:class="{'active': viewSectionBtns === 'viewTags'}"
					@click="handleSelectedSectionTags"
				>
					Ubicación en Barra
				</button>
			</li>
			<li class="nav-item">
				<button
					class="btn btn-outline-secondary btn-welcom btn-nav-tpv text-uppercase "
					style="height: 4.3rem;width: 5rem"
					:class="{'active': viewSectionBtns === 'viewPax'}"
					@click="handleSelectedSectionPax"
				>
					Numero de Pax
				</button>
			</li>

			<li class="nav-item mx-2" v-if="isTableBar && false">
				<div class="input-group" style="width: 20rem;">
					<span class="input-group-text" id="basic-addon1">
						<i class="fa-solid fa-tag"></i>
					</span>
					<input type="text" class="form-control" placeholder="Ubicación en Barra" aria-label="Username" aria-describedby="basic-addon1" v-model="locationTagName">
				</div>
			</li>

			<li class="nav-item px-2">
				<span class="h5" v-if="isTableBar && viewSectionBtns === 'viewTags'">
					Agrega la Ubicación de la zona
				</span>
				<span class="h5 text-wrap" v-else>
					Selecciona cuantos pax serán para la nueva cuenta
				</span>
			</li>
		</ul>
	</div>
	<div class="col-12 mt-1 h-100" v-if="viewSectionBtns === 'viewPax'">
		<div class="row g-0">
			<div
				class="col-3 col-lg-3 col-xl-2"
				v-for="(number, index) in groupedPax"
				:key="index"
			>
				<button
					class="btn btn-primary btn-welcom w-100 btn-tpv position-relative"
					style="height: 5rem;"
					@click="handleSelectedPax(number)"
					:disabled="isDisabledForBar"
				>

						<span class="fs-5">
							{{ number }}
						</span>
				</button>
			</div>

		</div>
	</div>
	<div class="col-12 mt-1 h-100" v-if="viewSectionBtns === 'viewTags'">
		<div class="row g-0">
			<div
				class="col-3 col-lg-3 col-xl-2"
				v-for="(reservationLocationTag, index) in reservationLocationTags"
				:key="index"
			>
				<button
					class="btn btn-primary btn-welcom w-100 btn-tpv position-relative"
					style="height: 5rem;"
					:class="{'active': newAccount.locationTag === reservationLocationTag.name}"
					@click="handleSelectedTag(reservationLocationTag.name)"
				>
					<span class="fs-5">
						{{ reservationLocationTag.name }}
					</span>
					<span
						v-if="newAccount.locationTag === reservationLocationTag.name"
						class="badge badge-info bottom-0 w-100 start-0 position-absolute"
						style="z-index: 1; top: auto"
					>
						Seleccionado
					</span>
				</button>
			</div>
			<div
				class="col-3 col-lg-3 col-xl-2"
			>
				<button
					class="btn btn-secondary btn-welcom w-100 btn-tpv position-relative"
					style="height: 5rem;"
					@click="handleSelectedOtherNameTag()"
				>
					<span class="fs-5">
						Otro
					</span>
				</button>
			</div>
		</div>
	</div>

	<!-- Bootstrap Modal -->
	<div class="modal fade" id="tpv-modal-location-tag" tabindex="-1" aria-labelledby="locationModalLabel" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered">
			<div class="modal-content">
				<div class="modal-body">
					<div class="input-group w-100">
          <span class="input-group-text" id="basic-addon1">
            <i class="fa-solid fa-tag"></i>
          </span>
						<input type="text" autofocus class="form-control" placeholder="Ubicación en Barra" aria-label="Ubicación en Barra" aria-describedby="basic-addon1" v-model="locationTagName">
					</div>

					<div class="d-flex pt-3 justify-content-end">
						<DismissButton :is-modal="true"/>
						<button :disabled="!locationTagName.length" class="btn btn-primary" @click="addLocationTag">
							Agregar
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
import store from "@/store";
import {mapGetters} from "vuex";
import {walkingService} from "@/service/walkingService";
import {hideAllModals, showModal} from "@/utils";
import DismissButton from "@/core/DismissButton.vue";

export default {
	name: "TpvSelectedPax",
	components: {DismissButton},
	data() {
		return {
			groupedPax: [
				1,
				2,
				3,
				4,
				5,
				6,
				7,
				8,
				9,
				10,
			],
			groupedTags: [
				{
					name: 'Sin Nombre'
				},
				{
					name: 'Grifos'
				},
				{
					name: 'Entrada'
				},
				{
					name: 'Escaleras'
				},
			],
			locationTagName: '',
			viewSectionBtns: 'viewTags',
			isSelectedTag: false
		};
	},
	computed: {
		...mapGetters('tpv', ['newAccount', 'viewBtnsSectionPax']),
		...mapGetters('copilot', ['tables','selectedRole']),
		...mapGetters('venue', ['reservationLocationTags']),

		isDisabled() {
			// this.locationTagName.trim().length < 2 ||
			return !this.isSelectedTag;
		},

		isTableBar() {
			if (!this.newAccount || !this.newAccount.tables || !this.tables) {
				return false;
			}

			return this.newAccount.tables.some(accountTableId => {
				const foundTable = this.tables.find(table => table.table_id === accountTableId);
				return foundTable && foundTable.room === "Barra";
			});
		},

		isDisabledForBar() {
			if (this.isTableBar) {
				return this.isDisabled;
			} else {
				return false;
			}
		}
	},
	mounted() {
		console.log('newAccount', this.newAccount);
		if (!this.isTableBar) {
			this.viewSectionBtns = 'viewPax';
			this.isSelectedTag = true;
		} else {
			this.viewSectionBtns = 'viewTags';
		}
	},
	methods: {
		async handleSelectedPax(numberPax) {
			console.log('newAccount', this.newAccount)
			if (!this.newAccount) {
				console.log('cuantos pax son? ', numberPax);
				store.commit('tpv/setIsSelectedPax', true)
				store.commit('tpv/setSelectedNumberPax', numberPax)
				store.commit("tpv/setViewSectionItems", "tables")
			} else {
				store.commit('tpv/setIsLoading', true)
				store.commit('tpv/setIsSelectedPax', true)
				store.commit('tpv/setSelectedNumberPax', numberPax)
				store.commit('tpv/updateNewAccount', {pax: numberPax, roleId: this.selectedRole })

				let resultWalkin = await walkingService.create(this.newAccount);
				console.log("El resultado de crear el walkin", resultWalkin);

				if (resultWalkin.result === 0) {
					console.log({result: resultWalkin});
					// store.commit("tpv/setAccountSelectedId", resultWalkin.reservation.id);
					store.commit("tpv/setViewSectionAccounts", 'allAccounts');
					store.commit('menu/setProductsCart', {productsCart: []});
					store.commit('tpv/setViewSectionItems', 'topChoice');
					store.commit('tpv/setNewAccount', null);
					store.commit('tpv/setIsLoading', false)

					this.isSelectedTag = false;
					this.viewSectionBtns = 'viewTags'

				} else {
					store.commit("copilot/setToast", {toast: {toastMessage: 'No se ha podido crear la cuenta.', showToast: true, idToast: "incorrectlyToast"}});
					store.commit('tpv/setIsLoading', false)
				}
				console.log('Se actualiza la cuenta?', this.newAccount)
			}
		},

		handleSelectedTag(nameTag) {
			if (nameTag === 'Sin Nombre') {
				store.commit('tpv/updateNewAccount', {locationTag: ''});
			} else {
				store.commit('tpv/updateNewAccount', {locationTag: nameTag});
			}
			this.isSelectedTag = true
			this.viewSectionBtns = 'viewPax'
		},

		handleSelectedSectionPax() {
			this.viewSectionBtns = 'viewPax'
		},

		handleSelectedSectionTags() {
			this.viewSectionBtns = 'viewTags'
		},

		handleSelectedOtherNameTag() {
			showModal('tpv-modal-location-tag');
		},

		addLocationTag() {
			store.commit('tpv/updateNewAccount', {locationTag: this.locationTagName});
			this.isSelectedTag = true;
			hideAllModals();
			this.viewSectionBtns = 'viewPax'
		}
	},
};
</script>

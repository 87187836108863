<template>
	<span v-if="reservationPaymentInfo.payments && reservationPaymentInfo.payments.length">
		<template v-for="payment in reservationPaymentInfo.payments" :key="payment.id">
			<MoneyBillIcon v-if="payment && payment.methodName === 'Efectivo' && payment.type === 'Local'" :class="{'text-success':payment.status === 100}"/>

			<CreditCardIcon v-if="payment && payment.methodName === 'Tarjeta' && payment.type === 'Local'" :class="{'text-success':payment.status === 100}"/>

			<MobileIcon v-if="payment && payment.type === 'Online'" :class="{'text-success':payment.status === 100}" class="fa-solid fa-mobile-screen-button"/>
		</template>
	</span>
</template>

<script>
import MoneyBillIcon from "@/core/icons/MoneyBillIcon.vue";
import CreditCardIcon from "@/core/icons/CreditCardIcon.vue";
import MobileIcon from "@/core/icons/MobileIcon.vue";

export default {
	name: "ReservationPaymentIcons",
	components: {MobileIcon, CreditCardIcon, MoneyBillIcon},
	props:{
		reservationPaymentInfo: {
			type: Object,
			required: true
		}
	}
}
</script>

<style scoped>

</style>

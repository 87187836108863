<template>
	<div class="d-flex gap-1">
		<WalkingIcon v-if="reservation.walkIn === 1"/>
		<SittingIcon v-if="reservation.walkIn === 2" fill-color="white"/>
		<QRIcon v-if="reservation.walkIn == null || reservation.walkIn === 0 && reservation.source !== 10"/>
		<PhoneIcon v-if="reservation.source === 10" class="fa-solid fa-phone"/>
	</div>
</template>

<script>
import SittingIcon from "@/core/icons/SittingIcon.vue";
import QRIcon from "@/core/icons/QRIcon.vue";
import WalkingIcon from "@/core/icons/WalkingIcon.vue";
import PhoneIcon from "@/core/icons/PhoneIcon.vue";

export default {
	name: "ReservationTypeIcons" ,
	components: {PhoneIcon, WalkingIcon, QRIcon, SittingIcon},
	props:{
		reservation:{
			type: Object,
			required: true
		}
	}
}
</script>

<style scoped>

</style>

const state = () => ({
	reservation: null,
});

const getters = {
	reservation: (state) => state.reservation,

	totalPrice: (state) => {
		if (!state.reservation || !state.reservation.paymentInfo) {
			return "0,00";
		}
		return state.reservation.paymentInfo.total.toFixed(2).replace(".", ",");
	},
	pendingPayment: (state) => {
		if (!state.reservation || !state.reservation.paymentInfo) {
			return "0,00";
		}
		return state.reservation.paymentInfo.pendingPayment.toFixed(2).replace(".", ",");
	},
};

const mutations = {
	setReservation: (state, reservation) => {
		state.reservation = reservation;
	},
};

const actions = {
	updateReservation({ commit }, reservation) {
		commit('setReservation', reservation);
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};

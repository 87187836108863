<template>
	<TableIcon style="font-size: 12px"/>
	<span>{{ tableDisplayName }}</span>
</template>

<script>
import TableIcon from "@/core/icons/TableIcon.vue";

export default {
	name: "ReservationTable",
	components: { TableIcon },
	props: {
		table: {
			type: String,
			required: true
		}
	},
	computed: {
		tableDisplayName() {
			return this.table.trim() ? `${this.table}` : "Sin Mesa";
		},
	}
}
</script>

<style scoped>

</style>

<template>
	<label :class="{ heartbeat: hasUnitsChanged }" class="product-card-client product-card-client-list list-copilot position-relative" :for="`option_checkbox_${menu.name}-${menu.productOrderId}`">
		<span v-if="hasUnitsChanged" class="badge badge-secondary badge-time">Actualizada la cantidad</span>
		<div class="product-card-body">
			<img v-if="showPictureMenu !== null && imageUrl()" class="product-card-img " :src="imageUrl()" @error="handleImageError" alt="...">
			<div class="product-card-info">
				<div class="product-card-data">
					<div class="product-card-title">
						<span v-if="menu.quantity && source !== 'account' && !menu.units">{{ menu.quantity }}</span>
						<span v-if="source === 'account'">x{{ menu.quantity }}</span>
						<span v-if="menu.units > 1">{{ menu.units }} (uds.)</span>
						<span v-if="menu.count && menu.quantity > 1" >{{ menu.count }}</span>
						{{ menu.shortName || menu.name }}
						<span v-if="source === 'account'">{{ menu.price }}</span>
					</div>
				</div>
				<div class="ingredients">
					<div v-for="(choice, index) in menu.choices" :key="index">
						<span>{{ choice.type }}</span>
						<div>
							<span v-for="(option, optionIndex) in choice.options" :key="optionIndex">
								<span v-if="optionIndex === 0">- </span>
								<span class="fw-bold">{{ option.quantity }}</span> {{ option.name }}
								<span v-if="optionIndex < choice.options.length - 1">, </span>
							</span>
						</div>
					</div>
				</div>
			</div>

		</div>
	</label>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
	name: 'OrdersProductMenuDailyCard',
	inject: {
		reservationRepository: 'reservationRepository',
		userRepository: 'userRepository',
	},
	props: {
		menu: {
			type: Object,
			required: true,
			default: null
		},
		source: {
			type: String,
			default: null
		},
	},
	data() {
		return {
			showPictureMenu: null,
			hasUnitsChanged: false,
		};
	},
	computed: {
		...mapGetters('venue', ['resourceId', 'urlVenue']),
		...mapGetters('copilot', ['selectedProductOrdersToServe', 'selectedProductOrdersToPrepared','productOrder']),
		productImg() {
			return `${this.urlVenue}/world/img/${this.resourceId}/product/${this.menu.pictureMenu}`;
		},

	},
	watch: {
		menu: {
			handler(newValue, oldValue) {
				if(newValue.quantity !== oldValue.quantity){
					this.hasUnitsChanged = true;
				}
			},
			deep: true
		}
	},

	mounted() {
		this.checkImage();
	},
	methods: {
		...mapActions('copilot', ['getIdReservation','addProductOrder']),
		imageUrl() {
			return this.productImg;
		},
		checkImage() {
			const img = new Image();
			img.src = this.productImg;

			img.onerror = () => {
				this.showPictureMenu = null;
			};

			this.showPictureMenu = true
		},
		handleImageError() {
			this.showPictureMenu = null;
		},
	}
}
</script>

<style>
.heartbeat {
	animation: heartbeat 1s infinite;
}

@keyframes heartbeat {
	0% {
		transform: scale(1);
	}
	25% {
		transform: scale(1.02);
	}
	50% {
		transform: scale(1.04);
	}
	75% {
		transform: scale(1.02);
	}
	100% {
		transform: scale(1);
	}
}
</style>

<template>
	<ul class="list-group list-group-item-light overflow-y-scroll h-100 service-reservation-container" id="accordion-reservation-bar">
		<TpvCardAccount v-for="reservation in reservationsActive" :key="reservation.id" :reservation="reservation"/>
	</ul>
</template>

<script>
import TpvCardAccount from "@/components/tpv/tpvCardAccount/TpvCardAccount.vue";


export default {
	name: "TpvListAccounts",

	components: {
		TpvCardAccount,
	},
	props: {
		reservationsActive: {
			type: Object,
			required: true,
		}
	},
	computed: {
	},
	data() {
		return {
		}
	},
	methods: {

	}
}
</script>

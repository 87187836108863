<template>
	<div class="dropdown-center h-100">
		<button
			class="btn btn-light w-100 h-100 dropdown-toggle"
			type="button"
			data-bs-toggle="dropdown"
			aria-expanded="false"
			:id="`dropdown-selected-pax-${reservationId}`"
			@click.stop="handleViewSection"
		>
			<ReservationPax :pax="reservationPax" />
		</button>

		<div class="dropdown-menu bg-white p-1 custom-dropdown-menu" style="width: 15rem" :aria-labelledby="`dropdown-selected-pax-${reservationId}`">
			<form action="" @submit.prevent="updatePaxReservation(reservationId)">
				<div class="input-group">
					<input type="number" @click.stop class="form-control" style="height: 50px;" min="0" aria-label="Ubicación en Barra" aria-describedby="basic-addon1" placeholder="Número de pax" v-model="paxTuUpdate">
					<button class="btn btn-primary" @click.stop="updatePaxReservation(reservationId)" :disabled="paxTuUpdate<1 || isLoading">
						<span v-if="!isLoading">Actualizar</span>
						<span v-else>Actualizando... <SpinnerIcon/></span>
					</button>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
import SpinnerIcon from "@/core/icons/SpinnerIcon.vue";
import ReservationPax from "@/core/reservation/ReservationPax.vue";
import {closeDropdown, hideAllModals, viewSectionAccounts} from "@/utils";
import store from "@/store";

export default {
	name: "TpvDropdownReservationUpdatePax" ,
	components: {ReservationPax, SpinnerIcon},
	inject: {
		reservationRepository: 'reservationRepository',
	},
	props: {
		reservationPax: {
			type: Number,
			required: true
		},
		reservationId: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			paxTuUpdate: null,
			isLoading: false,
		}
	},
	methods:{
		handleViewSection() {
			viewSectionAccounts();
		},

		async updatePaxReservation(reservationId) {
			this.isLoading = true;
			const resultUpdatePax = await this.reservationRepository.updatePax({
				reservationWid: reservationId,
				pax: this.paxTuUpdate
			});
			console.log(resultUpdatePax);
			if (resultUpdatePax.result === 0) {
				this.paxTuUpdate = null;
				closeDropdown(`dropdown-selected-pax-${reservationId}`)
				hideAllModals()
			} else {
				store.commit("copilot/setToast", {
					toast: {
						toastMessage: 'Ha ocurrido un error actualizado los comensales correctamente.',
						showToast: true,
						idToast: "incorrectlyToast"
					}
				});
				this.isLoading = false;
			}
		},
	}
}
</script>

<style scoped>
.custom-dropdown-menu {
	position: fixed !important;
	max-height: 300px;
	overflow-y: auto;
	z-index: 1050;
}
</style>

<template>
<OffcanvasBottomProductWrapper :id="'offcanvas_' + product.sku" >
	<template #body>
		<div class="product-food">
			<img v-if="showPictureMenu !== null" class="food-img " :src="pictureMenu" alt="">
			<div class="wrapper">
				<h5 class="offcanvas-title">{{ product.name }}</h5>

				<div v-if="product.allergens && product.allergens.length > 0" class="food-allergens">
					<img class="allergens-img" v-for="allergen in product.allergens" :key="allergen.id" :src="getImageAllergenUrl(allergen)" :alt="allergen.name">
				</div>
				<div v-else>
					<p>{{ t('menu.checkAllergensWithStaff') }}</p>
				</div>
				<div v-for="(productOption, index) in product.productOptions" :key="`product_option_${product.sku}-${productOption.id}`">
					<hr class="vr-horizontal">
					<div class="extras-container">
						<div class="extras-header">
							<div class="extras-title">
							<span v-if="product.productOptions && product.productOptions.length > 1">{{
									t('menu.productOptionCount', {
										index: index + 1,
										total: product.productOptions.length
									}) }}: </span>{{ translatedProductOptionDescription(productOption) }}
							</div>
							<div class="extras-subtitle">
								{{ productOption.max === 1 ? t('menu.chooseOne') : productOption.max === productOption.min ? t('menu.chooseUpTo', {count: productOption.max}) : t('menu.chooseUpTo', {count: productOption.max}) }}
							</div>
						</div>
						<div class="extras-products-list" >
							<MenuProductCardOptions :stackable="productOption.stackable || 0" :product="product" :options="productOption.options" :max="productOption.max" :min="productOption.min" @update-options="updateOptions(productOption.id, $event)" :ref="`product_option_card_${product.sku}-${productOption.id}`"></MenuProductCardOptions>
						</div>
					</div>
				</div>
			</div>
		</div>
	</template>
	<template #footer>
		<button type="button" class="btn btn-primary w-100" :id="'buttonModalAdd' + product.sku" @click="sendProductToCart" :disabled="!isOptionsWithinRange || !availability">Añadir</button>
	</template>
</OffcanvasBottomProductWrapper>
</template>

<script>
import OffcanvasBottomProductWrapper from "@/components/utils/offcanvas/OffcanvasBottomProductWrapper";
import MenuProductCardOptions from "@/components/menuProductCardOptions/MenuProductCardOptions";
import {useI18n} from "vue-i18n";
import {computed, reactive} from "vue";
import {getTranslationKey} from "@/i18n";
import {animateProductToCart, hideAllModals, normalizeChoices} from "@/utils";
import * as bootstrap from "bootstrap";
import {mapGetters} from "vuex";
import store from "@/store";
export default {
	name: "InformationProduct",
	props:{
		product:{
			type: Object
		}
	},
	inject: {
		dateFormatterService: 'dateFormatterService',
	},
	components: {MenuProductCardOptions, OffcanvasBottomProductWrapper},
	data() {
		return {
			productImg: null,
			productFoodImg: null,
			selectedUnits: this.calculateValue(0),
			showPictureMenu: null,
			iSDisabledButtonAddCanvasDisable: true,
			options: reactive({}),
			menuQuantity: 0,

			isTimeOut: false
		}
	},
	setup(props) {
		const { t } = useI18n();

		// Use `props` to get the correct translation key
		const translatedProductName = computed(() => {
			const translationKey = getTranslationKey('product', props.product, 'name');
			let translation = t(`dynamic.${translationKey}`);
			return translation !== `dynamic.${translationKey}` ? translation : props.product.name;
		});

		const translatedProductDescription = computed(() => {
			const translationKey = getTranslationKey('product', props.product, 'description');
			let translation = t(`dynamic.${translationKey}`);
			return translation !== `dynamic.${translationKey}` ? translation : props.product.description;
		});

		const translatedProductOptionName = (productOption) => {
			const translationKey = getTranslationKey('productOptionType', productOption, 'name');
			let translation = t(`dynamic.${translationKey}`);
			return translation !== `dynamic.${translationKey}` ? translation : productOption.name;
		};

		const translatedProductOptionDescription = (productOption) => {
			const translationKey = getTranslationKey('productOptionType', productOption, 'description');
			let translation = t(`dynamic.${translationKey}`);
			return translation !== `dynamic.${translationKey}` ? translation : productOption.description;
		};

		return {
			t,
			translatedProductName,
			translatedProductDescription,
			translatedProductOptionName,
			translatedProductOptionDescription,
		};
	},
	beforeMount() {
		this.isLoading = true

		this.productImg = `${this.urlVenue}/world/img/${this.resourceId}/product/${this.product.pictureMenu}`
		this.productFoodImg = `${this.urlVenue}/world/img/${this.resourceId}/product/${this.product.pictureMenu}`

		if (this.product.category === 'Soft' || this.product.subcategory === 'Beer' || this.product.subcategory === 'Wine') {
			// return this.product.finalPrice = this.product.priceMemberUnit * 100;

		} else {
			if (this.reservationPromotion && this.reservationPromotion.shortDescription) {
				// const discountPercentage = parseFloat(this.reservationPromotion.shortDescription.match(/\d+(\.\d+)?/));
				// discountFactor = discountPercentage ? (100 - discountPercentage) / 100 : 1;
			}
		}
		this.checkImage();
	},
	mounted() {
		if (this.hasProductOptions) {
			this.initializeOptions();
		}

	},
	computed:{
		...mapGetters('copilot', ['view']),
		...mapGetters('tpv', ['isAddProductsCartInAccount']),
		...mapGetters('config', ['weWelcomUrl']),
		...mapGetters('venue', ['businessName', 'urlVenue', 'resourceId']),
		pictureMenu() {
			return 	`${this.urlVenue}/world/image/product/${this.product.pictureMenu}`
		},
		productRange() {
			const range = [];
			for (let i = this.product.servingQtyMin; i <= this.product.servingQtyMax; i++) {
				range.push(i);
			}
			return range;
		},
		hasProductOptions() {
			return Array.isArray(this.product.productOptions) && this.product.productOptions.length > 0;
		},
		productOptionsOld() {
			return this.product.productOptions ? this.product.productOptions[0] : []
		},
		isOptionsWithinRangeOld() {
			const options = [...this.options];
			if (this.hasProductOptions) {
				return options.length >= this.productOptions.min && options.length <= this.productOptions.max;
			}
			return true;
		},
		isOptionsWithinRange() {
			if (this.hasProductOptions) {
				return this.product.productOptions.every(option => {
					const selectedOptions = this.options[option.id] || [];
					return selectedOptions.length >= option.min && selectedOptions.length <= option.max;
				});
			}
			return true;
		},
		matchingProductUnits() {
			return this.productUnit.find(unit => unit.sku === this.product.sku) || {cartItemCount: 0};
		},
		backdropAccount() {
			return !this.isCopilotRoute;
		},
		availability() {
			return this.product.menuCategoryProductInfo.availability === 1
		}
	},
	methods:{
		checkImage() {
			const img = new Image();
			img.onerror = () => {
				console.log('Error 404: No se pudo cargar la imagen de fondo.');
				this.showPictureMenu = null;
			};
			this.showPictureMenu = true;

			img.src = this.pictureMenu;
		},
		initializeOptions() {
			if (Array.isArray(this.product.productOptions)) {
				this.product.productOptions.forEach(option => {
					this.options[option.id] = [];
				});
			} else {
				console.warn('productOptions is not an array or is undefined:', this.product.productOptions);
			}
		},
		calculateValue(increment) {
			return this.product.servingQtyMin + this.product.unitaryIncrement * increment;
		},
		calculateLabel(increment) {
			return this.product.servingQtyMin + this.product.unitaryIncrement * increment;
		},
		sendProductToCart(event) {
			if (event) {
				event.stopPropagation();
			}

			if (this.isCopilotRoute) {
				this.clearProductOptions();
				this.hideOffcanvas();
			}
			this.isTimeOut= true
			// store.commit("copilot/setToast", {toast:{toastMessage: `Tu ${this.product.name} se han agregado correctamente.`, showToast:true, idToast: "correctlyToast"}});
			console.log('Que opciones son?',this.options)
			const normalizedOptions = normalizeChoices(this.options);
			console.log('Emitting add-product with options:', normalizedOptions);
			this.addProductToCart(this.product, 1, this.options)
			setTimeout(() => {
				this.isTimeOut= false
			}, 500);

			console.log('Unidades', this.product.cartItemCount)
			this.clearProductOptions();
			this.initializeOptions();
			this.hideOffcanvas();
			console.log('que rol se ha seleccionado? ', this.selectedRole)

			if(this.view === 'TPV' && !this.isAddProductsCartInAccount) {
				store.commit('tpv/setViewSectionAccounts', "createAccount")
			}
			
			this.$nextTick(() => {
				if (!this.isCopilotRoute) {
					animateProductToCart(this.product.sku, 'cartIcon');
				}
			})
		},
		addProductToCart(product, units, options) {
			store.commit('menu/addProductToCart', {product, units, options})
		},
		clearProductOptions() {
			if (this.product.productOptions) {
				this.product.productOptions.forEach((productOption) => {
					const refName = `product_option_card_${this.product.sku}-${productOption.id}`;
					const ref = this.$refs[refName];
					if (ref && Array.isArray(ref)) {
						ref.forEach(r => r.clearSelectedOptions());
					} else if (ref) {
						ref.clearSelectedOptions();
					}
				});
			}

		},
		getImageAllergenUrl(alergeno) {
			return `assets/img/allergies/${alergeno.pictureName.toLowerCase()}.png`;
		},
		formatFloat(price) {
			if (isNaN(price)) {
				return price;
			}
			const formatted = this.dateFormatterService.formattedPrice(price)
			return formatted
		},
		getMinusButtonIcon() {
			return this.product.cartItemCount === 1 ? 'fa-trash' : 'fa-minus';
		},
		openInformationProduct(productSku/*, event*/) {
			let offcanvasElement = document.getElementById('offcanvas_' + productSku);

			if (offcanvasElement) {
				let offcanvasInstance = bootstrap.Offcanvas.getInstance(offcanvasElement);
				if (!offcanvasInstance) {
					offcanvasInstance = new bootstrap.Offcanvas(offcanvasElement, {
						keyboard: true // Permite cerrar el offcanvas con la tecla ESC
					});
				}
				if (offcanvasElement.classList.contains('show')) {
					// offcanvasInstance.hide();
				} else {
					offcanvasInstance.show();
				}
			}
		},
		hideOffcanvas() {
			console.log('vamos a cerrar el canvas')
			const offcanvasElement = document.getElementById("offcanvas_" + this.product.sku);

			if (offcanvasElement) {
				const offCanvas = bootstrap.Offcanvas.getInstance(offcanvasElement);
				if (offCanvas) {
					offCanvas.hide();
				}
			}
		},
		updateOptionsOld(options) {
			options ? this.options = options : this.options = []
		},
		updateOptions(optionId, selectedOptions) {
			this.options[optionId] = selectedOptions;
		},
		addUnit() {
			if (event) {
				event.stopPropagation();
			}
			this.$emit('add-unit', this.matchingProductUnits)
		},
		subtractUnit() {
			if (event) {
				event.stopPropagation();
			}
			this.confirmSubtractUnit()
		},
		confirmSubtractUnit() {
			if (event) {
				event.stopPropagation();
			}
			this.$emit('subtract-unit', this.matchingProductUnits);
			this.productToRemove = null;
			hideAllModals()
		},
		closeModal() {
			if (event) {
				event.stopPropagation();
			}
			hideAllModals()
		},
	},
}
</script>

<style scoped>

</style>

<template>
	<ul class="list-group">
		<li class="list-group-item list-group-item-secondary">
			<div class="row g-0 text-center">
				<div class="col-1">Ud.</div>
				<div class="col-7">Descripción</div>
				<div class="col-2">PVP</div>
			</div>
		</li>
	</ul>
	<ul v-if="reservationCartSession" class="list-group overflow-y-scroll overflow-hidden h-100">
		<li v-if="reservationCartSession.productOrders" class="list-group-item list-group-item-light text-uppercase">
			<div
				class="border-bottom"
				v-for="productOrder in listProductsHistoric"
				:key="productOrder.id"
				:class="{'bg-body-secondary': productOrder.statusProductOrder === -1 || productOrder.statusProductOrder === -3}"
			>
				<label
					:for="`check-box-tpv-${productOrder.productOrderId}`"
					class="d-flex justify-content-between gap-2 w-100 py-1 px-1"
					:class="{
            'text-decoration-line-through': productOrder.statusProductOrder === -1 || productOrder.statusProductOrder === -3,
            'text-success': productOrder.paymentStatus === 1
          }"
				>
          <span class="fw-bold" style="flex: 0">
            {{ productOrder.units }}
          </span>
					<span style="flex: 3; min-width: 150px;">
            <ProductOrderStatus :product-order="productOrder"/>
            {{ productOrder.name }}
          </span>
			<template v-if="false">
				<div v-for="(choiceGroup, index) in productOrder.choices" :key="index">
					<span>- {{ choiceGroup.choice }}</span>
				</div>
			</template>
					<span class="fw-bold text-end" style="flex: 1">
            {{ formatPrice(productOrder) }}
          </span>
					<span class="d-flex justify-content-end" style="flex: 1">
            <span class="form-check">
              <input :id="`check-box-tpv-${productOrder.productOrderId}`" :value="productOrder.productOrderId" v-model="selectedProducts" @change="queueCheckboxChange(productOrder.productOrderId, $event.target.checked, reservationId)" class="form-check-input" style="width: 25px;height: 25px" type="checkbox">
            </span>
          </span>
				</label>
			</div>
		</li>
	</ul>
</template>

<script>
import {mapState} from 'vuex';
import ProductOrderStatus from "@/core/product/ProductOrderStatus.vue";
import debounce from 'lodash/debounce';

export default {
	name: "TpvListHistoricProducts",
	components: {ProductOrderStatus},
	props: {
		reservationCartSession: {
			type: Object,
			required: true,
		},
		reservationId: {
			type: String,
			required: true
		}
	},
	computed: {
		...mapState('tpv', ['listProductsSelected']),
		selectedProducts: {
			get() {
				return this.listProductsSelected;
			},
			set(value) {
				this.$store.commit('tpv/setListProductsMoveAccount', value);
			}
		},
		listProductsHistoric() {
			if (!this.reservationCartSession || !this.reservationCartSession.productOrders) {
				return [];
			}
			let allProducts = [];
			this.reservationCartSession.productOrders.forEach(order => {
				allProducts.push(...order.items);
			});
			allProducts.sort((a, b) => {
				const bottomStatuses = [-1, -3];
				const isAInBottom = bottomStatuses.includes(a.statusProductOrder);
				const isBInBottom = bottomStatuses.includes(b.statusProductOrder);
				if (isAInBottom && !isBInBottom) return 1;
				if (!isAInBottom && isBInBottom) return -1;
				if (a.paymentStatus === 1 && b.paymentStatus !== 1) return 1;
				if (a.paymentStatus !== 1 && b.paymentStatus === 1) return -1;
				return 0;
			});
			return allProducts;
		},
	},
	data() {
		return {
			activeAccountId: null,
			checkboxUpdateQueue: []
		};
	},
	methods: {
		queueCheckboxChange(productId, isSelected, reservationId) {
			if (this.activeAccountId !== reservationId) {
				this.$store.commit('tpv/clearListProductsSelected');
				this.activeAccountId = reservationId;
			}
			this.checkboxUpdateQueue.push({productId, isSelected});
			this.debouncedFlushQueue();
		},
		flushQueue() {
			while (this.checkboxUpdateQueue.length > 0) {
				const change = this.checkboxUpdateQueue.shift();
				this.$store.dispatch('tpv/updateProductSelection', change);
			}
		},
		getOptionName(productOrder,optionId, chosenId) {
			const productOption = productOrder.productOptions.find(option => option.id === optionId);
			const option = productOption ? productOption.options.find(opt => opt.id === chosenId) : null;
			return option ? option.name : '';
		},
		productOrderPrintName(productOrder) {
			let name = productOrder.name;

			// Build an array for extra info (modifiers and choices with supplements)
			const extras = [];

			// Add modifiers if available
			if (productOrder.priceModifiers && productOrder.priceModifiers.length > 0) {
				const modifiers = productOrder.priceModifiers.map(modifier => modifier.name);
				if (modifiers.length > 0) {
					extras.push(modifiers.join(", "));
				}
			}

			// Add choices that have a supplement (non-null and non-zero)
			if (productOrder.choices && productOrder.choices.length > 0) {
				const choicesWithSupplement = productOrder.choices.filter(choice => choice.supplement != null && choice.supplement > 0);
				if (choicesWithSupplement.length > 0) {
					// For each, append the choice name and the supplement value (e.g., "Masa Rústica +2")
					const choicesText = choicesWithSupplement.map(choice => `${choice.choice} +${choice.supplement}`);
					extras.push(choicesText.join(", "));
				}
			}

			if (extras.length > 0) {
				name += " (" + extras.join("; ") + ")";
			}

			return name;
		},
		debouncedFlushQueue: debounce(function () {
			this.flushQueue();
		}, 300),
		formatPrice(product) {
			if (
				!product ||
				typeof product.units !== "number" ||
				typeof product.unitaryPrice !== "number"
			) {
				return "0,00";
			}

			// Calculate the extra supplement amount from choices (if any)
			let extra = 0;
			if (Array.isArray(product.choices)) {
				product.choices.forEach(choice => {
					if (choice.supplement != null && !isNaN(choice.supplement)) {
						extra += parseFloat(choice.supplement);
					}
				});
			}

			// New unit price includes the supplement
			const unitPrice = parseFloat(product.unitaryPrice) + extra;
			const total = product.units * unitPrice;

			// Format the price with 2 decimals and comma as decimal separator
			return total.toFixed(2).replace(".", ",");
		},
	}
};
</script>

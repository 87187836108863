<template v-if="service && service.serviceReservations && service.serviceReservations.length">
	<!--  Contenedor del servicio  -->
	<section
		class="section-service collapsed mb-3"
		:class="{ 'open': isOpen(safeServiceName),'services-active': seatedReservations && seatedReservations.totalReservations.length !== 0}"
		:id="`parentCollapseServices${safeServiceName}`"
		data-bs-toggle="collapse"
		:href="`#childrenCollapseService${safeServiceName}`"
		role="button"
		aria-expanded="false"
		:aria-controls="`childrenCollapseService${safeServiceName}`">
		<div class="service-title" :class="{'active': seatedReservations && seatedReservations.totalReservations.length !== 0}">
			<div class="row">
				<div class="col-6">
					<h3>{{ service.serviceName }}</h3>
				</div>
				<div class="col-6">
					<div class="service-meta">
						<span>
							<span><i class="fas fa-user"></i> {{ serviceReservations?.totalPax || '0' }}</span>
							<div class="vr mx-2"></div>
							<span><i class="fas fa-calendar-day"></i> {{ serviceReservations?.totalReservations?.length || '0' }}</span>
						</span>
					</div>
				</div>
			</div>
		</div>
		<div class="service-subtitle" v-if="seatedReservations && seatedReservations.totalReservations.length !== 0">
			<div class="row">
				<div class="col-6">
					<div class="service-meta">
						<span>Por venir</span>
						<span>
							<span><i class="fas fa-user"></i> {{ reservationsToArrive ? reservationsToArrive.totalPax : 0 }}</span>
							<div class="vr mx-1"></div>
							<span><i class="fas fa-calendar-day"></i> {{ reservationsToArrive ? reservationsToArrive.totalReservations.length : 0 }}</span>
						</span>
					</div>
				</div>
				<div class="col-6">
					<div class="service-meta">
						<span>Sentadas</span>
						<span>
							<span><i class="fas fa-user"></i> {{ seatedReservations ? seatedReservations.totalPax : 0 }}</span>
							<div class="vr mx-1"></div>
							<span><i class="fas fa-calendar-day"></i> {{ seatedReservations ? seatedReservations.totalReservations.length : 0 }}</span>
						</span>
					</div>
				</div>
			</div>
		</div>
	</section>

	<!--  Contenido del collapse	-->
	<div class="collapse" data-bs-parent="#accordionAllServices" :id="`childrenCollapseService${safeServiceName}`">
		<div class="w-100 mb-2 scroll-x p-0 gap-1">
			<button
				@click="filterByZone(null)"
				:class="{'active': !selectedZone}"
				class="btn btn-outline-secondary">
				Todas
			</button>
			<button
				v-for="zone in zones"
				:key="zone"
				@click="filterByZone(zone)"
				:class="{'active': selectedZone === zone}"
				class="btn btn-outline-secondary">
				{{ zone }}
			</button>
		</div>

		<div class="container">
			<div class="btn-group w-100 btn-group-sm" role="group" aria-label="Basic outlined example">
				<button :class="{'active': selectedAreaFilter === 'hour'}" class="btn btn-outline-secondary rounded-bottom-0" style="border-top-left-radius: 15px" @click="applyFilter('hour')">
					<span v-if="selectedAreaFilter === 'hour'"><i class="fa-solid fa-clock"></i></span><span v-else>Ordenar por Hora</span>
				</button>
				<button :class="{'active': selectedAreaFilter === 'table'}" class="btn btn-outline-secondary rounded-bottom-0" style="border-top-right-radius: 15px" @click="applyFilter('table')">
					<span v-if="selectedAreaFilter === 'table'"><table-icon/></span><span v-else>Ordenar por Mesa</span>
				</button>
			</div>
		</div>

		<template v-if="currentReservations?.totalReservations?.length && isLoad">

			<div class="container g-0" :id="`childrenCollapseService${safeServiceName}services`">
				<div class="row gap-2 g-0">
					<copilot-card-reservation data-base-parent="services" class="col-12" v-for="reservation in sortedActiveReservations" :key="'key-'+reservation.id" :service-name="safeServiceName" :reservation="reservation"></copilot-card-reservation>
				</div>
			</div>

			<div class="container">
				<div class="btn-group btn-group-sm w-100" role="group" aria-label="Basic outlined example">
					<button class="collapsed btn btn-outline-primary w-100 rounded-top-0  gap-0"
							style="border-bottom-left-radius: 15px"
							data-bs-toggle="collapse"
							:data-bs-target="`#childrenCollapseFinishReservations${safeServiceName}`"
							aria-expanded="false"
							@click="console.log('btn finnish')"
							>
						<span class="text-truncate">{{ t('copilot.finished') }}</span>
						<span> <span><i class="fas fa-user"></i> {{ finalizedReservations ? finalizedReservations.totalPax : 0 }}</span> <div class="vr mx-1"></div> <span><i class="fas fa-calendar-day"></i> {{ finalizedReservations ? finalizedReservations.totalReservations.length : 0 }}</span> </span>
					</button>
					<button class="collapsed btn btn-outline-primary w-100 rounded-top-0"
							style="border-bottom-right-radius: 15px"
							data-bs-toggle="collapse"
							:data-bs-target="`#childrenCollapseNoShowReservations${safeServiceName}`"
							aria-expanded="false"
							>
						<span>{{ t('copilot.noShow') }}</span>
						<span> <span><i class="fas fa-user "></i> {{ noShowReservations ? noShowReservations.totalPax : 0 }}</span> <div class="vr mx-1"></div> <span><i class="fas fa-calendar-day"></i> {{ noShowReservations ? noShowReservations.totalReservations.length : 0 }}</span> </span>
					</button>
				</div>
			</div>

			<!--		Collapse no show		-->
			<div class="collapse" :id="`childrenCollapseNoShowReservations${safeServiceName}`">
				<div class="mb-3 pb-3" :id="`childrenCollapseService${safeServiceName}noShow`">
					<div v-if="sortedNoShowReservations.length">
						<copilot-card-reservation v-for="reservation in sortedNoShowReservations" :key="reservation.id" data-base-parent="services" :service-name="safeServiceName" :reservation="reservation"></copilot-card-reservation>
					</div>
					<div class="text-center w-100 p-3" v-else>
						No hay reservas no show
					</div>
				</div>
			</div>

			<!--		Collapse finish		-->
			<div class="collapse " :id="`childrenCollapseFinishReservations${safeServiceName}`">
				<div class="service-reservation-footer">
					<div class="container">
						<div class="row">
							<div class="col-6">
								<div class="service-meta">
									<span>{{ t('copilot.walkin') }}</span>
									<span>
										<i class="fas fa-user"></i> {{ walkinReservations ? walkinReservations.totalPax : 0 }}
										<div class="vr mx-1"></div>
										<i class="fas fa-calendar-day"></i> {{ walkinReservations ? walkinReservations.totalReservations.length : 0 }}
									</span>
								</div>
							</div>
							<div class="col-6">
								<div class="service-meta">
									<span>{{ t('copilot.reservation') }}</span>
									<span>
										<i class="fas fa-user"></i> {{ reserveReservation ? reserveReservation.totalPax : 0 }}
										<div class="vr mx-1"></div>
										<i class="fas fa-calendar-day"></i> {{ reserveReservation ? reserveReservation.totalReservations.length : 0 }}
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<template v-if="sortedFinishReservations.length">
					<div :id="`childrenCollapseService${safeServiceName}finish`">
						<copilot-card-reservation data-base-parent="finish" v-for="reservation in sortedFinishReservations" :key="reservation.id" :service-name="safeServiceName" :reservation="reservation"></copilot-card-reservation>
					</div>
				</template>
				<div class="text-center w-100 p-3" v-else>
					{{ t('copilot.notFinishedReservations') }}
				</div>
			</div>
		</template>

		<!--  Si no hay reservas	-->
		<section v-else class="text-center container-section">
			<b>{{ emptyStateDescription }}</b>
		</section>
	</div>
	<!--	</div>-->
</template>


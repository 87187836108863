<template>
	<div class="container-fluid g-0 vh-100 overflow-hidden">
		<div class="row g-1">
			<main class="col-5 d-flex flex-column gap-1">
				<div style="height: 50vh" id="section-tpv-accounts">
					<section class="box-shadow  m-0 h-100 p-1 d-flex flex-column justify-content-between bg-body-secondary welcom-border-sm">

						<TpvAccounts v-if="viewSectionAccounts === 'allAccounts'"/>

						<TpvNewOrder v-if="viewSectionAccounts === 'createAccount'"/>

					</section>
				</div>
				<div style="height: 50vh" id="section-tpv-drinks-to-prepare">
					<TpvDrinksToPrepare/>
				</div>
			</main>

			<div class="col-5 col-lg-6 d-flex flex-column gap-1">
				<div style="height: 50vh">
					<TpvMenuCategories/>
				</div>
				<div style="height: 50vh;">
					<section class="box-shadow m-0 h-100 p-1 bg-body-secondary welcom-border-sm overflow-y-scroll">
						<TpvProductsByCategory
							v-if="viewSectionItems === 'products'"
							:category="selectedCategory"
						/>

						<TpvTables
							v-if="viewSectionItems === 'tables'"
						/>

						<TpvSelectedPax
							v-if="viewSectionItems === 'selectedPax'"
						/>

						<TpvListProductsTopChoice
							v-if="viewSectionItems === 'topChoice'"
						/>
					</section>
				</div>
			</div>

			<header class="col-2 col-lg-1">
				<div class=" d-flex flex-column justify-content-between gap-1 h-100">
					<div>
						<button
							ref="buttons"
							class="btn btn-primary w-100 btn-tpv-sm border-0 shadow-none"
							type="button" data-bs-toggle="offcanvas"
							data-bs-target="#offcanvasNavbar"
							aria-controls="offcanvasNavbar"
							aria-label="Toggle navigation"
						>
							<span class="navbar-toggler-icon fs-5"></span>
						</button>
						<img src="/assets/img/wewelcom-light-mix-v-nobg-transparente.png" alt="" class="logo w-100">
						<button
							v-if="false"

							ref="buttons"
							class="btn btn-tpv-sm w-100"
							:class="[viewSectionAccounts === 'createAccount' ? 'btn-primary' : 'btn-outline-primary']"
							:disabled="viewSectionAccounts === 'createAccount'"
							@click="showCreateAccount"

						>
							Nuevo Pedido
						</button>
						<button
							v-if="false"

							ref="buttons"
							class="btn btn-tpv-sm w-100"
							:class="[viewSectionAccounts === 'allAccounts' ? 'btn-primary' : 'btn-outline-primary']"
							:disabled="viewSectionAccounts === 'allAccounts'"
							@click="showAllAccounts"

						>
							Cuentas
						</button>
						<button
							v-if="false"
							ref="buttons"
							class="btn btn-tpv-sm w-100"
							:class="[viewSectionAccounts === 'allAccounts' ? 'btn-primary' : 'btn-outline-primary']"
							@click="handleUnifyAccounts"
						>
							Juntar Cuentas
						</button>
					</div>

					<button
						ref="buttons"
						class="btn btn-outline-primary btn-tpv-sm w-100"
						@click="showHome"
						style="height: 5rem;"

					>
						<i class="fa-solid fa-house fa-xl"></i>
					</button>
				</div>
			</header>
		</div>
	</div>
	<div v-if="isLoading" class="spinner-overlay">
		<div class="spinner-container">
			<div class="spinner"></div>
			<p>Cargando...</p>
		</div>
	</div>
	<TpvModalSelectedTable/>
	<TpvModalMethodPayment/>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import store from '@/store'

import TpvMenuCategories from "@/components/tpv/tpvMenuCategories/TpvMenuCategories";
import TpvProductsByCategory from "@/components/tpv/tpvProductsByCategory/TpvProductsByCategory";
import TpvTables from "@/components/tpv/tpvTables/tpvTables";
import TpvNewOrder from "@/components/tpv/tpvNewOrder/TpvNewOrder.vue";
import TpvDrinksToPrepare from "@/components/tpv/tpvDrinksToPrepare/TpvDrinksToPrepare.vue";
import TpvSelectedPax from "@/components/tpv/tpvSelectedPax/TpvSelectedPax.vue";
import TpvListProductsTopChoice from "@/components/tpv/tpvListProductsTopChoice/TpvListProductsTopChoise.vue";
import {showModal, viewSectionStandar} from "@/utils";
import TpvModalSelectedTable from "@/components/tpv/tpvModalSelectedTable/TpvModalSelectedTable.vue";
import TpvModalMethodPayment from "@/components/tpv/tpvModalMethodPayment/TpvModalMethodPayment.vue";
import TpvAccounts from "@/components/tpv/tpvAccounts/TpvAccounts.vue";

export default {
	name: "TPV",
	components: {
		TpvAccounts,
		TpvModalMethodPayment,
		TpvModalSelectedTable,
		TpvListProductsTopChoice,
		TpvSelectedPax,
		TpvDrinksToPrepare,
		TpvNewOrder,
		TpvTables,
		TpvProductsByCategory,
		TpvMenuCategories
	},
	data() {
		return {
			chosenReservation: null,
		}
	},
	computed: {
		...mapGetters('tpv', ["viewSectionAccounts", "viewSectionItems", 'isLoading']),
		...mapGetters("menu", ["selectedCategory", "productsCart"]),
		...mapGetters('venue', ['venueId']),
		...mapGetters('copilot', ['reservations']),
	},
	created() {
		this.$nextTick(() => {
			this.adjustFontSize()
		});

		this.$nextTick(() => {
			viewSectionStandar()
		});
	},
	beforeMount() {
		this.adjustFontSize()
	},
	methods: {
		...mapActions("menu", ["setCategorySelected"]),

		showAllAccounts() {
			store.commit("tpv/setViewSectionAccounts", 'allAccounts');
			this.resetProductsCart()
		},
		showCreateAccount() {
			store.commit("tpv/setViewSectionAccounts", 'createAccount');
			this.resetProductsCart()
		},

		resetProductsCart() {
			store.commit('menu/setProductsCart', {productsCart: []});
			store.commit('tpv/setViewSectionItems', 'topChoice');
			this.setCategorySelected({id: '', name: ''});
			store.commit('tpv/setIsChangeTable', false);
			store.commit('tpv/setNewAccount', null);
			store.commit('tpv/clearListProductsSelected');
			store.commit('tpv/setIsAddProductsCartInAccount',false);
			store.commit('copilot/setReservationId',null);
			viewSectionStandar()
		},

		async updateNotifications() {
			await store.dispatch('copilot/updateOrdersAndNotifications')
		},

		showHome() {
			store.commit('tpv/setViewSectionAccounts', 'allAccounts');
			this.resetProductsCart()
		},

		handleUnifyAccounts() {
			showModal('tpv-modal-unify-accounts')
		},

		adjustFontSize() {
			if (this.$refs.buttons) {
				let button = this.$refs.buttons;
				let fontSize = 14;
				button.style.fontSize = `${fontSize}px`;
				while (button.scrollHeight > button.offsetHeight && fontSize > 1) {
					fontSize -= 1;
					button.style.fontSize = `${fontSize}px`;
				}
			}
		}
	},
}
</script>
<style scoped>
/* Fondo que cubre toda la pantalla */
.spinner-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background: rgba(0, 0, 0, 0.5); /* Fondo semitransparente */
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 9999; /* Asegura que esté por encima de otros elementos */
}

/* Contenedor del spinner */
.spinner-container {
	text-align: center;
	color: white;
}

/* Estilo del spinner */
.spinner {
	border: 8px solid rgba(255, 255, 255, 0.3); /* Color de fondo del spinner */
	border-top: 8px solid white; /* Color del borde animado */
	border-radius: 50%;
	width: 80px;
	height: 80px;
	animation: spin 1s linear infinite;
}

.section-tpv-h50 {
	@media (min-width: 576px) {
		height: auto;
	}

	@media (min-width: 992px) {
		height: 50vh;
	}
}

.section-tpv-h75 {
	@media (min-width: 576px) {
		height: auto;
	}

	@media (min-width: 992px) {
		height: 75vh;
	}
}

/* Animación del spinner */
@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
</style>

<template>
	<li  class="list-group-item mb-1 service-reservation" :class="reservationStatus(reservation)">
		<div type="button" v-if="reservation" class="position-relative" @click="openCollapse(reservation.id)">
			<div class="container-fluid g-0">
				<div class="row g-0 h-100 align-items-center">
					<div class="col-10">
						<div class="row g-0">
							<div class="col-2 d-flex justify-content-between align-items-center text-center">
								<ReservationTypeIcons v-if="reservation.source" :reservation="reservation"/>

								<span class="ps-1 w-100">
											<ReservationStartDate v-if="reservation.startDate" :start-date="reservation.startDate"/>
										</span>
							</div>

							<div class="col-2">
								<TpvDropdownReservationUpdatePax :reservation-id="reservation.id" :reservation-pax="reservation.pax"/>
							</div>

							<div class="col-6">
								<TpvBtnsReservationUpdateTable :reservation="reservation"/>
							</div>

							<div class="col-2">
								<button class="btn btn-secondary w-100 h-100" @click.stop="handleAddProductsCart(reservation.id)">
									<CartPlusIcon/>
								</button>
							</div>
						</div>
					</div>

					<div class="col-2 arrow" :id="'containerArrow' + reservation.id">
						<div
							class="d-flex justify-content-center align-items-center"
							style="height: 50px"
						>
							<i :id="'iconArrow'+reservation.id" class="fa-solid fa-chevron-down"></i>
						</div>
					</div>
				</div>
			</div>

			<span v-if="reservation.dateReadyForPayment && reservation.paymentInfo.length" class="alert-reserve text-dark process-pay">
						Cuenta solicitada

						<ReservationPaymentIcons :reservation-payment-info="reservation.paymentInfo"/>
					</span>

			<ReservationReadyPayment :reservation-payment-info="reservation.paymentInfo" :reservation-ready-payment="reservation.dateReadyForPayment"/>
		</div>

		<div class="collapse" :id="`collapse-bar-${reservation.id}`" data-bs-parent="#accordion-reservation-bar">
			<div class=" mb-3 d-flex flex-column gap-2">
				<div class="container-fluid g-0">
					<div class="row g-0">
						<div class="col-12">
							<div class="fw-bold text-uppercase">
								<ReservationName :reservation-name="reservation.name"/>
							</div>
						</div>
						<div class="col-12">
							<ReservationInternalComment :internal-comment="reservation.staffComments"/>
						</div>
					</div>
				</div>
				<div class="btn-group w-100">
					<button class="btn btn-light w-100 text-wrap" type="button" data-bs-toggle="collapse" :data-bs-target="`#collapse-internal-comment-${reservation.id}`" aria-expanded="false" aria-controls="collapseExample">
						<i class="fa-solid fa-sticky-note"></i>
						Comentario interno
					</button>
					<button
						ref="buttons"
						class="btn btn-light w-100"
						@click="handleUnifyAccount(reservation.id)"
					>
						Unir cuenta
					</button>
				</div>

				<div class="collapse" :id="`collapse-internal-comment-${reservation.id}`">
					<div class="card card-body">
						<form >
							<div class="form-floating w-100">
								<textarea v-model="internalComment" class="form-control" placeholder="¿Algún comentario privado para esta reserva?" id="comments"></textarea>
								<label for="comments">¿Algún comentario privado para esta reserva?</label>
							</div>
							<button class="btn btn-primary w-100 mt-2" @click.stop="addInternalComment" :disabled="isLoading">
								<span v-if="!isLoading">Guardar</span><span v-else>Guardando... <SpinnerIcon/></span>
							</button>
						</form>
					</div>
				</div>

				<div class="btn-group w-100">
					<button
						ref="buttons"
						class="btn btn-light w-100"
						:disabled="!isConnected"
						@click="handlePrintTicket(reservation)"
					>
						<i class="fa-solid fa-print"></i>
						Imprimir
					</button>
					<button v-if="reservation.dateReadyForPayment && reservation.menuAccessEnabled === 1" ref="buttons" class="btn btn-light w-100" @click="handleReactiveAccount(reservation.id)">
						Reactivar
					</button>
					<button v-else class="btn btn-secondary text-wrap w-100" @click="handleRequestTicket(reservation.id)">
						Pedir cuenta
					</button>
				</div>

				<section v-if="reservationId === reservation.id" class="p-1 section-primary welcom-border-sm">
					<h3>Nuevo pedido</h3>
					<TpvListProductsCart />
					<button
						:disabled="!productsCart.length || isLoadingBtnCreateOrder"
						class="btn btn-success w-100 my-2"
						@click.stop="handleCreateOrder(reservation)"
					>
								<span v-if="!isLoadingBtnCreateOrder">
									Enviar pedido
								</span>
						<span v-else>
									Enviando... <SpinnerIcon/>
								</span>
					</button>
				</section>

				<TpvListHistoricProducts :reservation-id="reservation.id" :reservation-cart-session="reservation.cartSession"/>

				<ul class="list-group position-sticky bottom-0">
					<li class="list-group-item list-group-item-secondary">
						<div class="row g-0">
							<div class="col-6">
								Total
							</div>
							<div class="col-6 text-end">
								{{ formatNumber(reservation.paymentInfo.total) }}
							</div>
						</div>
						<div class="row g-0">
							<div class="col-6">
								Pagado
							</div>
							<div class="col-6 text-end">
								{{ formatNumber(reservation.paymentInfo.totalPayment) }}
							</div>
						</div>
						<div class="row g-0 fw-bold fs-5">
							<div class="col-6">
								Pendiente
							</div>
							<div class="col-6 text-end">
								{{ formatNumber(reservation.paymentInfo.pendingPayment) }}
							</div>
						</div>
					</li>
				</ul>
			</div>
		</div>
	</li>
</template>

<script>
import TpvBtnsReservationUpdateTable from "@/components/tpv/tpvBtnsReservationUpdateTable/TpvBtnsReservationUpdateTable.vue";
import ReservationReadyPayment from "@/core/reservation/ReservationReadyPayment.vue";
import CartPlusIcon from "@/core/icons/CartPlusIcon.vue";
import ReservationName from "@/core/reservation/ReservationName.vue";
import SpinnerIcon from "@/core/icons/SpinnerIcon.vue";
import ReservationPaymentIcons from "@/core/reservation/ReservationPaymentIcons.vue";
import ReservationInternalComment from "@/core/reservation/ReservationInternalComment.vue";
import TpvListProductsCart from "@/components/tpv/tpvListProductsCart/TpvListProductsCart.vue";
import ReservationStartDate from "@/core/reservation/ReservationStartDate.vue";
import TpvDropdownReservationUpdatePax from "@/components/tpv/tpvDropdownReservationUpdatePax/TpvDropdownReservationUpdatePax.vue";
import ReservationTypeIcons from "@/core/reservation/ReservationTypeIcons.vue";
import TpvListHistoricProducts from "@/components/tpv/tpvListHistoricProducts/TpvListHistoricProducts.vue";
import * as bootstrap from "bootstrap";
import store from "@/store";
import {hideAllModals, showModal, viewSectionAccounts, viewSectionStandar} from "@/utils";
import {reservationRepository} from "@/service/repository/reservationRepository";
import {mapActions, mapGetters} from "vuex";

export default {
	name: "TpvCardAccount" ,
	components: {TpvListHistoricProducts, ReservationTypeIcons, TpvDropdownReservationUpdatePax, ReservationStartDate, TpvListProductsCart, ReservationInternalComment, ReservationPaymentIcons, SpinnerIcon, ReservationName, CartPlusIcon, ReservationReadyPayment, TpvBtnsReservationUpdateTable},
	props:{
		reservation: {
			type: Object,
			required: true
		}
	},
	inject: {
		cartSessionRepository: 'cartSessionRepository',
		copilotRepository: 'copilotRepository',
		reservationRepository: 'reservationRepository',
	},
	data() {
		return {
			openCollapseId: null,
			isAddProductsCart: false,

			isLoadingBtnCreateOrder:false,
			internalComment: null,

			isLoading: false
		}
	},
	mounted() {
		if(this.reservation.staffComments){
			this.internalComment = this.reservation.staffComments;
		} else {
			this.internalComment = null;
		}

	},
	computed:{
		...mapGetters('venue', ['reservationLocationTags']),
		...mapGetters('weWingman', ['isConnected']),
		...mapGetters('menu',['productsCart']),
		...mapGetters('user',['user']),
		...mapGetters('copilot',['selectedRole','reservationId'])
	},
	watch: {
		reservation: {
			immediate: true,
			handler(newReservation) {
				this.internalComment = newReservation.staffComments || '';
			}
		}
	},
	methods:{
		...mapActions('weWingman', ['printBill', 'printTicket']),
		handleUnifyAccount(reservationId) {
			console.log(reservationId)
			store.commit('tpv/setAccountSelectedId', reservationId)
			store.commit('tpv/setIsAccountSelectedForUnify', true)
			store.commit('tpv/setUnifyAccounts', {
				originReservationId: reservationId
			});

			this.$nextTick(() => {
				showModal('tpv-modal-selected-table')
			})
		},

		async handlePrintTicket(reservation) {
			console.log("En la venue " + this.resourceId + " Vamos a imprimir el ticket de " + this.reservation.id);

			await this.printTicket(reservation);
		},
		async handleReactiveAccount(reservationId) {
			event.stopPropagation()
			try {
				const resultReactivateReservation = await this.reservationRepository.reactivateReservation(reservationId);

				if (resultReactivateReservation.result === 0) {
					console.log('handleReactiveAccount correcto')
				}
			} catch (e) {
				console.log(e)
			}
		},
		handleRequestTicket(reservationId) {
			event.stopPropagation();
			store.commit('tpv/setAccountSelectedId', reservationId)
			this.$nextTick(() => {
				showModal('tpv-modal-method-payment')
			})
		},

		handleAddProductsCart(reservationId) {
			let collapseElement = document.getElementById(`collapse-bar-${reservationId}`);

			if (!collapseElement.classList.contains('show')) {
				store.commit('menu/setProductsCart', {productsCart:[]})
				this.openCollapse(reservationId);
			}

			store.commit('copilot/setReservationId', reservationId);
			store.commit('tpv/setIsAddProductsCartInAccount', true);

		},

		async handleCreateOrder(reservation) {
			this.isLoadingBtnCreateOrder = true;
			const orderInfo = {
				name: this.user.name,
				welKey: this.user.welKey,
				userId: this.user.id,
				pax: reservation.pax,
				source: 2,
				clientMessage: this.cartClientMessage,
				orderReservationName: this.user.name,
				reservationId: reservation.id,
				av: 0,
				roleId: this.selectedRole
			};

			const products = this.productsCart.flatMap(product => {
				if (product.isUnitary === "SI") {
					return {
						sku: product.sku,
						options: product.chosenOptions,
						units: product.cartItemCount,
						modifyDishClass: product.modifyDishClass?.id,
						isShareable: product.isShareable
					}
				} else {
					return Array(product.cartItemCount).fill({
						sku: product.sku,
						options: product.chosenOptions,
						modifyDishClass: product.modifyDishClass?.id,
						isShareable: product.isShareable
					});
				}
			});
			const resultOrder = await this.cartSessionRepository.createCartSessionOrder(orderInfo, products);
			if (resultOrder.result === 0) {
				this.resetStoreNewAccount()
				store.commit('tpv/setIsAddProductsCartInAccount',false)
				store.commit('copilot/setReservationId',null);
				this.isLoadingBtnCreateOrder = false;
				viewSectionStandar()
			} else {
				this.isLoadingBtnCreateOrder = false;
				store.commit("copilot/setToast", {toast: {toastMessage: 'No se ha podido asignar a la cuenta.', showToast: true, idToast: "incorrectlyToast"}});
			}
		},

		reservationStatus(reservation) {
			if (reservation) {
				if (reservation.status === -1) {
					return 'cancelled';
				} else if (reservation.status === -4) {
					return 'no-show'
				} else if (reservation.status === 7) {
					return 'process-pay'
				} else if (reservation.status === 10) {
					return 'finish';
				} else if (this.isReservationRequest) {
					return 'order';
				} else if (reservation.menuAccessEnabled === 1) {
					/*'started'*/
					return '';
				} else if (reservation.startDate !== null) {
					return 'standar'
				}
				return '';
			}
		},

		resetStoreNewAccount() {
			store.commit("tpv/setViewSectionAccounts", 'allAccounts');
			store.commit('menu/setProductsCart', {productsCart: []});
			store.commit('tpv/setViewSectionItems', 'topChoice');
			this.selectedForNewOrder = null;
			hideAllModals()
		},

		openCollapse(elementId) {
			let collapseElement = document.getElementById(`collapse-bar-${elementId}`);
			let iconArrow = document.getElementById(`iconArrow${elementId}`);
			let containerElement = document.getElementById(`containerArrow${elementId}`);

			let collapseInstance = bootstrap.Collapse.getInstance(collapseElement);
			if (!collapseInstance) {
				collapseInstance = new bootstrap.Collapse(collapseElement, {toggle: false});
			}

			if (collapseElement.classList.contains('show')) {
				collapseInstance.hide();
				iconArrow.classList.remove('fa-rotate-180');
				containerElement.classList.remove('active');
				this.openCollapseId = null;
				store.commit('menu/setProductsCart', {productsCart:[]})

				viewSectionStandar()
			} else {
				collapseInstance.show();
				iconArrow.classList.add('fa-rotate-180');
				containerElement.classList.add('active');
				this.openCollapseId = elementId;
				store.commit('tpv/setIsAddProductsCartInAccount',false)
				store.commit('copilot/setReservationId',null);
				store.commit('tpv/clearListProductsSelected')
				viewSectionAccounts()
			}
		},

		formatNumber(value) {
			if (typeof value !== "number") {
				return "0,00";
			}

			return value.toFixed(2).replace(".", ",");
		},

		async addInternalComment(){
			this.isLoading = true;

			const data = {
				reservationId: this.reservation.id,
				staffComments: this.internalComment
			};

			const result = await reservationRepository.addInternalCommentReservation(data);
			if(result.result === 0){
				let collapseElement = document.getElementById("collapse-internal-comment-"+ this.reservation.id);
				let collapseInstance = bootstrap.Collapse.getInstance(collapseElement) || new bootstrap.Collapse(collapseElement);
				collapseInstance.hide();

				this.internalComment = this.reservation.staffComments;
				this.isLoading = false;
			} else {
				store.commit("copilot/setToast", {
					toast: {
						toastMessage: 'Ha ocurrido un error al intentar agregar el comentario interno.',
						showToast: true,
						idToast: "incorrectlyToast"
					}
				});
				this.isLoading = false;
			}


		}
	}
}
</script>

<style scoped>

</style>

<template>
	<section class="container-md">
		<div :id="serviceEventPendingService[0].serviceEventId">
			<h2>
				{{ sectionTitle }} <span class="time"><i class="fas fa-clock"></i> {{ elapsedTime }}</span>
			</h2>

			<!--  Placeholder	-->
			<div v-if="isLoadingOrder" class="order-container order-container-copilot">
				<div class="order-data placeholder-glow">
					<div class="order-data-header">
						<span class="placeholder col-5"></span>
						<span class="placeholder col-5"></span>
					</div>
				</div>

				<div class="content-list-order">
					<div class="card product-list">
						<div class="card-info placeholder-glow">
							<span class="placeholder col-10"></span>
							<span class="placeholder col-2"></span>
						</div>
					</div>
					<div class="card product-list">
						<div class="card-info placeholder-glow">
							<span class="placeholder col-10"></span>
							<span class="placeholder col-2"></span>
						</div>
					</div>
					<div class="card product-list">
						<div class="card-info placeholder-glow">
							<span class="placeholder col-10"></span>
							<span class="placeholder col-2"></span>
						</div>
					</div>
				</div>

				<button class="btn btn-secondary w-100 disabled placeholder col-6" aria-disabled="true"></button>
			</div>

			<div class="order-container order-container-copilot fadeOutBackground" >
				<div v-for="order in unifiedProducts" :key="order.reservationInfo.reservationId"  id="">
					<div class="order-data">
						<div class="order-data-header">
							<div>
								<table-icon fill-color="black"/>
								{{ order.reservationInfo.zone }} / {{ order.reservationInfo.table }}
							</div>
							<div>
								<i class="fas fa-utensils"></i> {{ order.reservationInfo.userName }}
							</div>
						</div>
					</div>
					<div class="products-list-content" >
						<orders-product-card v-for="product in order.products" :key="product.productOrderId" :order="product" source="readyOrder"></orders-product-card>
					</div>
				</div>

				<button :disabled="isRequestInLoading" @click="handleServeProductOrders()" class="btn btn-secondary w-100">
					<span class="process-button" v-html="isRequestInLoading ? buttonTextOrderLoading : buttonTextOrder "></span></button>
			</div>

			<div v-if="serviceEventPendingService && serviceEventPendingService.length > 1" class="alert alert-order" role="alert">
				<i class="fas fa-bell"></i> <span> +{{ serviceEventPendingService.length - 1 }} pedidos</span>
			</div>

		</div>
	</section>
</template>

<script>
import OrdersProductCard from "@/components/ordersProductCard/OrdersProductCard";
import {mapGetters} from "vuex";
import TableIcon from "@/core/icons/TableIcon";
import store from "@/store";

export default {
	name: 'CopilotOrdersContainerKitchen',
	inject: {
		productOrderRepository: 'productOrderRepository',
		reservationRepository: 'reservationRepository',
		userRepository: 'userRepository',
		cartSessionRepository: 'cartSessionRepository',
		dateFormatterService: 'dateFormatterService',
	},
	emits: [],
	data() {
		return {
			previousFirstOrder: null,
			isLoadingOrder:false,
			elapsedTime: '',
			isLoadingOrderKitchen: false,
			isRequestInLoading: false,
			buttonTextOrderLoading: `Enviando pedido <i class="fas fa-spinner fa-spin"></i>`
		};
	},
	computed: {
		...mapGetters('copilot',['serviceEventPendingService', 'selectedProductOrdersToServe','isOnlyRoom']),
		sectionTitle(){
			if (this.isOnlyRoom) return 'Preparar y Servir';
			return 'Pedido Listo'
		},
		buttonTextOrder(){
			if(this.serviceEventIsDrinkOrDish === 'Cocina'){
				return this.selectedProductOrdersToServe.length === 0 ? 'Servir todos los platos' : `Servir ${this.selectedProductOrdersToServe.length} platos`
			}else{
				return this.selectedProductOrdersToServe.length === 0 ? 'Servir todas las bebidas' : `Servir ${this.selectedProductOrdersToServe.length} bebidas`
			}

		},
		serviceEventIsDrinkOrDish(){
			return this.serviceEventPendingService[0].items[0].requirementProductCopilotRoles[0].name;
		},
		unifiedProducts() {
			const items = this.serviceEventPendingService[0].items.filter(productOrder => productOrder.statusProductOrder < 100);
			const groupedProducts = {};

			items.forEach(item => {
				const reservationId = item.reservationInfo.reservationId;
				if (!groupedProducts[reservationId]) {
					groupedProducts[reservationId] = {
						reservationInfo: item.reservationInfo,
						products: []
					};
				}
				groupedProducts[reservationId].products.push(item);
			});

			return Object.values(groupedProducts).map(group => ({
				reservationInfo: group.reservationInfo,
				products: group.products
			}));
		},
	},
	watch: {
	},
	components: {
		'orders-product-card': OrdersProductCard,
		'table-icon': TableIcon
	},
	beforeMount() {
	},
	async mounted() {
		this.updateElapsedTime();

		setInterval(() => {
			this.updateElapsedTime();
		}, 1000);

	},
	methods: {

		async handleServeProductOrders() {
			console.log('Vamos a procesar los pedidos como servidos');
			this.isRequestInLoading = true;
			console.log("Este es el serviceEvent", this.serviceEventPendingService[0])
			if(!this.selectedProductOrdersToServe.length){
				const allProductsOrders = this.serviceEventPendingService[0].items.filter(productOrder => productOrder.statusProductOrder === 3).map(productOrder => productOrder.productOrderId);
				console.log(allProductsOrders);
				const data = {
					productOrderIds: allProductsOrders,
					serviceEventId: this.serviceEventPendingService[0].serviceEventId,
				};
				const resultServeProductOrders = await this.productOrderRepository.serveProductOrders(data);
				console.log(resultServeProductOrders)
			}else{
				const data = {
					productOrderIds: this.selectedProductOrdersToServe,
					serviceEventId: this.serviceEventPendingService[0].serviceEventId,
				};
				const resultServeProductOrders = await this.productOrderRepository.serveProductOrders(data);
				console.log(resultServeProductOrders);
				store.commit('copilot/setSelectedProductOrdersToServe', { selectedProductOrdersToServe: [] });
			}
			this.isRequestInLoading = false;
		},

		updateElapsedTime() {
			if (!this.serviceEventPendingService.length || !this.serviceEventPendingService[0].serviceEventTimeCreated) {
				return;
			}

			const startTime = new Date(this.serviceEventPendingService[0].serviceEventTimeCreated.date);
			const startDateFormatted = this.dateFormatterService.formattedDate(startTime);

			const currentDate = new Date();

			if (startTime.toDateString() !== currentDate.toDateString()) {
				this.elapsedTime = startDateFormatted;
			} else {

				const difference = Math.floor((currentDate - startTime) / 1000);

				const hours = Math.floor(difference / 3600);
				const minutes = Math.floor((difference % 3600) / 60);
				const seconds = difference % 60;

				if (hours > 0) {
					this.elapsedTime = `${hours} ${hours > 1 ? '\'' : '\''}`;
				} else if (minutes > 0) {
					this.elapsedTime = `${minutes} ${minutes > 1 ? '\'' : '\''}`;
				} else if (seconds > 0) {
					this.elapsedTime = `New`;
				} else {
					this.elapsedTime = startDateFormatted;
				}
			}
		},

	},
}
</script>

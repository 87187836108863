<template>
	<div class="modal fade" id="tpv-modal-selected-table" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
		<div class="modal-dialog modal-lg modal-dialog-centered modal-fullscreen">
			<div class="modal-content">
				<div class="modal-header d-block">
					<h1 class="modal-title fs-4" id="exampleModalLabel">
						<span v-if="this.viewSectionAccounts === 'createAccount'">
							Selecciona una mesa
						</span>
						<span v-if="reservation && viewSectionAccounts !== 'createAccount' && reservation.roomName !== 'Barra'">
							<span>
								{{ reservation.roomName }}
								<span class="vr mx-1"></span>
							</span>
							<span>
								<TableIcon style="font-size: 12px"/>
								{{ reservation.table }}
							</span>
						</span>
						<span v-if="reservation && viewSectionAccounts !== 'createAccount' && reservation.roomName === 'Barra'">
							<span>
								{{ reservation.roomName }}
								<span class="vr mx-1"></span>
							</span>
							<span>
								<TableIcon style="font-size: 12px"/>
								{{ reservation.locationTag }}
							</span>
						</span>
						<span v-if="isAccountMoveProducts">
							A que mesa movemos los productos?
						</span>
					</h1>
				</div>
				<div class="modal-body d-flex flex-column overflow-hidden">
					<div class="btn-group-tpv mb-3">
						<button
							v-for="zone in zones"
							:key="zone"
							class="btn btn-outline-secondary btn-tpv-sm position-relative shadow-none"
							:class="{ active: selectedZone === zone}"
							@click="selectZone(zone)"
						>
							{{ zone }}
						</button>
					</div>

					<div v-if="selectedZone !== 'Barra'" class="overflow-y-scroll overflow-hidden">
						<div class="btn-group-tpv" v-for="zone in displayedTables" :key="zone.name">
							<button class="btn btn-primary btn-tpv-sm position-relative shadow-none border-0  m-3" v-if="zone.name && zone.name !== 'Todas'">
								<span class="text-uppercase fw-bold">{{ zone.name }}</span>
							</button>
							<template v-for="table in zone.tables" :key="table.table_id">
								<button
									v-if="zone.name !== 'Barra'"
									:key="table.table_id"
									ref="buttons"
									class="btn btn-primary btn-tpv-sm position-relative shadow-none d-flex flex-column align-items-center justify-content-between m-3"
									:class="{'btn-secondary' : isTableOccupied(table) && isChangeTable}"
									@click="createSelectedTable(table)"
								>
								<span class="mb-2 overflow-hidden" style="flex: 1">
									{{ table.name }}
								</span>
									<span class="badges-container flex-column bottom-0 w-auto" style="top:unset; right: unset">
									<template v-if="getReservationsForTable(table).length">
										<span v-if="hasDubbing(table)" class="badge badge-danger pulse z-2" ><i class="fa-solid fa-clock me-1"></i>{{ remainingTimeToReservation(table) }}</span>
										<span class="badge border-secondary border-1 border d-flex flex-column align-items-center gap-1 z-1 badge-warning text-primary">
										<span v-for="reservation in getReservationsForTable(table)" :key="reservation.id" >
											<span class="fw-bold" v-if="reservation.source">
												<i v-if="reservation.walkIn === 1" class="fas fa-walking"></i>
												<SittingIcon v-if="reservation.walkIn === 2" style="width: 15px;height: 15px;" fill-color="white"/>
												<i v-if="reservation.walkIn == null || reservation.walkIn === 0 && reservation.source !== 10" class="fas fa-qrcode"></i>
												<i v-if="reservation.source === 10 && isReserve" class="fa-solid fa-phone"></i>
											</span>
											{{ reservation.startDate }}
											<span class="vr mx-1"></span>
											{{ reservation.pax }}
											<span class="vr" v-if="reservation.name"></span>
											{{ reservation.name }}
										</span>
									</span>
									</template>
									<span  v-if="isTableOccupied(table)" class="badge border-secondary border-1 border z-1" :class="{'badge-primary' : isChangeTable, 'badge-secondary' : !isChangeTable}">
										<span class="fw-bold">
											<i v-if="isTableOccupied(table).walkIn === 1" class="fas fa-walking"></i>
											<SittingIcon v-if="isTableOccupied(table).walkIn === 2" style="width: 15px;height: 15px;" fill-color="white"/>
											<i v-if="isTableOccupied(table).walkIn == null || isTableOccupied(table).walkIn === 0 && isTableOccupied(table).source !== 10" class="fas fa-qrcode"></i>
											<i v-if="isTableOccupied(table).source === 10 && isReserve" class="fa-solid fa-phone"></i>
										</span>
										{{ isTableOccupied(table).startDate }}
										<span class="vr mx-1"></span>
										{{ isTableOccupied(table).pax }}
										<span class="vr" v-if="isTableOccupied(table).name"></span>
										{{ isTableOccupied(table).name }}
									</span>
								</span>
								</button>
								<template v-else>
									<button
										ref="buttons"
										class="btn btn-primary btn-tpv-sm position-relative shadow-none d-flex flex-column align-items-center justify-content-between m-3"
										:class="{'btn-secondary' : isTableOccupied(table) && isChangeTable}"
										@click="createSelectedTable(table)"
										v-if="isTableOccupied(table)"
									>
									<span class="mb-2 overflow-hidden" style="flex: 1">
										{{ isTableOccupied(table).locationTag }}
									</span>
										<span class="badge border-secondary border-1 border z-1" :class="{'badge-primary' : isChangeTable, 'badge-secondary' : !isChangeTable}">
										<span class="fw-bold">
											<i v-if="isTableOccupied(table).walkIn === 1" class="fas fa-walking"></i>
											<SittingIcon v-if="isTableOccupied(table).walkIn === 2" style="width: 15px;height: 15px;" fill-color="white"/>
											<i v-if="isTableOccupied(table).walkIn == null || isTableOccupied(table).walkIn === 0 && isTableOccupied(table).source !== 10" class="fas fa-qrcode"></i>
											<i v-if="isTableOccupied(table).source === 10 && isReserve" class="fa-solid fa-phone"></i>
										</span>
										{{ isTableOccupied(table).startDate }}
										<span class="vr mx-1"></span>
										{{ isTableOccupied(table).pax }}
										<span class="vr" v-if="isTableOccupied(table).name"></span>
										{{ isTableOccupied(table).name }}
									</span>
									</button>
								</template>
							</template>
						</div>
					</div>
					<div v-if="selectedZone === 'Barra'" class="overflow-y-scroll overflow-hidden">
						<div class="btn-group-tpv"  v-for="zone in displayedTables" :key="zone.name">
							<template
								v-for="table in zone.tables"
								:key="table.table_id"
							>
								<button
									ref="buttons"
									class="btn btn-primary btn-tpv-sm position-relative shadow-none d-flex flex-column align-items-center justify-content-between m-3"
									:class="{'btn-secondary' : isTableOccupied(table) && isChangeTable}"
									@click="createSelectedTable(table)"
									v-if="isTableOccupied(table)"
								>
									<span class="mb-2 overflow-hidden" style="flex: 1">
										{{ isTableOccupied(table).locationTag }}
									</span>
									<span class="badge border-secondary border-1 border z-1" :class="{'badge-primary' : isChangeTable, 'badge-secondary' : !isChangeTable}">
										<span class="fw-bold">
											<i v-if="isTableOccupied(table).walkIn === 1" class="fas fa-walking"></i>
											<SittingIcon v-if="isTableOccupied(table).walkIn === 2" style="width: 15px;height: 15px;" fill-color="white"/>
											<i v-if="isTableOccupied(table).walkIn == null || isTableOccupied(table).walkIn === 0 && isTableOccupied(table).source !== 10" class="fas fa-qrcode"></i>
											<i v-if="isTableOccupied(table).source === 10 && isReserve" class="fa-solid fa-phone"></i>
										</span>
										{{ isTableOccupied(table).startDate }}
										<span class="vr mx-1"></span>
										{{ isTableOccupied(table).pax }}
										<span class="vr" v-if="isTableOccupied(table).name"></span>
										{{ isTableOccupied(table).name }}
									</span>
								</button>
							</template>
						</div>
					</div>
				</div>
				<div class="modal-footer">
					<DismissButton :is-modal="true" @click="handleClose"/>
				</div>
			</div>
		</div>
	</div>

	<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
		<div  class="modal-dialog modal-lg modal-dialog-centered" v-if="selectedTable">
			<div class="modal-content">
				<div class="modal-header">
					<h1 class="modal-title fs-4" id="exampleModalLabel">
						<span v-if="reservation && viewSectionAccounts !== 'createAccount' && reservation.roomName !== 'Barra'">
							<span>
								{{ reservation.roomName }}
								<span class="vr mx-1"></span>
							</span>
							<span>
								<TableIcon style="font-size: 12px"/>
								{{ reservation.table }}
							</span>
						</span>
						<span v-if="reservation && viewSectionAccounts !== 'createAccount' && reservation.roomName === 'Barra'">
							<span>
								{{ reservation.roomName }}
								<span class="vr mx-1"></span>
							</span>
							<span>
								<TableIcon style="font-size: 12px"/>
								{{ reservation.locationTag }}
							</span>
						</span>
					</h1>
				</div>
				<div class="modal-body d-flex flex-column justify-content-center" style="height: 50vh">
					<button style="height: 8rem" class="btn btn-secondary btn-welcom btn-xxl w-100" @click="moveAccountTable(selectedTable)">
						Sentar en la mesa
					</button>
					<button style="height: 8rem;" class="btn btn-secondary btn-welcom btn-xxl w-100 mt-3" @click="unifySelectedAccounts(selectedTable)">
						Mover productos e iniciar reserva
					</button>
				</div>
				<div class="modal-footer">
					<DismissButton :is-modal="true"/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import {mapGetters} from "vuex";
import DismissButton from "@/core/DismissButton.vue";
import store from "@/store";
import {walkingService} from "@/service/walkingService";
import {hideAllModals, showModal, viewSectionStandar} from "@/utils";
import SittingIcon from "@/core/icons/SittingIcon.vue";
import TableIcon from "@/core/icons/TableIcon.vue";
import {reservationRepository} from "@/service/repository/reservationRepository";

export default {
	name: "TpvModalSelectedTable",
	components: {TableIcon, SittingIcon, DismissButton},
	inject: {
		cartSessionRepository: 'cartSessionRepository',
		copilotRepository: 'copilotRepository',
		reservationRepository: 'reservationRepository',
	},
	data() {
		return {
			selectedZone: 'Todas',
			selectedTable: null,
		}
	},
	computed: {
		...mapGetters('tpv', ['viewSectionAccounts', 'accountSelectedId','isPaymentPartial', 'unifyAccounts','isAccountSelectedForUnify' ,'listProductsSelected', 'isChangeTable', 'selectedNumberPax', 'isSelectedPax', 'newAccount','isAccountMoveProducts']),
		...mapGetters('copilot', ['reservations', 'tables', 'selectedRole']),
		...mapGetters('user', ['user']),
		...mapGetters('menu', ['productsCart']),

		zones() {
			// Definir las zonas de forma ordenada
			const uniqueZones = Array.from(new Set(this.tables.map(table => table.room)));
			let zones = ['Todas'];

			if (uniqueZones.includes('Barra')) {
				zones.push('Barra');
			}

			const remainingZones = uniqueZones.filter(zone => zone !== 'Barra');
			return [...zones, ...remainingZones];
		},

		displayedTables() {
			// Si la zona seleccionada es 'Todas', se agrupan todas las mesas en un solo objeto
			if (this.selectedZone === 'Todas') {
				const allZones = [];
				// Agrupar todas las mesas por zona
				this.zones.forEach(zone => {
					const zoneTables = this.tables.filter(table => table.room === zone);
					allZones.push({
						name: zone, // nombre de la zona
						tables: zoneTables // mesas correspondientes a esa zona
					});
				});
				return allZones;
			} else {
				// Filtrar y retornar las mesas solo para la zona seleccionada
				const selectedZoneTables = this.tables.filter(table => table.room === this.selectedZone);
				return [{
					tables: selectedZoneTables // mesas de la zona seleccionada
				}];
			}
		},

		reservation() {
			return this.reservations.find(reservation => reservation.id === this.accountSelectedId)
		},
	},
	mounted() {
		this.initializeSelectedZone();
		console.log('que zona es ', this.displayedTables)

	},
	beforeUnmount() {
		this.initializeSelectedZone();
	},
	methods: {
		selectZone(zoneName) {
			this.selectedZone = zoneName;
		},

		async createSelectedTable(table) {
			console.log(table)
			const timeToReservation = this.remainingTimeToReservation(table);

			if (this.viewSectionAccounts === 'createAccount') {
				if (this.isTableOccupied(table)) {
					store.commit('tpv/setIsLoading', true)
					const reservation = this.isTableOccupied(table);
					const orderInfo = {
						name: this.user.name,
						welKey: this.user.welKey,
						userId: this.user.id,
						pax: reservation.pax,
						source: 2,
						clientMessage: this.cartClientMessage,
						orderReservationName: this.user.name,
						reservationId: reservation.id,
						av: 0,
						roleId: this.selectedRole
					};

					const products = this.productsCart.flatMap(product => {
						if (product.isUnitary === "SI") {
							return {
								sku: product.sku,
								options: product.chosenOptions,
								units: product.cartItemCount,
								modifyDishClass: product.modifyDishClass?.id,
								isShareable: product.isShareable
							}
						} else {
							return Array(product.cartItemCount).fill({
								sku: product.sku,
								options: product.chosenOptions,
								modifyDishClass: product.modifyDishClass?.id,
								isShareable: product.isShareable
							});
						}
					});
					const resultOrder = await this.cartSessionRepository.createCartSessionOrder(orderInfo, products);
					if (resultOrder.result === 0) {
						this.resetStoreNewAccount();
						viewSectionStandar()
					} else {
						store.commit("copilot/setToast", {toast: {toastMessage: 'No se ha podido asignar a la cuenta.', showToast: true, idToast: "incorrectlyToast"}});
						store.commit('tpv/setIsLoading', false);
					}
				} else {
					const tables = [table.table_id];
					const products = this.productsCart.flatMap(product => {
						if (product.isUnitary === "SI") {
							return {
								sku: product.sku,
								options: product.chosenOptions,
								units: product.cartItemCount,
								modifyDishClass: product.modifyDishClass?.id,
								isShareable: product.isShareable
							}
						} else {
							return Array(product.cartItemCount).fill({
								sku: product.sku,
								options: product.chosenOptions,
								modifyDishClass: product.modifyDishClass?.id,
								isShareable: product.isShareable
							});
						}
					});
					store.commit('tpv/updateNewAccount', {
						products,
						tables,
						roleId: this.selectedRole
					})

					store.commit('tpv/setIsLoading', true);

					let resultWalkin = await walkingService.create(this.newAccount);
					if (resultWalkin.result === 0) {
						this.resetStoreNewAccount();
						viewSectionStandar()

					} else {
						store.commit("copilot/setToast", {toast: {toastMessage: 'No se ha podido crear la cuenta.', showToast: true, idToast: "incorrectlyToast"}});
						store.commit('tpv/setIsLoading', false);
					}
				}
			}

			if(this.isAccountMoveProducts) {
				const reservation = this.isTableOccupied(table)

				const data = {
					productOrdersIds: this.listProductsSelected,
					destinationReservationId: reservation.id
				}
				const result = await reservationRepository.moveProductsOrdersReservation(data);

				if (result.result === 0) {
					hideAllModals();
					store.commit('tpv/setDestinationAccountProductsOrders', null);
					store.commit('tpv/setIsAccountMoveProducts',false);
					store.commit('tpv/clearListProductsSelected');
					store.commit('tpv/setViewSectionAccounts', 'allAccounts');
					viewSectionStandar()
				} else {
					store.commit("copilot/setToast", {toast: {toastMessage: 'Error al mover los productos.', showToast: true, idToast: "incorrectlyToast"}});
				}
			}

			if (timeToReservation && timeToReservation.includes('m') && this.reservation && this.reservation.roomName === 'Barra') {
				this.selectedTable = table
				if (this.isTableOccupied(table)) {
					store.commit('tpv/setIsAccountSelectedForUnify', true);
					store.commit('tpv/setUnifyAccounts', {
						originReservationId: this.reservation.id
					});
				}
				this.$nextTick(() => {
					showModal('exampleModal');
				})
			} else{
				if (this.isChangeTable) {
					await this.moveAccountTable(table);
				}
				if(this.isAccountSelectedForUnify) {
					await this.unifySelectedAccounts(table)
				}
			}
		},

		async moveAccountTable(table) {
			if (this.isChangeTable) {
				console.log('es cambio de mesa')
				store.commit('tpv/setIsLoading', true);

				const result = await this.copilotRepository.updateTable({
					reservationWid: this.accountSelectedId,
					tables: [table.table_id]
				})
				if (result.result === 0) {
					hideAllModals();
					this.$nextTick(() => {
						store.commit('tpv/setIsLoading', false);
						store.commit('tpv/setAccountSelectedId', null)
						this.initializeSelectedZone();
						viewSectionStandar()

					})
					this.selectedTable = null;
				} else {
					store.commit("copilot/setToast", {
						toast: {
							toastMessage: `No se ha podido cambiar de mesa.`,
							showToast: true,
							idToast: "incorrectlyToast"
						}
					});
					store.commit('tpv/setIsLoading', false);
				}
			}
		},
		async unifySelectedAccounts(table){
			// if (this.isAccountSelectedForUnify) {
				console.log('es unificación de mesa')
				if (this.getReservationsForTable(table)[0]) {
					store.commit('tpv/setUnifyAccounts', {
						originReservationId: this.reservation.id,
						destinationReservationId: this.getReservationsForTable(table)[0].id
					})
				} else {
					if (this.isTableOccupied(table) && this.isTableOccupied(table).id && this.reservation) {
						console.log('que es esta reserva ', this.isTableOccupied(table))
						store.commit('tpv/setUnifyAccounts', {
							originReservationId: this.reservation.id,
							destinationReservationId: this.isTableOccupied(table).id
						})
					}

				}

				const data = this.unifyAccounts
				const result = await reservationRepository.unifyReservations(data)

				if (result.result === 0) {
					store.commit('tpv/setIsAccountSelectedForUnify', false);
					store.commit('tpv/setUnifyAccounts', {originReservationId: null, destinationReservationId: null});
					store.commit('tpv/setViewSectionAccounts', 'allAccounts');
					store.commit('tpv/setViewSectionItems', 'topChoice');
					this.selectedTable = null;
					viewSectionStandar()

					hideAllModals()
				} else {
					store.commit("copilot/setToast", {toast: {toastMessage: 'No se ha podido juntar las cuentas.', showToast: true, idToast: "incorrectlyToast"}});
				}
			// }
		},

		isTableOccupied(table) {
			return this.reservations.find(reservation =>
				reservation.table === table.name &&
				reservation.menuAccessEnabled === 1 &&
				reservation.status !== 7 &&
				reservation.status !== 10
			);
		},

		getReservationsForTable(table) {
			const now = new Date();  // Obtener la hora actual
			const oneHourFromNow = new Date(now.getTime() + (60 * 60 * 1000));  // Calcular una hora en el futuro

			return this.reservations.filter(reservation =>
				reservation.table === table.name &&
				reservation.status !== 7 &&
				reservation.status !== 10 &&
				reservation.menuAccessEnabled === 0 &&
				new Date(reservation.startDateTime) <= oneHourFromNow &&  // La reserva debe comenzar en una hora o antes
				new Date(reservation.startDateTime) > now  // Pero aún no debe haber comenzado
			);
		},

		hasDubbing(table) {
			const now = new Date();
			// Filtra reservas que están dentro de las próximas 24 horas y aún no han comenzado
			const upcomingReservations = this.reservations.filter(reservation =>
				reservation.table === table.name &&
				reservation.status !== 7 &&
				reservation.status !== 10 &&
				reservation.menuAccessEnabled === 0 &&
				new Date(reservation.startDateTime) > now && // Debe empezar en el futuro
				new Date(reservation.startDateTime) <= new Date(now.getTime() + (24 * 60 * 60 * 1000)) // Dentro de las próximas 24 horas
			);

			return upcomingReservations.length > 0; // Devuelve true si hay al menos una reserva próxima
		},

		remainingTimeToReservation(table) {
			// Obtener la hora actual
			const now = new Date();

			// Filtrar las reservaciones futuras para la mesa seleccionada
			const upcomingReservations = this.reservations.filter(reservation =>
				reservation.table === table.name &&
				reservation.status !== 7 &&
				reservation.status !== 10 &&
				new Date(reservation.startDateTime) > now
			);

			// Si no hay próximas reservas, devuelve 'N/A'
			if (upcomingReservations.length === 0) {
				return 'N/A';
			}

			// Ordenar por hora de inicio para encontrar la próxima reserva
			upcomingReservations.sort((a, b) => new Date(a.startDateTime) - new Date(b.startDateTime));

			// Calcular el tiempo hasta la próxima reserva
			const nextReservation = upcomingReservations[0];
			const reservationStartTime = new Date(nextReservation.startDateTime);
			const timeDifference = reservationStartTime - now;

			// Si el tiempo hasta la reserva es negativo, la reserva ya ha comenzado
			if (timeDifference < 0) {
				return null;  // O devolver "Reserva en progreso" si se desea mantener una respuesta
			}

			const minutesUntilStart = Math.round(timeDifference / 60000);

			// Devolver una cadena legible con el tiempo restante solo si es menos de una hora
			if (minutesUntilStart < 60) {
				return `${minutesUntilStart}m`;
			}

			// Si falta más de una hora, no devolver nada
			return null;
		},

		initializeSelectedZone() {
			this.selectedZone = 'Todas';
		},

		resetStoreNewAccount() {
			store.commit("tpv/setViewSectionAccounts", 'allAccounts');
			store.commit('menu/setProductsCart', {productsCart: []});
			store.commit('tpv/setViewSectionItems', 'topChoice');
			store.commit('tpv/setNewAccount', null);
			store.commit('tpv/setIsChangeTable', false);
			store.commit('tpv/setIsLoading', false);
			hideAllModals()
		},

		handleClose() {
			store.commit('copilot/setReservationId', null);
			store.commit('tpv/setIsChangeTable', false)
			this.initializeSelectedZone()
		},
	}
}
</script>

<template>
	<button class="btn btn-light w-100 h-100" @click.stop="handleChangeTable(reservation.id)" v-if="reservation.roomName !== 'Barra'">
		<ReservationRoomName :room-name="reservation.roomName" />
		<ReservationTable :table="reservation.table" />
	</button>
	<span class="btn-group w-100 h-100" v-if="reservation.roomName === 'Barra'">
		<button class="btn btn-light w-100" @click.stop="handleChangeTable(reservation.id)">
			<ReservationRoomName :room-name="reservation.roomName" />
		</button>

		<TpvDropdownReservationUpdateTable :reservation-id="reservation.id" :reservation-location-tag="reservation.locationTag"/>
	</span>
</template>

<script>
import ReservationTable from "@/core/reservation/ReservationTable.vue";
import ReservationRoomName from "@/core/reservation/ReservationRoomName.vue";
import store from "@/store";
import {showModal, viewSectionAccounts} from "@/utils";
import TpvDropdownReservationUpdateTable from "@/components/tpv/tpvDropdownReservationUpdateTable/TpvDropdownReservationUpdateTable.vue";

export default {
	name: "TpvBtnsReservationUpdateTable",
	components: {TpvDropdownReservationUpdateTable, ReservationRoomName, ReservationTable},
	props:{
		reservation: {
			type: Object,
			required: true
		}
	},
	methods: {
		handleChangeTable(reservationId) {
			store.commit('tpv/setAccountSelectedId', reservationId)
			store.commit('tpv/setIsChangeTable', true)
			this.$nextTick(() => {
				showModal('tpv-modal-selected-table');
				viewSectionAccounts()
			})
		},

	},
}
</script>

<style scoped>

</style>

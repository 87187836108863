<template>
	<span>{{ roomDisplayName }}</span>
	<span class="vr mx-1" v-if="roomDisplayName !== 'Barra'"></span>
</template>

<script>
export default {
	name: "ReservationRoomName",
	props: {
		roomName: {
			type: String,
			required: true
		}
	},
	computed: {
		roomDisplayName() {
			return this.roomName.trim() ? this.roomName : "Sin zona";
		}
	}
}
</script>

<style scoped>

</style>

<template>
	<template v-if="!isProductOption">
		<div class="btn-group-tpv">
			<button
				ref="buttons"
				class="btn btn-outline-secondary btn-tpv-sm position-relative shadow-none"
				@click="resetProductsCart"
			>
				Volver
			</button>
			<template
				v-for="category in categoryNamesWithProducts" :key="category.name"
			>
				<button
					ref="buttons"
					class="shadow-none  border-0"
					:class="['btn', 'btn-tpv-sm', 'btn-light'/*, `level-${category.depth}`*/]"
					@click="selectCategory(name)"
				>
					{{ category.name }}
				</button>
				<TpvCardProduct
					v-for="product in category.products"
					:key="product.id"
					:product="product"
					@product-selected-with-options="viewProductOptions"
				/>
			</template>
		</div>
	</template>
	<template v-else>
		<div class="btn-group-tpv">
			<button
				ref="buttons"
				class="btn btn-outline-secondary btn-tpv-sm position-relative shadow-none"
				@click="closeProductSelectedForOptions"
			>
				Volver
			</button>
			<template
				v-for="option in selectedProductOptions"
				:key="option.id"
			>
				<button
					v-if="option.min === 0"
					ref="buttons"
					class="btn btn-primary btn-tpv-sm position-relative shadow-none"
					@click="selectedOption(null, option.id)"
				>
					Sin nada
				</button>

				<button
					v-for="choseOption in option.options"
					:key="choseOption.id"
					ref="buttons"
					class="btn btn-primary btn-tpv-sm position-relative shadow-none"
					@click="selectedOption(choseOption.id)"
				>
					{{ choseOption.name }}
				</button>
			</template>
		</div>
	</template>
	<MenuProductCardOffCanvasOptionDailyMenu v-if="productSelectedForOptions && productSelectedForOptions.isMenu === 'SI'" :product="productSelectedForOptions" />
	<InformationProduct
		v-if="productSelectedForOptions && productSelectedForOptions.productOptions && productSelectedForOptions.productOptions.length > 1 && productSelectedForOptions.isMenu === null"
		:product="productSelectedForOptions"
	/>

</template>

<script>

import TpvCardProduct from "@/components/tpv/tpvCardProduct/TpvCardProduct.vue";
import { normalizeChoices,  showOffcanvas} from "@/utils";
import MenuProductCardOffCanvasOptionDailyMenu from "@/components/menuProductCardOffCanvasOptionDailyMenu/MenuProductCardOffCanvasOptionDailyMenu.vue";
import store from "@/store";
import {mapActions} from "vuex";
import InformationProduct from "@/components/copilotCommander/components/InformationProduct.vue";

export default {
	name: "TpvProductsByCategory",
	components: {InformationProduct, MenuProductCardOffCanvasOptionDailyMenu, TpvCardProduct},
	props: {
		category: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			selectedCategoryName: null,

			productSelectedForOptions: null,
			selectedProductOptions:null,
			isProductOption: false,

			optionsSelectedForProduct:null,

			categoryMenuOptions: null
		};
	},
	mounted() {
		if (this.categoryNamesWithProducts.length > 0) {
			this.selectCategory(this.categoryNamesWithProducts[0]);
		}

		this.$nextTick(() => {
			this.adjustFontSize();
		});

		window.addEventListener('resize', this.adjustFontSize);
	},
	beforeMount() {
		this.adjustFontSize();
		window.removeEventListener('resize', this.adjustFontSize);
	},
	computed: {
		categoryNamesWithProducts() {
			const findCategoryNamesAndProducts = (category, depth = 0) => {
				let result = [];

				if (category.products && Array.isArray(category.products)) {
					result.push({
						name: category.name,
						products: category.products,
						depth: depth,
					});
				}

				if (category.children && Array.isArray(category.children)) {
					for (const child of category.children) {
						result = result.concat(findCategoryNamesAndProducts(child, depth + 1));
					}
				}

				return result;
			};

			return findCategoryNamesAndProducts(this.category);
		},

		selectedProducts() {
			const findProductsByCategoryName = (category, targetName) => {
				let products = [];

				if (category.name === targetName && category.products && Array.isArray(category.products)) {
					products = products.concat(category.products);
				}

				if (category.children && Array.isArray(category.children)) {
					for (const child of category.children) {
						products = products.concat(findProductsByCategoryName(child, targetName));
					}
				}

				return products;
			};

			return this.selectedCategoryName
				? findProductsByCategoryName(this.category, this.selectedCategoryName)
				: [];
		},

		firstCategoryName() {
			return this.categoryNamesWithProducts.length > 0 ? this.categoryNamesWithProducts[0] : null;
		},
	},
	watch: {
		firstCategoryName: {
			immediate: true,
			handler(newCategoryName) {
				this.selectedCategoryName = newCategoryName;
				this.isProductOption = false;
				this.$nextTick(() => {
					this.adjustFontSize()
				})
			},
		},
		isProductOption: {
			immediate: true,
			handler() {
				this.$nextTick(() => {
					this.adjustFontSize()
				})
			},
		},
		selectCategory: {
			immediate: true,
			handler() {
				this.$nextTick(() => {
					this.adjustFontSize()
				})
			},
		},
		selectedCategoryName :{
			immediate: true,
			handler() {
				this.$nextTick(() => {
					this.adjustFontSize()
				})
			},
		}
	},
	methods: {
		...mapActions("menu", ["setCategorySelected"]),

		selectCategory(categoryName) {
			this.selectedCategoryName = categoryName;
			this.isProductOption = false;
			this.$nextTick(() => {
				this.adjustFontSize()
			})

			console.log('que categoria he seleccionado', categoryName)
		},

		viewProductOptions({ product, productOptions, isProductOption }) {
			this.productSelectedForOptions = product;
			if(this.productSelectedForOptions.isMenu === 'SI') {
				console.log('Es Menu?', product.isMenu)
				this.$nextTick(() => {
					showOffcanvas(`offcanvas_${this.productSelectedForOptions.sku}`);
				})
				return
			}
			if (this.productSelectedForOptions && this.productSelectedForOptions.productOptions && this.productSelectedForOptions.productOptions.length > 1 && this.productSelectedForOptions.isMenu === null) {
				console.log('Es Producto con varias opciones?', product)
				this.$nextTick(() => {
					showOffcanvas(`offcanvas_${this.productSelectedForOptions.sku}`);
				})
				return
			}
			this.selectedProductOptions = productOptions;
			this.isProductOption = isProductOption;

			const normalizedOptions = normalizeChoices(this.selectedProductOptions);
			console.log('Emitting add-product with options:', normalizedOptions);
		},

		resetProductsCart() {
			store.commit('tpv/setViewSectionItems', 'topChoice');
			this.setCategorySelected(null);
		},

		selectedOption(chosenOptionId) {
			const options = {};
			if (Array.isArray(this.selectedProductOptions)) {
				this.selectedProductOptions.forEach(option => {
					options[option.id] = [];
				});
			} else {
				console.warn('selectedProductOptions is not an array or is undefined:', this.selectedProductOptions);
			}

			const selectedOptionGroup = this.selectedProductOptions.find(option => option.options.some(o => o.id === chosenOptionId));
			if (selectedOptionGroup) {
				options[selectedOptionGroup.id] = [chosenOptionId];
			}

			this.$store.commit('menu/addProductToCart', {
				product: this.productSelectedForOptions,
				units: 1,
				options: options
			});

			this.closeProductSelectedForOptions()
		},

		selectedCategoryMenuProduct(categoryId) {
			if (this.selectedProductOptions.length > 1) {
				this.selectedProductOptions.find(option => {
					if (option.id === categoryId) {
						console.log('Opciones del menu', option.options)
						return 	this.categoryMenuOptions = option.options
					}
				})

				console.log('que es esto en el menu', this.selectedProductOptions)
			}
		},

		closeProductSelectedForOptions(){
			this.productSelectedForOptions = null;
			this.isProductOption = false;
		},

		adjustFontSize() {
			if (this.$refs.buttons) {
				// Asegurarte de que `this.$refs.buttons` sea iterable
				const buttons = Array.isArray(this.$refs.buttons)
					? this.$refs.buttons
					: Array.from(this.$refs.buttons);

				buttons.forEach(button => {
					let fontSize = 20; // Tamaño inicial en px
					button.style.fontSize = `${fontSize}px`;
					while (button.scrollHeight > button.offsetHeight && fontSize > 1) {
						fontSize -= 1;
						button.style.fontSize = `${fontSize}px`;
					}
				});
			}
		}

	}
};
</script>

<style scoped>
.level-0 {
	--bs-btn-color: var(--bs-primary);
	--bs-btn-border-color: var(--bs-primary);
	--bs-btn-hover-color: var(--bs-btn-color-primary);
	--bs-btn-hover-bg: var(--bs-primary);
	--bs-btn-hover-border-color: var(--bs-primary);
	--bs-btn-focus-shadow-rgb: 13, 110, 253;
	--bs-btn-active-color: var(--bs-btn-color-primary);
	--bs-btn-active-bg: var(--bs-primary);
	--bs-btn-active-border-color: var(--bs-primary);
	--bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
	--bs-btn-disabled-color: var(--bs-primary);
	--bs-btn-disabled-bg: transparent;
	--bs-btn-disabled-border-color: var(--bs-primary);
	--bs-gradient: none;
	background-color: var(--bs-white);
	color: var(--bs-black);}
.level-1 {
	background-color: var(--bs-primary-scale-400);
}
.level-2 {
	background-color: var(--bs-primary-scale-300); }
</style>

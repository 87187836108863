<template>
	<ul class="list-group">
		<li class="list-group-item">
			<div class="d-flex gap-2">
				<div style="flex: 0">
					Ud.
				</div>
				<div style="flex: 3">
					Descripción
				</div>
				<div style="flex: 1">
					PVP
				</div>
				<div style="flex: 1">
					Subtotal
				</div>
				<div style="flex: 0">
					<button v-if="!isAddProductsCartInAccount" class="btn btn-secondary btn-sm" @click="handleViewSection">Expandir</button>
				</div>
			</div>
		</li>
	</ul>

	<ul class="list-group overflow-y-scroll overflow-hidden h-100 my-1" >
		<template v-if="sortedProductsCart.length">
			<li class="list-group-item list-group-item-light border-0 mb-1 bg-secondary" v-for="product in sortedProductsCart" :key="product.id" @click="removeProduct(product)">
				<div class="d-flex gap-2" :class="product.chosenOptions.length ? 'align-items-start':'align-items-center'">
					<div class="fw-bold fs-5 mx-2" style="flex: 0">
						{{ product.cartItemCount }}
					</div>
					<div style="flex: 3">
						{{ product.name }}
						<template v-if="product.chosenOptions">
							<ul v-for="(choice, index) in product.chosenOptions" :key="index">
								<li v-for="(chosenId, idx) in choice.chosenIds" :key="`chosen_option_${idx}`">
									{{ getOptionName(product, choice.optionId, chosenId) }}
								</li>
							</ul>
						</template>
					</div>
					<div style="flex: 1" >
						{{ formatFloat(product.price) }}
					</div>
					<div style="flex: 1">
						{{ formatPrice(product) }}
					</div>
					<div style="flex: 0">
						<XmarkIcon v-if="product.cartItemCount <= 1 || product.servingQtyMin === 2" class="fa-2x" style="color:var(--bs-body-color)"/>
						<MinusIcon v-else class="fa-2x" style="color:var(--bs-body-color)"/>
					</div>
				</div>
			</li>
		</template>
	</ul>
</template>

<script>
import {mapGetters} from "vuex";
import XmarkIcon from "@/core/icons/XmarkIcon.vue";
import MinusIcon from "@/core/icons/MinusIcon.vue";
import {viewSectionAccounts} from "@/utils";

export default {
	name: "TpvListProductsCart",
	components: {MinusIcon, XmarkIcon},
	inject: {
		dateFormatterService: 'dateFormatterService',
	},
	computed:{
		...mapGetters('menu', ['productsCart']),
		...mapGetters('tpv',['isAddProductsCartInAccount']),
		sortedProductsCart() {
			return this.productsCart.slice().sort((a, b) => b.timestamp - a.timestamp);
		}
	},
	methods:{
		removeProduct(product) {
			this.$store.commit('menu/subtractUnit', {product})
		},

		getOptionName(product, optionId, chosenId) {
			const productOption = product.productOptions.find(option => option.id === optionId);
			const option = productOption ? productOption.options.find(opt => opt.id === chosenId) : null;
			return option ? option.name : '';
		},

		formatFloat(price) {
			if (isNaN(price)) {
				return price;
			}
			return this.dateFormatterService.formattedPriceEmployed(price)
		},

		formatPrice(product) {
			let total = product.cartItemCount * product.price;

			return total.toFixed(2).replace(".", ",");
		},
		handleViewSection() {
			viewSectionAccounts()
		},
	}

}
</script>

<style scoped>

</style>

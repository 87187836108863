<template>
	<TpvListProductsCart />

	<ul class="list-group">
		<li class="list-group-item list-group-item-secondary">
			<div class="row g-0 fw-bold">
				<div class="col-6">
					Total
				</div>
				<div class="col-6 text-end">
					{{ totalProductsCart }}
				</div>
			</div>
		</li>
	</ul>
	<div class="btn-group dropdown-center">
		<button
			v-for="(value,index) in numberPax"
			:key="index"
			class="btn btn-tpv-sm btn-outline-secondary border-0"
			:class="{ 'active': value.label === selectedPax }"
			style="width: 40px; height: 50px;"
			@click="handleSelectedPax(value.label,false)"
		>
			<i class="fa-solid fa-user"></i>
			{{ value.label }}
		</button>

		<button
			class="btn btn-tpv-sm btn-outline-secondary btn-lg dropdown-toggle border-0"
			style="width: 40px; height: 50px;"
			:class="{ 'active': isMorePaxSelected }"
			type="button"
			data-bs-toggle="dropdown"
			aria-expanded="false"
		>
			<i class="fa-solid fa-user pe-1"></i>
			<span v-if="!isMorePaxSelected">
				<i class="fa-solid fa-plus"></i>
			</span>
			<span v-else>
				{{ selectedPax }}
			</span>
		</button>
		<ul class="dropdown-menu bg-white custom-dropdown-menu">
			<li
				v-for="(value, index) in selectedMorePax"
				:key="index"
			>
				<button
					@click="handleSelectedPax(value.label, true)"
					class="dropdown-item text-primary"
					style="height: 50px;"
				>
					{{ value.label }}
				</button>
			</li>
		</ul>
	</div>

	<div class="btn-group dropdown-center">
		<button
			class="btn btn-tpv-sm btn-outline-secondary btn-lg dropdown-toggle border-0"
			:class="{'active': !isCreateTag || isSelectedTag}"
			style="width: 40px; height: 50px;"
			type="button"
			data-bs-toggle="dropdown"
			aria-expanded="false"
			id="dropdown-selected-tag"
		>
			{{ selectedTag }}
		</button>
		<ul class="dropdown-menu bg-white custom-dropdown-menu" aria-labelledby="dropdown-selected-tag">
			<li
				v-for="locationTag in reservationLocationTags"
				:key="locationTag.id"
			>
				<button
					class="dropdown-item text-primary"
					@click="handleSelectedTag(locationTag.name)"
					style="height: 50px;"

				>
					{{ locationTag.name }}
				</button>
			</li>
		</ul>

		<button
			class="btn btn-tpv-sm btn-outline-secondary btn-lg dropdown-toggle border-0"
			:class="{'active': isCreateTag}"
			style="width: 40px; height: 50px;"
			type="button"
			data-bs-toggle="dropdown"
			aria-expanded="false"
			id="dropdown-create-tag"
		>
			<span v-if="!isCreateTag">
				Nueva Zona
			</span>
			<span v-else>
				{{ locationTagName }}
			</span>
		</button>
		<div class="dropdown-menu bg-white p-1 custom-dropdown-menu" aria-labelledby="dropdown-create-tag">
			<form action="" @submit.prevent="addLocationTag">
				<div class="input-group">
					<input type="text" autofocus class="form-control" style="height: 50px;" placeholder="Ubicación en Barra" aria-label="Ubicación en Barra" aria-describedby="basic-addon1" v-model="locationTagName">
					<button :disabled="!locationTagName.length" class="btn btn-primary">
						Agregar
					</button>
				</div>
			</form>
		</div>
	</div>

	<div class="btn-group">
<!--		:disabled="this.viewSectionItems === 'tables' && this.viewSectionAccounts === 'createAccount' || !productsCart.length"-->

		<button
			class="btn btn-t-smpv border-0 btn-light"
			style="width: 40px; height: 50px; flex: 1"
			@click="resetProductsCart"
		>
			<i class="fa-solid fa-arrow-left"></i>
		</button>
		<button
			class="btn btn-tpv-sm border-0 btn-outline-secondary"
			style="width: 40px; height: 50px; flex: 1"
			:disabled="!productsCart.length"
			@click="handleChangeTable"
		>
			Crear en Mesa
		</button>
		<button
			ref="buttons"
			class="btn btn-tpv-sm btn-secondary border-0"
			:disabled="!productsCart.length"
			style="width: 40px; height: 50px; flex: 1"
			@click="createAccountInBar"
		>
			Nuevo en Barra
		</button>
	</div>

</template>

<script>
import {mapGetters} from "vuex";
import store from "@/store";
import * as bootstrap from "bootstrap";
import {walkingService} from "@/service/walkingService";
import {showModal, viewSectionStandar} from "@/utils";
import TpvListProductsCart from "@/components/tpv/tpvListProductsCart/TpvListProductsCart.vue";

export default {
	name: "TpvAccount",
	data() {
		return {
			isCreateAccountInBar: false,
			isMorePaxSelected: false,
			isCreateTag: false,
			isSelectedTag: true,

			selectedPax: 1,
			numberPax: [
				{ label: 1 },
				{ label: 2 },
				{ label: 3 },
				{ label: 4 },
				{ label: 5 },
				{ label: 6 }
			],
			selectedMorePax: [
				{ label: 7 },
				{ label: 8 },
				{ label: 9 },
				{ label: 10 },
				{ label: 11 },
				{ label: 12 },
			],

			selectedTag: 'Zona',

			locationTagName: '',

		}
	},
	components: {TpvListProductsCart},
	props: {
		chosenTable: {
			type: Object
		},
		reservationDetails: {
			type: Object,
		},
	},
	computed: {
		...mapGetters('menu', ['productsCart']),
		...mapGetters('tpv', ['newAccount', 'viewSectionAccounts', 'viewSectionItems']),
		...mapGetters('copilot', ['tables', 'reservations']),
		...mapGetters('venue', ['reservationLocationTags']),

		totalProductsCart() {
			return this.productsCart.reduce((total, product) => {
				return total + product.cartItemCount * product.price;
			}, 0).toFixed(2).replace(".", ",");
		}
	},
	mounted() {
		this.$nextTick(() => {
			this.adjustFontSize();

			store.commit('tpv/updateNewAccount',{
				pax: this.selectedPax
			})
			store.commit('tpv/updateNewAccount', {
				locationTag: ''
			});
		});
	},
	beforeMount() {
		this.adjustFontSize()
	},
	methods: {

		isTableOccupied(table) {
			return this.reservations.find(reservation =>
				reservation.table === table.name &&
				reservation.menuAccessEnabled === 1 &&
				reservation.status !== 7 &&
				reservation.status !== 10
			);
		},

		async createAccountInBar(){
			this.isCreateAccountInBar = true;
			const firstAvailableTableInBar = this.tables
				.filter(table => table.room === "Barra")
				.find(table => !this.isTableOccupied(table));

			const tables = [firstAvailableTableInBar.table_id];
			const products = this.productsCart.flatMap(product => {
				if (product.isUnitary === "SI") {
					return {
						sku: product.sku,
						options: product.chosenOptions,
						units: product.cartItemCount,
						modifyDishClass: product.modifyDishClass?.id,
						isShareable: product.isShareable
					}
				} else {
					return Array(product.cartItemCount).fill({
						sku: product.sku,
						options: product.chosenOptions,
						modifyDishClass: product.modifyDishClass?.id,
						isShareable: product.isShareable
					});
				}
			});

			store.commit('tpv/updateNewAccount', {
				products: products,
				tables: tables,
			})

			store.commit('tpv/setIsLoading', true);
			console.log(this.newAccount);

			let resultWalkin = await walkingService.create(this.newAccount);
			console.log("El resultado de crear el walkin", resultWalkin);

			if (resultWalkin.result === 0) {
				this.resetStoreNewAccount();

				this.isCreateAccountInBar = false;
				store.commit('tpv/setIsLoading', false);

				viewSectionStandar();

			} else {
				store.commit("copilot/setToast", {toast: {toastMessage: 'No se ha podido crear el sit in.', showToast: true, idToast: "incorrectlyToast"}});
				store.commit('tpv/setIsLoading', false);
			}
		},


		handleChangeTable() {
			this.isCreateAccountInBar = false;
			showModal('tpv-modal-selected-table')

			// store.commit('menu/setCategorySelected', '');
			// store.commit("tpv/setViewSectionItems", "tables");
			// store.commit('tpv/updateNewAccount', {
			// 	tables: null
			// })
		},

		handleSelectedPax(pax, isDropdown) {
			this.selectedPax = pax;
			this.isMorePaxSelected = isDropdown;

			store.commit('tpv/updateNewAccount',{
				pax: pax
			});

		},

		handleSelectedTag(nameTag) {
			this.isSelectedTag = true;
			this.isCreateTag = false;
			this.locationTagName = '';
			this.selectedTag = nameTag;
			if (nameTag === 'Zona') {
				store.commit('tpv/updateNewAccount', {locationTag: ''});
			} else {
				store.commit('tpv/updateNewAccount', {locationTag: nameTag});
			}
			this.viewSectionBtns = 'viewPax';
			this.closeDropdown('dropdown-selected-tag')
		},
		addLocationTag() {
			this.isSelectedTag = false;
			this.isCreateTag = true;
			store.commit('tpv/updateNewAccount', {locationTag: this.locationTagName});
			this.selectedTag = 'Zona'
			this.closeDropdown('dropdown-create-tag');
		},

		resetStoreNewAccount() {
			store.commit("tpv/setViewSectionAccounts", 'allAccounts');
			store.commit("tpv/setViewSectionItems", 'topChoice');
			store.commit('menu/setProductsCart', {productsCart: []});
			store.commit('tpv/setNewAccount', null)
			this.isSelectedTag = true;
			this.isCreateTag = false;
		},

		closeDropdown(id) {
			let dropdownElement = document.getElementById(id);
			let dropdownInstance = bootstrap.Dropdown.getInstance(dropdownElement);
			if (dropdownInstance) {
				dropdownInstance.hide();
			}
		},
		adjustFontSize() {
			if (this.$refs.buttons) {
				let button = this.$refs.buttons;
				let fontSize = 14;
				button.style.fontSize = `${fontSize}px`;
				while (button.scrollHeight > button.offsetHeight && fontSize > 1) {
					fontSize -= 1;
					button.style.fontSize = `${fontSize}px`;
				}
			}
		},

		resetProductsCart() {
			store.commit('menu/setProductsCart', {productsCart: []});
			store.commit('tpv/setViewSectionItems', 'topChoice');
			store.commit('tpv/setViewSectionAccounts', 'allAccounts');
			store.commit('tpv/setIsChangeTable', false);
			store.commit('tpv/setNewAccount', null);
			store.commit('tpv/clearListProductsSelected');
			viewSectionStandar()
		},
	}
}
</script>

<style scoped>
.custom-dropdown-menu {
	position: fixed !important;
	max-height: 200px;
	overflow-y: auto;
	z-index: 1050;
}
</style>

<template>
	<i class="fa-solid fa-minus" ></i>
</template>

<script>
export default {
	name: "MinusIcon"
}
</script>

<style scoped>

</style>

<template>
	<i class="fas fa-walking"></i>
</template>

<script>
export default {
	name: "WalkingIcon"
}
</script>

<style scoped>

</style>

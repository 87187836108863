<script>
export default {
	name: "CartPlusIcon"
}
</script>

<template>
	<i class="fa-solid fa-plus"></i>

</template>

<style scoped>

</style>


<template>
	<div class="modal fade" id="tpv-modal-method-payment" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" v-if="reservation">
		<div class="modal-dialog modal-dialog-centered">
			<div class="modal-content">
				<div class="modal-header d-block">
					<div class="modal-title" id="exampleModalLabel">
						<div class="row">
							<div class="col-6">
								<span class="ps-2" v-if="reservation.name">{{ reservation.name }}</span>
							</div>
							<div class="col-6">
								<TableIcon class="me-1"/>
								<template  v-if="!reservation.locationTag">
									<span v-if="reservation.table">{{ reservation.table }}</span>
									<span class="vr mx-1"></span>
									<span v-if="reservation.roomName">{{ reservation.roomName }}</span>
								</template>
								<template v-else>
									<span v-if="reservation.locationTag">{{ reservation.locationTag }}</span>
									<span class="vr mx-1"></span>
									<span v-if="reservation.roomName">{{ reservation.roomName }}</span>
								</template>
							</div>
						</div>
					</div>
				</div>
				<div class="modal-body d-flex flex-column justify-content-center" style="height: 50vh">
					<button style="height: 8rem" class="btn btn-secondary btn-welcom btn-xxl w-100" :disabled="isDisabledBtn" @click="handleFinishReservation(0,1)" v-html="finishReservationTextButtonCash">
					</button>
					<button style="height: 8rem;" class="btn btn-secondary btn-welcom btn-xxl w-100 mt-3" :disabled="isDisabledBtn" @click="handleFinishReservation(0,2)" v-html="finishReservationTextButtonCard">
					</button>
				</div>
				<div class="modal-footer">
					<DismissButton class="text-uppercase" :is-modal="true" @click="handleClose"/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import TableIcon from "@/core/icons/TableIcon.vue";
import DismissButton from "@/core/DismissButton.vue";
import store from "@/store";
import {hideAllModals} from "@/utils";
import {mapActions, mapGetters} from "vuex";

export default {
	name: "TpvModalMethodPayment" ,
	components: {DismissButton, TableIcon},
	inject: {
		reservationRepository: 'reservationRepository',
	},
	data() {
		return {
			finishReservationTextButtonCash: 'Pago en Efectivo',
			finishReservationTextButtonCard: 'Pago con Tarjeta',
			isDisabledBtn: false
		}
	},
	computed:{
		...mapGetters('copilot',['reservations']),
		...mapGetters('tpv', ['accountSelectedId']),
		reservation() {
			return this.reservations.find(reservation => reservation.id === this.accountSelectedId);
		}
	},
	methods: {
		...mapActions('weWingman', ['printTicket', 'printBill']),

		async handleFinishReservation(noShow, paymentMethod) {
			try {
				this.isDisabledBtn = true;
				if (paymentMethod === 1) {
					this.finishReservationTextButtonCash = `Pidiendo... <i class="fas fa-spinner fa-spin"></i>`
				}
				if (paymentMethod === 2) {
					this.finishReservationTextButtonCard = `Pidiendo... <i class="fas fa-spinner fa-spin"></i>`
				}

				const resultFinishReservation = await this.reservationRepository.finishReservation(this.reservation.id, noShow)

				const resultRequestTicket = await store.dispatch('menu/requestAccount', {
					reservationInfo: this.reservation,
					paymentMethod: paymentMethod
				});

				if (resultFinishReservation.result === 0) {
					// store.commit("copilot/setToast", {
					// 	toast: {
					// 		toastMessage: "Se ha finalizad la reserva correctamente",
					// 		showToast: true,
					// 		idToast: "correctlyToast"
					// 	}
					// });
					this.finishReservationTextButtonCash = 'Pago en efectivo';
					this.finishReservationTextButtonCard = 'Pago con tarjeta';

          if (resultRequestTicket.bill != null){
            await this.printBill(resultRequestTicket.bill);
          }

          store.commit('tpv/setAccountSelectedId', null)

					hideAllModals()
					this.isDisabledBtn = false;
				}
			} catch (e) {
				this.isDisabledBtn = false;

				console.log(e)
			}
		},

		async handleRequestTicket() {
			try {
				const resultTicket = await store.dispatch('menu/requestAccount', {reservationInfo: this.reservation, user: this.user});

				if (resultTicket.result === 0) {
					console.log('handleRequestTicket correcto')
				}
			} catch (e) {
				console.log(e)
			}
		},

		handleClose() {
			store.commit('tpv/setAccountSelectedId', null)
		}
	}
}
</script>


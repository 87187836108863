<template>
	<button :disabled="tableSelectDisabled" type="button" class="btn btn-light w-100 btn-sm" @click="toggleDropdown">
		<span v-if="selectedTables.length === 0">Selecciona una mesa</span>
		<span v-else>
        <span v-for="(names, room) in groupedTablesByRoom()" :key="room">
           {{ room }} | <table-icon fill-color="black"></table-icon> {{ names.join(', ') }}
        </span>
      </span>
	</button>

	<div class="offcanvas offcanvas-bottom" tabindex="-1"  :id="'modalSelectedTable'+reservation.id" aria-labelledby="offcanvasBottomLabel">
		<div class="offcanvas-header mb-0">
			<h5 class="offcanvas-title" id="offcanvasBottomLabel">Selecciona la mesa</h5>
			<button type="button" @click="closeModal" class="btn-close" aria-label="Close"></button>
		</div>

		<div class="offcanvas-body p-3">
			<p><strong>Mesa actual:</strong></p>
			<div class="mb-3">
				<div v-if="selectedTables.length === 0">Selecciona una mesa</div>
				<div v-else v-for="table in selectedTables" :key="table.table_id" class="selected-table" @click="removeTable(table)">
					<span >
						{{ getRoom(table.name) }} | {{ table.name }}
					</span>
				</div>
			</div>

			<div class="dropdown">
				<div
					v-for="table in localTables"
					:key="table.table_id"
					@click="toggleSelection(table)"
					:class="{ selected: isSelected(table) }"
				>
					{{ getRoom(table.name) }} | {{ table.name }}
				</div>
			</div>
		</div>

		<div class="offcanvas-footer">
			<button class="btn btn-outline-primary" @click="closeModal">No cambiar</button>
			<button class="btn btn-primary" :disabled="isLoadingUpdateTable" @click="updateTables">
				<span v-if="!isLoadingUpdateTable">Actualizar mesa <table-icon fill-color="white"/></span>
				<span v-else>Actualizando <i class="fas fa-spinner fa-spin"></i></span>
			</button>
		</div>
	</div>
</template>
<script>
import {mapGetters} from "vuex";
import store from "@/store";
import {arraysAreEqualIgnoringOrder, hideAllOffcanvas} from "@/utils";
import * as bootstrap from 'bootstrap'
import TableIcon from "@/core/icons/TableIcon";

export default {
	name: 'CopilotCardSelectTable',
	components: {TableIcon},
	inject: {
		reservationRepository: 'reservationRepository',
		copilotRepository: 'copilotRepository',
	},
	props: {
		reservation: {},
	},
	data() {
		return {
			selectedTables: [],
			dropdownOpen: false,
			isLoadingUpdateTable: false
		}
	},
	computed: {
		...mapGetters('copilot', ['tables']),
		localTables() {
			return this.tables.filter(table => table);
		},
		tableSelectDisabled() {
			return this.reservation.finishedDateTime != null;
		},
		hasReservationTablesChanged() {
			let reservationTables = []
			if (this.reservation.table) {
				reservationTables = this.reservation.table.split(",").map(tableName => {
					return {
						...this.tables.find(t => {
							return t.name === tableName.trim();
						})
					};
				});
			}

			return !arraysAreEqualIgnoringOrder(this.selectedTables, reservationTables)
		}
	},
	beforeMount() {

	},
	mounted() {
		this.synchronizeTables()
		// document.addEventListener('click', this.closeDropdownOnOutsideClick);
	},
	beforeUnmount() {
		// document.removeEventListener('click', this.closeDropdownOnOutsideClick);
	},
	methods: {
		closeModal() {
			event.stopPropagation();
			this.synchronizeTables()
			hideAllOffcanvas()
		},
		toggleDropdown() {
			event.stopPropagation();
			const modalId = document.getElementById('modalSelectedTable' + this.reservation.id)
			const elementModal = new bootstrap.Offcanvas(modalId)
			elementModal.show()

			if (!this.tableSelectDisabled) {
				// this.dropdownOpen = !this.dropdownOpen;
			}
		},
		toggleSelection(table) {
			event.stopPropagation();
			if(!this.hasReservationTablesChanged){
				console.log('No ha habido cambios')
				this.selectedTables = []
			}
			const index = this.selectedTables.findIndex(t => t.table_id === table.table_id);
			if (index === -1) {
				this.selectedTables.push(table);
			} else {
				console.log(this.selectedTables.length, !this.selectedTables.length)
				if (this.selectedTables.length === 1) {
					store.commit("copilot/setToast", {
						toast: {
							toastMessage: `Tienes que seleccionar al menos una mesa.`,
							showToast: true,
							idToast: "incorrectlyToast"
						}
					});
					this.dropdownOpen = false;
					this.synchronizeTables();
					return
				}
				this.selectedTables.splice(index, 1);
			}
		},
		removeTable(table) {
			console.log(table)
			const index = this.selectedTables.findIndex(t => t.table_id === table.table_id);
			if (index !== -1) {
				if (this.selectedTables.length === 1) {
					store.commit("copilot/setToast", {
						toast: {
							toastMessage: `Tienes que seleccionar al menos una mesa.`,
							showToast: true,
							idToast: "incorrectlyToast"
						}
					});
				} else {
					this.selectedTables.splice(index, 1);
					/*store.commit("copilot/setToast", {
						toast: {
							toastMessage: `Mesa ${this.tables.find(t => t.table_id === table.table_id).name} removida correctamente.`,
							showToast: true,
							idToast: "correctlyToast"
						}
					});*/
				}
			}
		},
		isSelected(table) {
			return this.selectedTables.some(t => t.table_id === table.table_id);
		},
		getRoom(tableName) {
			const table = this.tables.find(t => {
				return t.name === tableName;
			});
			return table ? table.room : 'No Room';
		},
		synchronizeTables() {
			if (this.reservation.table) {
				this.selectedTables = this.reservation.table.split(",").map(tableName => {
					return {
						...this.tables.find(t => {
							return t.name === tableName.trim();
						})
					};
				});
			}

		},
		async updateTables() {
			event.stopPropagation()
			const tableIds = this.selectedTables.map(table => table.table_id)
			console.log(tableIds)
			this.isLoadingUpdateTable = true;

			const resultUpdateTable = await this.copilotRepository.updateTable({
				reservationWid: this.reservation.id,
				tables: tableIds
			});

			if (resultUpdateTable.result === 0) {

				hideAllOffcanvas()

/*				store.commit("copilot/setToast", {
					toast: {
						toastMessage: `Se han cambiado las mesas correctamente.`,
						showToast: true,
						idToast: "correctlyToast"
					}
				});*/

			}
			this.dropdownOpen = false;
			this.isLoadingUpdateTable = false;
		},
		closeDropdownOnOutsideClick(event) {
			if (this.$refs.multiselect && !this.$refs.multiselect.contains(event.target)) {
				this.dropdownOpen = false;
			}
		},

		groupedTablesByRoom() {
			const grouped = {};
			const reservationSelectedTables = this.reservation.table?.split(",").map(tableName => {
				return {
					...this.tables.find(t => {
						return t.name === tableName.trim();
					})
				};
			});

			reservationSelectedTables?.forEach(table => {
				const roomName = this.getRoom(table.name);
				if (!grouped[roomName]) {
					grouped[roomName] = [];
				}
				grouped[roomName].push(table.name);
			});

			return grouped;
		},
	}

}
</script>

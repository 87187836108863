const state = {
	isLoading: false,
	viewSectionAccounts: 'allAccounts',
	viewSectionItems: 'topChoice',

	accountSelectedId: null,
	newAccount: null,
	isChangeTable: false,
	isSelectedPax: false,

	viewBtnsSectionPax: 'viewPax',

	selectedNumberPax: null,
	descriptionZone: null,

	unifyAccounts: {
		originReservationId: null,
		destinationReservationId: null
	},

	destinationAccountProductsOrders:null,
	isAccountMoveProducts:false,
	listProductsSelected: [],

	isAccountSelectedForUnify:false,

	isAddProductsCartInAccount: false,
};

const getters = {
	isLoading: state => {
		return state.isLoading
	},
	viewSectionAccounts: state => {
		return state.viewSectionAccounts
	},
	viewSectionItems: state => {
		return state.viewSectionItems
	},

	accountSelectedId: state => {
		return state.accountSelectedId
	},
	newAccount: state => {
		return state.newAccount
	},
	isChangeTable: state => {
		return state.isChangeTable
	},
	isSelectedPax: state => {
		return state.isSelectedPax
	},
	selectedNumberPax: state => {
		return state.selectedNumberPax
	},
	descriptionZone: state => {
		return state.descriptionZone
	},
	viewBtnsSectionPax: state => {
		return state.viewBtnsSectionPax
	},
	unifyAccounts: state => {
		return state.unifyAccounts
	},
	destinationAccountProductsOrders: state => {
		return state.destinationAccountProductsOrders
	},
	isAccountMoveProducts: state => {
		return state.isAccountMoveProducts
	},
	listProductsSelected: state => {
		return state.listProductsSelected
	},

	isAccountSelectedForUnify: state => {
		return state.isAccountSelectedForUnify
	},

	isAddProductsCartInAccount: state => {
		return state.isAddProductsCartInAccount
	}
};

const mutations = {
	setIsLoading: (state, payload) => {
		state.isLoading = payload;
	},
	setViewSectionAccounts(state, sectionAccounts) {
		state.viewSectionAccounts = sectionAccounts;
	},
	setViewSectionItems(state, sectionItems) {
		state.viewSectionItems = sectionItems;
	},

	setAccountSelectedId(state, accountId) {
		state.accountSelectedId = accountId;
	},
	setNewAccount(state, newAccount) {
		state.newAccount = newAccount;
	},

	updateNewAccount(state, payload) {
		state.newAccount = {
			...state.newAccount,
			...payload,
		};
	},

	setIsChangeTable(state, payload) {
		state.isChangeTable = payload;
	},
	setIsSelectedPax(state, payload) {
		state.isSelectedPax = payload;
	},
	setSelectedNumberPax(state, payload) {
		state.selectedNumberPax = payload;
	},
	setDescriptionZone(state, payload) {
		state.descriptionZone = payload;
	},

	setViewBtnsSection(state, payload) {
		state.viewBtnsSectionPax = payload
	},

	setDestinationAccountProductsOrders(state,payload) {
		state.destinationAccountProductsOrders = payload
	},
	setIsAccountMoveProducts(state, payload) {
		state.isAccountMoveProducts = payload;
	},
	addListProductsSelected(state, productId) {
		if (!state.listProductsSelected.includes(productId)) {
			state.listProductsSelected.push(productId);
		}
	},
	removeListProductsSelected(state, productId) {
		state.listProductsSelected = state.listProductsSelected.filter(id => id !== productId);
	},
	clearListProductsSelected(state) {
		state.listProductsSelected = [];
	},

	setUnifyAccounts(state, payload) {
		state.unifyAccounts = {
			...state.unifyAccounts,
			...payload
		};
	},
	setIsAccountSelectedForUnify(state, payload) {
		state.isAccountSelectedForUnify = payload;
	},
	setIsAddProductsCartInAccount(state, payload) {
		state.isAddProductsCartInAccount = payload;
	}
};

const actions = {
	updateProductSelection({ commit }, { productId, isSelected }) {
		if (isSelected) {
			commit('addListProductsSelected', productId);
		} else {
			commit('removeListProductsSelected', productId);
		}
	}
};

export default {
	namespaced: true,
	state,
	mutations,
	getters,
	actions,
}

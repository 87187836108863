<template>
	<section class="shadow-none m-0 h-100 p-1 border-0 overflow-y-scroll section-primary border-secondary border-1 border">
		<div
				class="btn-group-tpv gap-1 mb-3"
				v-for="menu in sortedMenus"
				:key="menu.id"
			>
				<button
					class="btn btn-primary btn-tpv-sm position-relative shadow-none border-0"
				>
					<span class="text-uppercase fw-bold">
						{{menu.menuTypeName}}
					</span>
				</button>
				<template
					v-for="category in menu.menuCategories"
					:key="category.id"
				>
					<button
						ref="buttons"
						class="btn btn-secondary btn-tpv-sm position-relative shadow-none"
						@click="selectCategory(category.id, category.name)"
						v-if="category.products.length || category.children.length"
						:class="{ 'active': category.id === selectedCategory.id }"
						style="height: 5rem; width: 5rem"
					>
						{{ category.name }}
					</button>
				</template>
			</div>
	</section>
</template>


<script>
import { mapActions, mapGetters } from "vuex";
import store from "@/store";
import {viewSectionAccounts} from "@/utils";
export default {
	name: "TpvMenuCategories",
	computed: {
		...mapGetters("menu", ["menus", "selectedCategory"]),
		...mapGetters('tpv',['isAddProductsCartInAccount']),
		sortedMenus() {
			return Array.isArray(this.menus)
				? [...this.menus].sort((a, b) => a.sortOrder - b.sortOrder)
				: [];
		},
	},
	methods: {
		...mapActions("menu", ["setCategorySelected"]),

		selectCategory(categoryId, categoryName) {
			this.setCategorySelected({ id: categoryId, name: categoryName });

			if (!this.isAddProductsCartInAccount){
				store.commit('tpv/setViewSectionAccounts', "createAccount")
			}

			viewSectionAccounts()
			this.showProducts()
		},

		showProducts() {
			this.$store.commit('tpv/setViewSectionItems','products')
		},
	},
};
</script>

<style scoped>

</style>

<template>
	{{ displayDate }} h
</template>

<script>
export default {
	name: "ReservationStartDate",
	props: {
		startDate: {
			type: String,
			required: true
		}
	},
	computed: {
		displayDate() {
			return this.startDate.trim() ? this.startDate : "";
		}
	}
}
</script>

<style scoped>

</style>

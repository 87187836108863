<template>
	<div class="col-12" v-if="productsList.length">
		<ul class="list-group">
			<li class="list-group-item">
				<div class="row g-0">
					<div class="col-6">
              <span v-if="reservation.name">
                <i class="fa-solid fa-qrcode"></i>
                {{ reservation.name }}
              </span>
					</div>
					<div class="col-6 text-end fw-bold" v-if="reservation.table && reservation.roomName && !reservation.locationTag">
						{{ reservation.table }}
						<div class="vr mx-1"></div>
						{{ reservation.roomName }}
					</div>
					<div v-if="reservation.locationTag && reservation.roomName === 'Barra'" class="col-6 text-end fw-bold">
						{{ reservation.locationTag }}
						<div class="vr mx-1"></div>
						{{ reservation.roomName }}
					</div>
				</div>
			</li>
			<li class="list-group-item" v-if="clientMessage">
				<div>
					<strong>Petición del cliente:</strong>
					{{ clientMessage }}
				</div>
			</li>
		</ul>

		<ul class="list-group">
			<li class="list-group-item list-group-item-light">
				<div class="col-12" v-for="product in productsList" :key="product.id">
					<label class="row g-0 border-bottom align-items-start py-1" :for="`option_checkbox_${product.name}-${product.productOrderId}`">
						<div class="col-2 col-lg-1">
            <span class="fw-bold fs-5">
              {{ product.units }}
            </span>
						</div>
						<div class="col-5 col-lg">
							<div class="row">
								<div class="col-12">
									{{ product.name }}
								</div>
								<div class="col-12" v-if="product.choices">
									<ul v-for="(choiceGroup, index) in product.choices" :key="index">
										<li>{{ choiceGroup.choice }}</li>
									</ul>
								</div>
							</div>
						</div>
						<div class="col-2 col-lg-1">
							<input class="form-check-input fs-4" type="checkbox" :checked="isChecked(product.productOrderId)" :id="`option_checkbox_${product.name}-${product.productOrderId}`" :value="product.productOrderId" @change="handleCheckboxChange(product.productOrderId, $event)">
						</div>
					</label>
				</div>
			</li>
		</ul>

		<button class="btn btn-primary w-100 my-3" :disabled="isLoading" v-html="buttonTextOrder" @click="handleProductOrdersReady()"></button>
	</div>
</template>

<script>
import {mapGetters} from "vuex";
import store from "@/store";
import {hideAllOffcanvas, viewSectionDrinksToPrepare} from "@/utils";

export default {
	name: "TpvListProductOrder",
	inject: {
		productOrderRepository: 'productOrderRepository',
	},
	props: {
		reservation: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			isLoading: false,
			source: 'hybridOffcanvas',
			isServed: false,
		}
	},
	computed: {
		...mapGetters('copilot', ['selectedProductOrdersToServe', 'selectedProductOrdersToPrepared', 'selectedRole']),


		productsList() {
			const orderedItems = this.reservation.orderedItems;

			if (!orderedItems || !Array.isArray(orderedItems)) {
				return [];
			}

			const filteredAndSortedItems = orderedItems
				.filter(item =>
					item.statusProductOrder > 0 &&
					item.statusProductOrder < 3 &&
					item.statusProductOrder < 100 &&
					item.requirementProductCopilotRoles.some(role => role.name.includes('Barra'))
				)
				.sort((a, b) => {
					if (a.statusProductOrder === 2 && b.statusProductOrder !== 2) {
						return -1;
					}
					if (a.statusProductOrder !== 2 && b.statusProductOrder === 2) {
						return 1;
					}
					if (a.statusProductOrder === b.statusProductOrder) {
						return new Date(a.createdDateTime) - new Date(b.createdDateTime);
					}
					return a.statusProductOrder - b.statusProductOrder;
				});

			return filteredAndSortedItems.length > 0 ? filteredAndSortedItems : [];
		},

		isChecked() {
			return (orderId) => {
				return this.selectedProductOrdersToServe.includes(orderId) || this.selectedProductOrdersToPrepared.includes(orderId);
			};
		},

		drinkProductOrdersAllOfReservation() {
			return this.reservation.orderedItems
				? this.reservation.orderedItems
					.filter(item => {
						return item.statusProductOrder > 0 &&
							item.statusProductOrder < 3 &&
							item.statusProductOrder < 100 &&
							item.requirementProductCopilotRoles.some(role => role.name.includes('Barra'))
					})
					.map(item => item.productOrderId)
				: []
		},
		selectedProductOrdersToPreparedOfReservation() {
			return this.drinkProductOrdersAllOfReservation.filter(productOrder => this.selectedProductOrdersToPrepared.includes(productOrder));
		},

		buttonTextOrder() {
			const readyProducts = this.selectedProductOrdersToPreparedOfReservation.length;
			console.log('REadyPRoducys', readyProducts)
			let tableInfo = ''
			if (this.reservation.roomName === 'Barra') {
				tableInfo = `zona ${this.reservation.locationTag}`;
			} else {
				tableInfo = `mesa ${this.reservation.table}`;
			}

			if (readyProducts === 0) {
				return `Todo listo de la ${tableInfo}`;
			}

			return `Servir ${readyProducts} bebida${readyProducts > 1 ? 's' : ''} de la ${tableInfo}`;
		},

		clientMessage() {
			const orderedItems = this.reservation.cartSession;

			console.log('ordereded', orderedItems)

			const messages = orderedItems.productOrders
				.map(item => item.clientMessage)
				.filter(message => message);

			return messages.length ? messages.join(', ') : null;
		},
	},
	methods: {
		getOptionName(product, optionId, chosenId) {
			const productOption = product.productOptions.find(option => option.id === optionId);
			const option = productOption ? productOption.options.find(opt => opt.id === chosenId) : null;
			return option ? option.name : '';
		},

		handleCheckboxChange(productOrderId, event) {
			viewSectionDrinksToPrepare();

			let selectedProductOrdersLocalArray = []
			console.log('Que es', this.source)
			if (this.source === 'readyOrder') {
				selectedProductOrdersLocalArray = [...this.selectedProductOrdersToServe];
			} else if (this.source === 'hybridOffcanvas') {
				selectedProductOrdersLocalArray = [...this.selectedProductOrdersToPrepared];
			}
			if (event.target.checked) {
				if (!selectedProductOrdersLocalArray.includes(productOrderId)) {
					selectedProductOrdersLocalArray.push(productOrderId);
				}
			} else {
				selectedProductOrdersLocalArray = selectedProductOrdersLocalArray.filter(prodOrdId => prodOrdId !== productOrderId);
			}
			if (this.source === 'readyOrder') {
				store.commit('copilot/setSelectedProductOrdersToServe', {selectedProductOrdersToServe: selectedProductOrdersLocalArray});
				console.log('despues del handle', selectedProductOrdersLocalArray)
			} else if (this.source === 'hybridOffcanvas') {
				store.commit('copilot/setSelectedProductOrdersToPrepared', {selectedProductOrdersToPrepared: selectedProductOrdersLocalArray});
				console.log('despues del handle hybridOffcanvas', selectedProductOrdersLocalArray)
			}

		},
		async handleProductOrdersReady() {
			this.isLoading = true;

			if (this.reservation.roomName === 'Barra') {
				this.isServed = true;
			}

			const data = this.selectedProductOrdersToPreparedOfReservation.length
				? this.selectedProductOrdersToPreparedOfReservation
				: this.drinkProductOrdersAllOfReservation;

			const resultFinishPreparation = await this.productOrderRepository.finishPreparation({
				productOrderIds: data,
				roleId: this.selectedRole,
				servedIsPossible: this.isServed
			});

			if (resultFinishPreparation.result === 0) {
				store.commit('copilot/setSelectedProductOrdersToServe', {selectedProductOrdersToServe: []});
				store.commit('copilot/setSelectedProductOrdersToPrepared', {selectedProductOrdersToPrepared: []});
				hideAllOffcanvas()
				this.isLoading = false;
				this.isServed = false;

			} else {
				store.commit("copilot/setToast", {
					toast: {
						toastMessage: `Ha habido un error marcando el pedido como listo.`,
						showToast: true,
						idToast: "incorrectlyToast"
					}
				});
				this.isLoading = false;
				this.isServed = false;
			}

			this.isLoading = false;
			this.isServed = false;
		},
	}
}
</script>

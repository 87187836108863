<template>
	<svg height="20" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="2 0 19 22" xml:space="preserve">
  <path d="M12.612 19.442v-5.759c2.181-.591 4.174-2.511 3.945-5.222l-.8-8.1A.4.4 0 0 0 15.359 0h-3.836l-1.002 2.138 1.757.738a.64.64 0 0 1 .345.354.64.64 0 0 1-.015.494l-1.419 3.074a.64.64 0 0 1-.575.368.6.6 0 0 1-.265-.058.634.634 0 0 1-.309-.84l1.144-2.479-1.76-.739a.64.64 0 0 1-.346-.356.64.64 0 0 1 .018-.496L10.125 0H6.346a.4.4 0 0 0-.398.361l-.8 8.105c-.121 1.392.273 2.635 1.14 3.594.688.761 1.675 1.331 2.805 1.625v5.757c-2.001.299-3.418 1.502-3.481 1.556a.4.4 0 0 0 .261.703h9.96a.4.4 0 0 0 .261-.703c-.063-.054-1.48-1.257-3.481-1.556"/>
</svg>

</template>

<script>
export default {
	name: 'BrokenCupIcon',
	props: {
		fillColor: String
	}
}
</script>

<template>
	<div class="offcanvas offcanvas-bottom" tabindex="-1" :id="'offcanvas-internal-comment'" aria-labelledby="offcanvasBottomLabel">
		<div class="offcanvas-header mb-0">
			<div class="offcanvas-title h4" id="offcanvasBottomLabel">
				{{ t('copilot.internalComment') }}
			</div>
			<button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
		</div>

		<div class="offcanvas-body p-3">
			<div v-if="reservation">
				<p v-if="reservation.staffComments">
					{{reservation.staffComments}}
				</p>

				<form>
					<div class="form-floating w-100">
						<textarea v-model="internalComment" class="form-control" placeholder="¿Algún comentario privado para esta reserva?" id="comments"></textarea>
						<label for="comments">{{ t('copilot.internalCommentQuestion') }}</label>
					</div>
					<div class="content-center">
						<button class="btn btn-primary" @click="addInternalComment" :disabled="isLoading">
							<span v-if="!isLoading">Guardar</span><span v-else>Guardando... <SpinnerIcon/></span>
						</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
import {useI18n} from "vue-i18n";
import {mapGetters} from "vuex";
import {reservationRepository} from "@/service/repository/reservationRepository";
import SpinnerIcon from "@/core/icons/SpinnerIcon.vue";
import store from "@/store";
import {hideAllOffcanvas} from "@/utils";

export default {
	components: {SpinnerIcon},
	inject: {
		reservationRepository: 'reservationRepository',
	},
	name: "CopilotOffcanvasInternalComment",
	setup() {
		const { t } = useI18n();

		return { t };
	},
	data(){
		return {
			internalComment: null,
			isLoading: false,
		}
	},
	computed:{
		...mapGetters('copilot',['selectedReservation']),
		reservation(){
			return this.selectedReservation
		}
	},
	mounted() {
		// Asigna el valor la primera vez
		if (this.reservation && this.reservation.staffComments){
			this.internalComment = this.reservation.staffComments;
		} else {
			this.internalComment = null;
		}

		// Obtén el elemento offcanvas
		const offcanvasEl = document.getElementById('offcanvas-internal-comment');
		if(offcanvasEl) {
			// Escucha el evento que se dispara cada vez que se muestra el offcanvas
			offcanvasEl.addEventListener('shown.bs.offcanvas', () => {
				// Al abrir, actualiza internalComment según el staffComments actual
				if (this.reservation.staffComments) {
					this.internalComment = this.reservation.staffComments;
				} else {
					this.internalComment = null;
				}
			});
		}
	},
	watch: {
		reservation: {
			immediate: true,
			handler(newReservation) {
				this.internalComment = newReservation && newReservation.staffComments ? newReservation.staffComments : null;
			}
		}
	},
	methods:{
		async addInternalComment(){
			this.isLoading = true;

			const data = {
				reservationId: this.reservation.id,
				staffComments: this.internalComment
			};

			const result = await reservationRepository.addInternalCommentReservation(data);
			if(result.result === 0){
				hideAllOffcanvas();

				this.internalComment = null;
				this.isLoading = false;
			} else {
				store.commit("copilot/setToast", {
					toast: {
						toastMessage: 'Ha ocurrido un error al intentar agregar el comentario interno.',
						showToast: true,
						idToast: "incorrectlyToast"
					}
				});
				this.isLoading = false;
			}


		}
	}
}
</script>


<style scoped>

</style>

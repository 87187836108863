<template>
	<span v-if="reservationReadyPayment && reservationPaymentInfo.payments.length" class="alert-reserve text-dark process-pay">
		Cuenta solicitada

		<ReservationPaymentIcons :reservation-payment-info="reservationPaymentInfo" />
	</span>
</template>

<script>
import ReservationPaymentIcons from "@/core/reservation/ReservationPaymentIcons.vue";

export default {
	name: "ReservationReadyPayment" ,
	props:{
		reservationReadyPayment: {
			type: String,
			required: true,
		},
		reservationPaymentInfo:{
			type: Object,
			required: true
		}
	},
	components: {ReservationPaymentIcons}
}
</script>

<style scoped>

</style>

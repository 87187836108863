<template>
	<div :id="id" class="modal fade" tabindex="-1" role="dialog" :aria-labelledby="`${id}-label`" aria-hidden="true">
		<div v-if="commanderReservation" class="modal-dialog modal-dialog-scrollable modal-fullscreen" role="document">
			<div class="modal-content">
				<div class="modal-header">
					<div class="modal-title h4" :id="`${id}-label`">
						{{ commanderReservation.roomName }}
						<span class="vr mx-2"></span>
						<TableIcon/>
						{{ commanderReservation.table }}
					</div>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body p-0">
					<div class="container-fluid h-100 g-0">
						<div class="row h-100 g-0">
							<!-- Scrollable Content -->
							<div class="col-8 h-100 overflow-y-scroll" ref="scrollContainer">
								<div class="scroll-section-wrapper">
									<div v-for="menu in filteredMenus" :key="menu.id" :id="menu.id" class="h-100">
										<ProductsCategory :category="menu"/>
									</div>
								</div>
							</div>

							<!-- Sidebar -->
							<div class="col-4 h-100 overflow-y-scroll ">
								<div id="custom-navbar" v-for="(menu, index) in filteredMenus" :key="index" class="my-3 list-group">

									<!-- Menú Principal -->
									<a
										class="list-group-item list-group-item-action"
										@click.prevent="scrollToSection(`${menu.id}-${menu.name}`)"
										:class="{ 'active': activeSection === `${menu.id}-${menu.name}` }"

									>
										{{ menu.menuTypeName }}
									</a>

									<!-- Subcategorías -->
									<a
										v-for="(category, cIndex) in menu.menuCategories"
										:key="`cat-${cIndex}`"
										class="list-group-item list-group-item-action list-group-item-light"
										@click.prevent="scrollToSection(`${category.id}-${category.name}`)"
										:class="{ 'active': activeSection === `${category.id}-${category.name}` }"
									>
										{{ category.name }}
									</a>
								</div>
							</div>
						</div>
					</div>

					<template v-if="product">
						<MenuProductCardOffCanvasOptionDailyMenu v-if="product.isMenu === 'SI'" :product="product"></MenuProductCardOffCanvasOptionDailyMenu>
						<InformationProduct :product="product" v-else/>
						<div class="modal" :id="'modalRemoveProduct_'+product.sku " tabindex="-1">
							<div class="modal-dialog modal-dialog-centered">
								<div class="modal-content">
									<div class="modal-header">
										<div class="modal-title h4">Eliminar Producto del Carrito</div>
										<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
									</div>
									<div class="modal-body">
										<p>¿Estás seguro de que deseas eliminar {{ product.name }} del carrito?</p>
									</div>
									<div class="modal-footer">
										<DismissButton :on-click="closeModal" content-btn-text="No, mantener producto"/>
										<button type="button" class="btn btn-danger" @click="confirmSubtractUnit">Eliminar</button>
									</div>
								</div>
							</div>
						</div>
					</template>

					<ProductCart/>
					<MenuModalRequestAccount :reservation-info="reservationInfo"></MenuModalRequestAccount>

				</div>
				<div id="modal-footer-section" class="modal-footer">
					<FooterBtnsAction :total-products="totalProducts" :cart-session="reservationInfo.cartSession"/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {mapGetters} from "vuex";
import ProductsCategory from "@/components/copilotCommander/components/ProductsCategory";
import FooterBtnsAction from "@/components/copilotCommander/components/FooterBtnsAction";
import DismissButton from "@/core/DismissButton";
import MenuProductCardOffCanvasOptionDailyMenu
	from "@/components/menuProductCardOffCanvasOptionDailyMenu/MenuProductCardOffCanvasOptionDailyMenu";
import InformationProduct from "@/components/copilotCommander/components/InformationProduct";
import ProductCart from "@/components/copilotCommander/components/ProductCart";
import MenuModalRequestAccount from "@/components/modalRequestAccount/ModalRequestAccount";
import TableIcon from "@/core/icons/TableIcon";

export default {
	name: "CopilotCommander",
	components: {
		TableIcon,
		MenuModalRequestAccount,
		ProductCart,
		InformationProduct,
		MenuProductCardOffCanvasOptionDailyMenu,
		DismissButton, FooterBtnsAction, ProductsCategory
	},
	inject: {
		cartSessionRepository: 'cartSessionRepository',
		userRepository: 'userRepository',
		menuRepository: 'menuRepository',
	},
	data() {
		return {
			id: 'copilot-commander',
			activeSection: null,
		};
	},
	watch: {
		filteredMenus() {
			this.$nextTick(() => {
				this.handleScroll();
			});
		}
	},
	computed: {
		...mapGetters('user', ['user']),
		...mapGetters('venue', ['resourceId', 'urlVenue', 'businessName', 'weOrderClientComments']),
		...mapGetters('copilot', ['commanderReservation']),
		...mapGetters('menu', ['menusVenue', 'productsCart', 'menus', 'getProduct']),

		filteredMenus() {
			const filterProducts = (products) => {
				return Array.isArray(products)
					? products
					: [];
			};

			const filterCategoriesRecursively = (category) => {
				if (!category) return null;

				const filteredChildren = Array.isArray(category.children)
					? category.children
						.map(filterCategoriesRecursively)
						.filter(child => child !== null)
					: [];

				const filteredMenuCategories = Array.isArray(category.menuCategories)
					? category.menuCategories
						.map(filterCategoriesRecursively)
						.filter(subCategory => subCategory !== null)
					: [];

				const filteredProducts = filterProducts(category.products);

				if (
					filteredChildren.length === 0 &&
					filteredMenuCategories.length === 0 &&
					filteredProducts.length === 0
				) {
					return null;
				}

				return {
					...category,
					children: filteredChildren,
					menuCategories: filteredMenuCategories,
					products: filteredProducts,
				};
			};

			return Array.isArray(this.menus)
				? this.menus
					.map(menu => ({
						...menu,
						menuCategories: Array.isArray(menu.menuCategories)
							? menu.menuCategories
								.map(filterCategoriesRecursively)
								.filter(category => category !== null)
							: [],
					}))
					.filter(menu => menu.menuCategories.length > 0)
				: [];
		},

		product() {
			return this.getProduct
		},

		totalProducts() {
			return this.productsCart.reduce((acumulator, product) => {
				return acumulator + product.cartItemCount;
			}, 0);
		},

		reservationInfo() {
			return this.commanderReservation
		},
	},
	methods: {
		handleScroll() {
			const container = this.$refs.scrollContainer;
			if (!container) return;

			const containerScrollTop = container.scrollTop;
			let closestSection = null;
			let closestDistance = Infinity;

			this.filteredMenus.forEach((menu) => {
				const element = document.getElementById(menu.id);
				if (element) {
					const elementTop = element.offsetTop;
					const distanceToTop = Math.abs(elementTop - containerScrollTop - 20);

					if (distanceToTop < closestDistance) {
						closestDistance = distanceToTop;
						closestSection = menu.id + '-' + menu.name;
					}
				}

				menu.menuCategories.forEach((category) => {
					const subElement = document.getElementById(`${category.id}-${category.name}`);
					if (subElement) {
						const subElementTop = subElement.offsetTop;
						const distanceToTop = Math.abs(subElementTop - containerScrollTop);

						if (distanceToTop < closestDistance) {
							closestDistance = distanceToTop;
							closestSection = `${category.id}-${category.name}`;

						}
					}
				});
			});

			if (closestSection !== this.activeSection) {
				this.activeSection = closestSection;
			}
		},
		scrollToSection(id) {
			const container = this.$refs.scrollContainer;
			const target = document.getElementById(id);

			if (container && target) {
				const containerOffset = container.getBoundingClientRect().top;
				const targetOffset = target.getBoundingClientRect().top;

				container.scrollTo({
					top: targetOffset - containerOffset + container.scrollTop,
				});

				this.activeSection = id;
			}
		},
		resetScrollToTop() {
			const container = this.$refs.scrollContainer;
			if (container) {
				container.scrollTo({ top: 0, behavior: 'instant' });
				this.activeSection = null;
			}
		},
	},
	mounted() {
		const modalElement = document.getElementById(this.id);
		if (modalElement) {
			modalElement.addEventListener('shown.bs.modal', () => {
				this.$nextTick(() => {
					const container = this.$refs.scrollContainer;
					if (container) {
						container.addEventListener('scroll', this.handleScroll);
						this.handleScroll();
					}
				});
			});
		}
	},
	beforeMount() {
		const container = this.$refs.scrollContainer;

		const modalElement = document.getElementById(this.id);
		if (modalElement) {
			modalElement.removeEventListener('shown.bs.modal', this.resetScrollToTop);
		}

		if (container) {
			container.removeEventListener('scroll', this.handleScroll);
		}
	},
};
</script>

<style scoped>
.modal-body {
	height: calc(100vh - 56px - 56px);
	overflow: hidden;
}
</style>

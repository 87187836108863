<template>
	<i class="fa-solid fa-user"></i>
</template>

<script>
export default {
	name: "UserIcon"
}
</script>

<style scoped>

</style>

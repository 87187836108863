<template>
	<template v-if="!isProductOption">
		<div class="btn-group-tpv">
			<button
				ref="buttons"
				class="btn shadow-none btn-outline-primary btn-tpv-sm border-0"
				style="height: 5rem; width: 5rem"
			>
				<i class="fa-solid fa-star fa-xl"></i>
			</button>
			<TpvCardProduct
				v-for="product in quickAccessProducts"
				:key="product.id"
				:product="product"
				@product-selected-with-options="viewProductOptions"
			/>
		</div>
	</template>
	<template v-else>
		<div class="btn-group-tpv">
			<button
				ref="buttons"
				class="btn btn-outline-secondary  btn-tpv-sm position-relative shadow-none"
				@click="closeProductSelectedForOptions"
			>
				Volver
			</button>
			<template
				v-for="option in selectedProductOptions"
				:key="option.id"
			>
				<button
					v-if="option.min === 0"
					ref="buttons"
					class="btn btn-primary btn-tpv-sm position-relative shadow-none"
					@click="selectedOption(null, option.id)"
				>
					Sin nada
				</button>
				<button
					v-for="choseOption in option.options"
					:key="choseOption.id"
					ref="buttons"
					class="btn btn-primary btn-tpv-sm position-relative shadow-none"
					@click="selectedOption(choseOption.id)"
				>
					{{ choseOption.name }}
				</button>
			</template>
		</div>
	</template>

	<MenuProductCardOffCanvasOptionDailyMenu v-if="productSelectedForOptions && productSelectedForOptions.isMenu === 'SI'" :product="productSelectedForOptions" />
	<InformationProduct
		v-if="productSelectedForOptions && productSelectedForOptions.productOptions && productSelectedForOptions.productOptions.length > 1 && productSelectedForOptions.isMenu === null"
		:product="productSelectedForOptions"
	/>
</template>

<script>
import {mapGetters} from "vuex";
import store from "@/store";

import TpvCardProduct from "@/components/tpv/tpvCardProduct/TpvCardProduct.vue";
import {normalizeChoices, showOffcanvas, viewSectionAccounts} from "@/utils";
import MenuProductCardOffCanvasOptionDailyMenu from "@/components/menuProductCardOffCanvasOptionDailyMenu/MenuProductCardOffCanvasOptionDailyMenu.vue";
import InformationProduct from "@/components/copilotCommander/components/InformationProduct.vue";

export default {
	name: 'TpvListProductsTopChoice',
	components: {InformationProduct, MenuProductCardOffCanvasOptionDailyMenu, TpvCardProduct},
	data() {
		return {
			isProductOption: false,
			selectedProductOptions: null,
			productSelectedForOptions: null,
		}
	},
	computed: {
		...mapGetters('menu', ['menus']),
		...mapGetters('tpv', ['isAddProductsCartInAccount']),

		quickAccessProducts() {
			const quickAccessProducts = [];

			this.menus.forEach(menu => {
				if (Array.isArray(menu.menuCategories)) {
					menu.menuCategories.forEach(menuCategory => {
						if (Array.isArray(menuCategory.children) && menuCategory.children.length > 0) {
							menuCategory.children.forEach(firtsChildren => {
								if (Array.isArray(firtsChildren.children) && firtsChildren.children.length > 0) {
									firtsChildren.children.forEach(secondChildren => {
										if (Array.isArray(secondChildren.menuCategories)) {
											secondChildren.menuCategories.forEach(category => {
												if (Array.isArray(category.products)) {
													category.products.forEach(product => {
														if (product.quickAccess.isQuickAccess) {
															quickAccessProducts.push(product);
														}
													});
												}
											});
										}
									});
								} else if (Array.isArray(firtsChildren.menuCategories)) {
									firtsChildren.menuCategories.forEach(category => {
										if (Array.isArray(category.products)) {
											category.products.forEach(product => {
												if (product.quickAccess.isQuickAccess) {
													quickAccessProducts.push(product);
												}
											});
										}
									});
								}
							});
						} else if (Array.isArray(menuCategory.products)) {
							menuCategory.products.forEach(product => {
								if (product.quickAccess.isQuickAccess) {
									quickAccessProducts.push(product);
								}
							});
						}
					});
				}
			});

			console.log(quickAccessProducts);

			return quickAccessProducts;
		},
	},
	mounted() {
		this.$nextTick(() => {
			this.adjustFontSize();
		});
	},
	beforeMount() {
		this.adjustFontSize()
	},
	methods: {
		viewProductOptions({product, productOptions, isProductOption}) {
			viewSectionAccounts()

			this.productSelectedForOptions = product;
			if (this.productSelectedForOptions.isMenu === 'SI') {
				console.log('Es Menu?', product.isMenu)
				this.$nextTick(() => {
					showOffcanvas(`offcanvas_${this.productSelectedForOptions.sku}`);
				})

				return
			}
			if (this.productSelectedForOptions && this.productSelectedForOptions.productOptions && this.productSelectedForOptions.productOptions.length > 1 && this.productSelectedForOptions.isMenu === null) {
				console.log('Es Producto con varias opciones?', product)
				this.$nextTick(() => {
					showOffcanvas(`offcanvas_${this.productSelectedForOptions.sku}`);
				})

				return
			}
			this.selectedProductOptions = productOptions;
			this.isProductOption = isProductOption;

			const normalizedOptions = normalizeChoices(this.selectedProductOptions);
			console.log('Emitting add-product with options:', normalizedOptions);
		},

		selectedOption(chosenOptionId) {
			const options = {};
			if (Array.isArray(this.selectedProductOptions)) {
				this.selectedProductOptions.forEach(option => {
					options[option.id] = [];
				});
			} else {
				console.warn('selectedProductOptions is not an array or is undefined:', this.selectedProductOptions);
			}

			const selectedOptionGroup = this.selectedProductOptions.find(option => option.options.some(o => o.id === chosenOptionId));
			if (selectedOptionGroup) {
				options[selectedOptionGroup.id] = [chosenOptionId];
			}

			viewSectionAccounts()

			store.commit('menu/addProductToCart', {
				product: this.productSelectedForOptions,
				units: 1,
				options: options
			});

			if (!this.isAddProductsCartInAccount) {
				store.commit('tpv/setViewSectionAccounts', "createAccount")
			}

			this.closeProductSelectedForOptions()
		},

		closeProductSelectedForOptions() {
			this.productSelectedForOptions = null;
			this.isProductOption = false;
		},

		adjustFontSize() {
			if (this.$refs.buttons) {
				let button = this.$refs.buttons;
				let fontSize = 14;
				button.style.fontSize = `${fontSize}px`;
				while (button.scrollHeight > button.offsetHeight && fontSize > 1) {
					fontSize -= 1;
					button.style.fontSize = `${fontSize}px`;
				}
			}
		}
	}
}
</script>


<template>
	<div :class="totalPages === 1 ? 'col-12 col-lg-12' : 'col-9 col-lg-10'">
		<ul class="nav nav-tabs">

			<li class="nav-item" v-for="zone in groupedTables" :key="zone.name">
				<button
					class="btn btn-outline-secondary btn-welcom btn-nav-tpv text-uppercase"
					:class="{ active: selectedZone === zone.name }"
					@click="selectZone(zone.name)"
					style="height: 4.3rem;width: 5rem"
					v-if="isChangeTable && zone.name !== 'Barra'"
				>
					{{ zone.name }}
				</button>

				<button
					class="btn btn-outline-secondary btn-welcom btn-nav-tpv text-uppercase"
					:class="{ active: selectedZone === zone.name }"
					@click="selectZone(zone.name)"
					style="height: 4.3rem;width: 5rem"
					v-if="!isChangeTable"
				>
					{{ zone.name }}
				</button>

			</li>

			<li class="nav-item text-muted align-content-center p-2" v-if="viewSectionAccounts === 'accountSelected' && isChangeTable">
				<span class="h5">
					Selecciona la mesa a la que deseas mover esta cuenta
				</span>
			</li>
		</ul>
		<div v-if="selectedZone !== 'Barra'" class="col-12 mt-1">
			<div class="row g-0">
				<div
					class="col-3 col-lg-2 col-xl-2"
					v-for="table in paginatedTables"
					:key="table.table_id"
				>
					<button
						class="btn btn-primary btn-welcom w-100 btn-tpv position-relative"
						style="height: 5rem;"
						:class="{'btn-secondary' : isTableOccupied(table) && isChangeTable}"
						@click="selectedTable(table)"
					>
						<span class="fs-5">
							{{ table.name }}
						</span>
						<span
							v-if="isTableOccupied(table)"
							class="position-absolute  bottom-0 w-100 start-0 badge"
							:class="{'badge-primary' : isTableOccupied(table) && isChangeTable, 'badge-secondary' : !(isTableOccupied(table) && isChangeTable)}"
							style="z-index: 1; top: auto"
						>
							<span class="w-100 text-truncate">
								{{ isTableOccupied(table).pax }} Pax
								<span class="vr" v-if="isTableOccupied(table).name"></span>
								{{ isTableOccupied(table).name }}
							</span>
						</span>
						<span
							v-if="hasDubbing(table)"
							class="position-absolute top-0 badge badge-danger pulse"
							style="z-index: 1;"
						>
							{{ remainingTimeToReservation(table) }}
						</span>
					</button>
				</div>
			</div>
		</div>
		<div v-if="selectedZone === 'Barra'" class="col-12 mt-1">
			<div class="row g-0">
				<div
					class="col-3 col-lg-2 col-xl-2"
					v-for="table in paginatedTables"
					:key="table.table_id"
				>
					<button
						class="btn btn-primary btn-welcom w-100 btn-tpv position-relative"
						style="height: 5rem;"
						:class="{'btn-secondary' : isTableOccupied(table) && isChangeTable}"
						@click="selectedTable(table)"
						v-if="isTableOccupied(table)"
					>
						<span class="fs-5">
							{{ isTableOccupied(table).locationTag }}
						</span>
						<span
							v-if="isTableOccupied(table)"
							class="position-absolute badge bottom-0 w-100 start-0"
							:class="{'badge-primary' : isTableOccupied(table) && isChangeTable, 'badge-secondary' : !(isTableOccupied(table) && isChangeTable)}"
							style="z-index: 1; top: auto"
						>
							<span class="text-truncate w-100">
								{{ isTableOccupied(table).pax }} Pax
								<span class="vr" v-if="isTableOccupied(table).name"></span>
								{{ isTableOccupied(table).name }}
							</span>
						</span>

						<span
							v-if="hasDubbing(table)"
							class="position-absolute top-0 badge badge-danger pulse"
							style="z-index: 1;"
						>
							{{ remainingTimeToReservation(table) }}
						</span>
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import store from "@/store";
import {mapGetters} from "vuex";
import {walkingService} from "@/service/walkingService";

export default {
	name: "tpvTables",
	inject: {
		copilotRepository: 'copilotRepository',
		cartSessionRepository: 'cartSessionRepository',
	},
	data() {
		return {
			currentPage: {},
			pageSize: 500,
			selectedZone: null,
		};
	},
	computed: {
		...mapGetters('tpv', ['viewSectionAccounts', 'accountSelectedId', 'isChangeTable', 'selectedNumberPax','isSelectedPax']),
		...mapGetters('copilot', ['reservations', 'tables','selectedRole']),
		...mapGetters('user', ['user']),
		...mapGetters('menu', ['productsCart']),

		groupedTables() {
			return this.tables.reduce((acc, table) => {
				const roomName = table.room;

				let zone = acc.find((item) => item.name === roomName);

				if (!zone) {
					zone = {name: roomName, tables: []};
					acc.push(zone);
				}

				if (!this.currentPage[roomName]) {
					this.currentPage[roomName] = 1;
				}

				zone.tables.push(table);

				return acc;
			}, []);
		},

		paginatedTables() {
			if (!this.selectedZone) return [];
			const selectedZoneTables =
				this.groupedTables.find((zone) => zone.name === this.selectedZone)?.tables || [];
			const start = (this.currentPage[this.selectedZone] - 1) * this.pageSize;
			const end = start + this.pageSize;
			return selectedZoneTables.slice(start, end);
		},

		totalPages() {
			if (!this.selectedZone) return 1;
			const selectedZoneTables =
				this.groupedTables.find((zone) => zone.name === this.selectedZone)?.tables || [];
			return Math.ceil(selectedZoneTables.length / this.pageSize);
		},

		tablesOccupied() {
			const occupiedTableIds = this.reservations.map(reservation => reservation.table);
			return this.tables.filter(table => occupiedTableIds.includes(table.name));
		},

		reserveSelected() {
			if(!this.accountSelectedId){
				return null
			}
			return this.reservations.find(reservation => reservation.id === this.accountSelectedId)
		}
	},
	methods: {
		selectZone(zoneName) {
			this.selectedZone = zoneName;
		},


		async updateNotifications() {
			await store.dispatch('copilot/updateOrdersAndNotifications')
		},

		async selectedTable(table) {
			console.log(table)
			this.$emit('selected-table', table)

			if (this.viewSectionAccounts === 'createAccount') {

				if (this.isTableOccupied(table)) {
					store.commit('tpv/setIsLoading', true)
					const reservation = this.isTableOccupied(table);
					const orderInfo = {
						name: this.user.name,
						welKey: this.user.welKey,
						userId: this.user.id,
						pax: reservation.pax,
						source: 2,
						clientMessage: this.cartClientMessage,
						orderReservationName: this.user.name,
						reservationId: reservation.id,
						av: 0,
						roleId: this.selectedRole
					};

					const products = this.productsCart.flatMap(product => {
						if (product.isUnitary === "SI") {
							return {
								sku: product.sku,
								options: product.chosenOptions,
								units: product.cartItemCount,
								modifyDishClass: product.modifyDishClass?.id,
								isShareable: product.isShareable
							}
						} else {
							return Array(product.cartItemCount).fill({
								sku: product.sku,
								options: product.chosenOptions,
								modifyDishClass: product.modifyDishClass?.id,
								isShareable: product.isShareable
							});
						}
					});

					const resultOrder = await this.cartSessionRepository.createCartSessionOrder(orderInfo, products);
					if (resultOrder.result === 0) {
						console.log('se ha enviando el nuevo pedido de ', {result: reservation, order: orderInfo, products: products});

						// store.commit('tpv/setAccountSelectedId', reservation.id)
						// store.commit("tpv/setViewSectionAccounts", 'accountSelected');
						store.commit("tpv/setViewSectionAccounts", 'allAccounts');
						store.commit('menu/setProductsCart', {productsCart: []});
						store.commit('tpv/setViewSectionItems', 'topChoice');
						store.commit('tpv/setNewAccount', null);
						store.commit('tpv/setIsLoading', false)
					} else {
						store.commit("copilot/setToast", {toast: {toastMessage: 'No se ha podido asignar a la cuenta.', showToast: true, idToast: "incorrectlyToast"}});
						store.commit('tpv/setIsLoading', false);
					}
				} else {
					const tables = [table.table_id];
					const products = this.productsCart.flatMap(product => {
						if (product.isUnitary === "SI") {
							return {
								sku: product.sku,
								options: product.chosenOptions,
								units: product.cartItemCount,
								modifyDishClass: product.modifyDishClass?.id,
								isShareable: product.isShareable
							}
						} else {
							return Array(product.cartItemCount).fill({
								sku: product.sku,
								options: product.chosenOptions,
								modifyDishClass: product.modifyDishClass?.id,
								isShareable: product.isShareable
							});
						}
					});
					const data = {
						products,
						tables,
						roleId: this.selectedRole
					};

					if(!this.isSelectedPax) {
						store.commit('tpv/setViewSectionItems', 'selectedPax');
						store.commit('tpv/setNewAccount', data)
						return
					}

					data.pax = this.selectedNumberPax;

					store.commit('tpv/setIsLoading', true);

					let resultWalkin = await walkingService.create(data);
					console.log("El resultado de crear el walkin", resultWalkin);

					if (resultWalkin.result === 0) {
						console.log({result: resultWalkin});
						store.commit("tpv/setViewSectionAccounts", 'allAccounts');
						store.commit('menu/setProductsCart', {productsCart: []});
						store.commit('tpv/setViewSectionItems', 'topChoice');
						store.commit('tpv/setNewAccount', null);
						store.commit('tpv/setIsLoading', false)

					} else {
						store.commit("copilot/setToast", {toast: {toastMessage: 'No se ha podido crear la cuenta.', showToast: true, idToast: "incorrectlyToast"}});
						store.commit('tpv/setIsLoading', false);
					}
				}
			}

			if (this.viewSectionAccounts === 'accountSelected') {
				store.commit('tpv/setIsLoading', true);

				const result = await this.copilotRepository.updateTable({
					reservationWid: this.accountSelectedId,
					tables: [table.table_id]
				})
				if (result.result === 0) {
					console.log('Todo correcto');
					store.commit('tpv/setIsLoading', false);

				} else {
					store.commit("copilot/setToast", {
						toast: {
							toastMessage: `No se ha podido cambiar de mesa.`,
							showToast: true,
							idToast: "incorrectlyToast"
						}
					});
					store.commit('tpv/setIsLoading', false);
				}
			}

			if (this.isChangeTable) {
				if (this.isTableOccupied(table)) {
					const reservation = this.isTableOccupied(table);
					store.commit("tpv/setAccountSelectedId", reservation.id);
					store.commit("tpv/setViewSectionAccounts", 'accountSelected');
					store.commit('tpv/setIsChangeTable', false)
				}
			}

/*			if (this.viewSectionAccounts === 'allAccounts') {
				if (this.isTableOccupied(table)) {
					const reservation = this.isTableOccupied(table);
					this.$store.commit("tpv/setAccountSelectedId", reservation.id);
					this.$store.commit("tpv/setViewSectionAccounts", 'accountSelected');
				}
			}*/
		},

		isTableOccupied(table) {
			return this.reservations.find(reservation =>
				reservation.table === table.name &&
				reservation.menuAccessEnabled === 1 &&
				reservation.status !== 7 &&
				reservation.status !== 10
			);
		},

		hasDubbing(tableSelected) {
			const currentReservation = this.isTableOccupied(tableSelected);

			if (!currentReservation || !currentReservation.endDateTime || !currentReservation.table) {
				return false;
			}

			const endDate = new Date(currentReservation.endDateTime);
			const table = currentReservation.table;

			return this.reservations.some(other => {
				if (!other || !other.startDateTime || !other.table) {
					return false;
				}

				if (!other.finishedDateTime && !currentReservation.finishedDateTime && other.table === table && other.id !== currentReservation.id) {
					const startDate = new Date(other.startDateTime);
					const timeDifference = (startDate - endDate) / (1000 * 60);
					return timeDifference <= 30 && timeDifference > 0;
				}
				return false;
			});
		},

		remainingTimeToReservation(tableSelected) {
			const currentReservation = this.isTableOccupied(tableSelected);

			// If there's no reservation or the reservation doesn't have a start time, return 'N/A'
			if (!currentReservation || !currentReservation.startDateTime) {
				return 'N/A';
			}

			const now = new Date();
			const reservationStartTime = new Date(currentReservation.startDateTime);

			// Check if the reservation has already started
			if (reservationStartTime <= now) {
				return 'Reservation in progress';
			}

			const timeDifference = reservationStartTime - now;

			// Convert the time difference from milliseconds to minutes
			const minutesUntilStart = Math.round(timeDifference / 60000);

			// Convert minutes to a more readable format, like hours and minutes
			const hours = Math.floor(minutesUntilStart / 60);
			const minutes = minutesUntilStart % 60;

			// Create a readable string for the time remaining
			return `${hours}h ${minutes}m until start`;
		}

	},
	mounted() {
		if (this.groupedTables.length > 0) {
			this.selectedZone = this.groupedTables[0].name;
		}

	},
};
</script>

<style scoped>

</style>

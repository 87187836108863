<template>
		<button
			ref="buttons"
			:disabled="!availability"
			class="btn btn-primary btn-tpv-sm position-relative shadow-none d-flex flex-column"
			style="text-align: initial;"
			@click="selectProduct(product)"
		>
			{{ product.shortName ? product.shortName : product.name }}

			<span class="position-absolute badge badge-info" style="bottom: 0;top: auto; right: 0; z-index: 1;">
				{{formatFloat(product.price)}}
			</span>
		</button>
</template>

<script>

import store from "@/store";
import {viewSectionAccounts} from "@/utils";
import {mapGetters} from "vuex";

export default {
	name: "TpvCardProduct",
	components: {},
	props:{
		product:{
			type: Object,
			required: true
		}
	},
	inject: {
		dateFormatterService: 'dateFormatterService',
	},
	computed:{
		...mapGetters('tpv',['isAddProductsCartInAccount']),
		hasProductOptions() {
			return Array.isArray(this.product.productOptions) && this.product.productOptions.length > 0;
		},

		availability() {
			return this.product.menuCategoryProductInfo.availability === 1
		},
	},
	mounted() {
		this.$nextTick(() => {
			this.adjustFontSize();
		});
	},
	beforeMount() {
		this.adjustFontSize()
	},
	methods:{
		selectProduct(product) {

			viewSectionAccounts();

			if (product.productOptions && product.productOptions.length) {
				this.$emit('product-selected-with-options', {
					product: product,
					productOptions: product.productOptions,
					isProductOption: true
				});
			} else {
				store.commit('menu/addProductToCart', { product: product, units:1, options:product.productOptions });
				if (!this.isAddProductsCartInAccount) {
					store.commit('tpv/setViewSectionAccounts', "createAccount")
				}
			}
		},

		formatFloat(price) {
			if (isNaN(price)) {
				return price;
			}
			return this.dateFormatterService.formattedPriceEmployed(price)
		},

		adjustFontSize() {
			if (this.$refs.buttons) {
				let button = this.$refs.buttons;
				let fontSize = 14;
				button.style.fontSize = `${fontSize}px`;
				while (button.scrollHeight > button.offsetHeight && fontSize > 1) {
					fontSize -= 1;
					button.style.fontSize = `${fontSize}px`;
				}
			}
		}
	}
}
</script>

<style scoped>

</style>

<template>
	<div class="text-truncate text-uppercase ps-1 fw-bold" v-if="reservationName">
		{{ reservationName }}
	</div>
</template>

<script>
export default {
	name: "ReservationName",
	props:{
		reservationName: {
			type: String,
			required: true
		}
	}
}
</script>

<style scoped>

</style>

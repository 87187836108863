<template>
	<UserIcon />
	<span class="ps-1">{{ paxDisplay }}</span>
</template>

<script>
import UserIcon from "@/core/icons/UserIcon.vue";

export default {
	name: "ReservationPax",
	components: { UserIcon },
	props: {
		pax: {
			type: Number,
			required: true
		}
	},
	computed: {
		paxDisplay() {
			return this.pax ? `${this.pax}` : "";
		}
	}
}
</script>

<style scoped>

</style>

<template>
	<span v-if="statusRemove" class="badge badge-primary">
		Quitado
	</span>
	<span v-if="statusDiminished" class="badge badge-primary">
		Mermado
	</span>
	<span v-if="statusPaid" class="badge badge-success">
		Pagado
	</span>
</template>

<script>
export default {
	name: "ProductOrderStatus",
	props: {
		productOrder: {
			type: Object,
			required: true
		}
	},
	computed: {
		statusRemove() {
			return this.productOrder.statusProductOrder === -1
		},
		statusDiminished() {
			return this.productOrder.statusProductOrder === -3
		},
		statusPaid() {
			return this.productOrder.paymentStatus === 1
		},
	}
}
</script>

<style scoped>

</style>

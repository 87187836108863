<template src="./copilotOrdersContainer.html"></template>

<script>

import OrdersProductCard from "@/components/ordersProductCard/OrdersProductCard";
import {mapGetters} from "vuex";
import TableIcon from "@/core/icons/TableIcon";
import {useI18n} from 'vue-i18n';
import OrdersProductMenuDailyCard from "@/components/ordersProductCard/OrdersProductMenuDailyCard";

export default {
	name: 'CopilotOrdersContainer',
	inject: {
		reservationRepository: 'reservationRepository',
		userRepository: 'userRepository',
		cartSessionRepository: 'cartSessionRepository',
		dateFormatterService: 'dateFormatterService',
	},
	emits: [],
	props: {
		reservations: Array,
	},
	data() {
		return {
			buttonTextOrder: 'Comanda anotada en TPV',
			previousFirstOrder: null,
			isLoadingOrder:false,
			elapsedTime: '',
			isLoadingOrderKitchen: false,

			isLoading:false
		};
	},
	computed: {
		...mapGetters('copilot',['pendingOrders', 'isKitchenSelectedRole', 'selectedRole', 'isOnlyRoom']),
		sectionTitle(){
			if(this.isKitchenSelectedRole) return this.t('copilot.cocinarPedido');
			return this.notificationType === 'readyOrder' ? this.t('copilot.pedidoListo') : this.t('copilot.nuevaComanda')
		},
		notificationType() {
			if (!this.isLoadingOrder && this.pendingOrders && this.pendingOrders[0]) {
				const cartSessionStatus = this.pendingOrders[0].cartSessionStatus;

				return cartSessionStatus !== 3 ? 'newOrder' : 'readyOrder';
			}
			return null;
		},
		buttonTextOrderLoaded(){
			return this.notificationType === 'readyOrder'
				? 'Pedido servido'
				: (this.isKitchenSelectedRole ? this.t('copilot.pedidoListoParaServir') : this.t('copilot.comandaAnotadaTPV'));
		},
		buttonTextOrderLoading(){
			return this.isKitchenSelectedRole
				? `Enviando pedido <i class="fas fa-spinner fa-spin"></i>`
				: `Anotando comanda <i class="fas fa-spinner fa-spin"></i>`;
		},

		groupedOrdersMenuDaily(){
			if (!this.pendingOrders || !this.pendingOrders[0] || typeof this.pendingOrders[0].items !== 'object') {
				return {};
			}
			const items = this.pendingOrders[0].items;

			const menusArray = Object.values(items)
				.filter(product => product.isMenu === "SI")
				.map((product, index) => ({ ...product, index }));

			const groupedMap = new Map();

			menusArray.forEach(order => {
				if (!groupedMap.has(order.productSku)) {
					groupedMap.set(order.productSku, {
						productSku: order.productSku,
						name: order.name,
						quantity: 0,
						choices: new Map()
					});
				}

				const group = groupedMap.get(order.productSku);
				group.quantity += order.count;


				order.choices.forEach(choice => {
					if (!group.choices.has(choice.type)) {
						group.choices.set(choice.type, new Map());
					}
					const typeGroup = group.choices.get(choice.type);

					typeGroup.set(choice.choice, (typeGroup.get(choice.choice) || 0) + order.count);
				});
			});

			return Array.from(groupedMap.values()).map(group => ({
				productSku: group.productSku,
				name: group.name,
				quantity: group.quantity,
				choices: Array.from(group.choices.entries()).map(([type, optionsMap]) => ({
					type,
					options: Array.from(optionsMap.entries()).map(([name, quantity]) => ({
						name,
						quantity
					}))
				}))
			}));
		},
		groupedOrders() {
			// Verificar si hay datos válidos en `pendingOrders`
			if (!this.pendingOrders || !this.pendingOrders[0] || typeof this.pendingOrders[0].items !== 'object') {
				return {};
			}

			const items = this.pendingOrders[0].items;
			const filteredItems = Object.fromEntries(
				Object.entries(items).filter(
					([, product]) => product.isMenu !== "SI"
				)
			);

			const grouped = {
				"Sin Categoría": [] // Grupo especial para los que no tienen dishClass
			};

			for (const key in filteredItems) {
				const order = filteredItems[key];

				// Verificar si tiene dishClass.name
				if (order.dishClass?.name) {
					if (!grouped[order.dishClass.name]) {
						grouped[order.dishClass.name] = [];
					}
					grouped[order.dishClass.name].push(order);
				} else {
					// Si no tiene dishClass, agrégalo al grupo "Sin DishClass"
					grouped["Sin Categoría"].push(order);
				}
			}

			// Ordenar cada grupo (excepto "Sin DishClass") por `dishClass.sortOrder`
			for (const dishName in grouped) {
				if (dishName !== "Sin Categoría") {
					grouped[dishName].sort((a, b) => {
						return (a.dishClass?.sortOrder || 0) - (b.dishClass?.sortOrder || 0);
					});
				}
			}

			// Retornar los grupos con "Sin DishClass" al principio
			return {
				"Sin Categoría": grouped["Sin Categoría"],
				...Object.keys(grouped)
					.filter(name => name !== "Sin Categoría")
					.sort() // Ordena los nombres de las claves alfabéticamente
					.reduce((sorted, name) => {
						sorted[name] = grouped[name];
						return sorted;
					}, {})
			};
		}

	},
	watch: {
		isKitchenSelectedRole(){
			this.buttonTextOrder = this.buttonTextOrderLoaded;
			// this.buttonTextOrder = this.notificationType === 'readyOrder' ? 'Pedido servido' : this.buttonTextOrder;
		},
		pendingOrders(){
			this.buttonTextOrder = this.buttonTextOrderLoaded;
			// this.buttonTextOrder = this.notificationType === 'readyOrder' ? 'Pedido servido' : this.buttonTextOrder;
		},
	},
	components: {
		'orders-product-card': OrdersProductCard,
		'table-icon': TableIcon,
		OrdersProductMenuDailyCard
	},
	setup() {
		const { t } = useI18n();
		return { t };
	},
	beforeMount() {
	},
	async mounted() {
		this.buttonTextOrder = this.buttonTextOrderLoaded;
		// this.buttonTextOrder = this.notificationType === 'readyOrder' ? 'Pedido servido' : this.buttonTextOrder;
		document.addEventListener('buttonTextOrderUpdated', () => {
			this.buttonTextOrder = this.isKitchenSelectedRole ? this.t('copilot.pedidoListoParaServir') : this.t('copilot.comandaAnotadaTPV');
		});

		this.updateElapsedTime();

		setInterval(() => {
			this.updateElapsedTime();
		}, 1000);
	},
	methods: {

		async handleProcessOrder(cartSessionOrderId) {
			// this.isLoadingNewOrder = true;
			this.isLoading = true
			console.log(this.pendingOrders[0])
			// this.buttonTextOrder = this.isKitchenSelectedRole ? `Enviando pedido <i class="fas fa-spinner fa-spin"></i>` : `Anotando comanda <i class="fas fa-spinner fa-spin"></i>`
			this.buttonTextOrder = this.buttonTextOrderLoading;
			const resultProcessOrder = await this.cartSessionRepository.processOrder(cartSessionOrderId, this.selectedRole);
			this.isLoadingOrder = true;

			if (resultProcessOrder.result === 0) {
				this.isLoading = false
				// this.isLoadingNewOrder = false;
				setTimeout(() => {
					this.isLoadingOrder = false;
				}, 1000);
			}
		},

		updateElapsedTime() {
			if (!this.pendingOrders.length || !this.pendingOrders[0].createdDateTime) {
				return;
			}
			this.elapsedTime = this.dateFormatterService.formattedTimeChronometer(this.pendingOrders[0].createdDateTime)
		}

	},
}
</script>

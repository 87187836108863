<template>
	<button
		class="btn rounded-end-5 w-100"
		:class="[!reservationLocationTag ? ' btn-secondary': 'btn-light']"
		type="button"
		data-bs-toggle="dropdown"
		aria-expanded="false"
		id="dropdown-selected-tag"
		@click.stop="handleViewSection"
	>
		<span class="text-wrap" v-if="!reservationLocationTag">
			Zona en Barra ?
		</span>
		<span class="text-wrap" v-else>
			{{ reservationLocationTag }}
		</span>
	</button>
	<ul class="dropdown-menu bg-white p-1 custom-dropdown-menu" aria-labelledby="dropdown-selected-tag">
		<li>
			<form action="" @submit.prevent.stop="addLocationTag(reservationId)">
				<div class="input-group">
					<input type="text" @click.stop autofocus class="form-control" style="height: 50px;" placeholder="Ubicación en Barra" aria-label="Ubicación en Barra" aria-describedby="basic-addon1" v-model="locationTagName">
					<button :disabled="!locationTagName.length" class="btn btn-primary">
						Agregar
					</button>
				</div>
			</form>
		</li>
		<li
			v-for="locationTag in reservationLocationTags"
			:key="locationTag.id"
		>
			<button
				class="dropdown-item text-primary"
				@click="handleSelectedTag(locationTag.name, reservationId)"
				style="height: 50px;"
			>
				{{ locationTag.name }}
			</button>
		</li>
	</ul>
</template>

<script>
import {closeDropdown, hideAllModals, viewSectionAccounts} from "@/utils";
import {mapGetters} from "vuex";
import store from "@/store";
import {reservationRepository} from "@/service/repository/reservationRepository";

export default {
	name: "TpvDropdownReservationUpdateTable",
	inject: {
		reservationRepository: 'reservationRepository',
	},
	props:{
		reservationLocationTag: {
			type: String,
			required: true
		},
		reservationId: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			locationTagName: '',
		}
	},
	computed:{
		...mapGetters('venue', ['reservationLocationTags']),

	},
	methods:{
		handleViewSection() {
			viewSectionAccounts();
		},

		async handleSelectedTag(nameTag, reservationId) {
			store.commit('tpv/setIsLoading', true)

			const data = {
				reservationId: reservationId,
				locationTag: nameTag
			}
			const result = await reservationRepository.updateLocationTag(data);
			if (result.result === 0) {
				store.commit('tpv/setViewSectionAccounts', 'allAccounts');
				store.commit('tpv/setViewSectionItems', 'topChoice');
				store.commit('tpv/setIsLoading', false)
				closeDropdown('dropdown-selected-tag')
				hideAllModals()
			} else {
				store.commit('tpv/setIsLoading', false)
				store.commit("copilot/setToast", {toast: {toastMessage: 'No se han podido agregar la zona en barra.', showToast: true, idToast: "incorrectlyToast"}});
			}
		},

		async addLocationTag(reservationId) {
			store.commit('tpv/setIsLoading', true)
			viewSectionAccounts()
			const data = {
				reservationId: reservationId,
				locationTag: this.locationTagName
			}
			const result = await reservationRepository.updateLocationTag(data);
			if (result.result === 0) {
				store.commit('tpv/setViewSectionAccounts', 'allAccounts');
				store.commit('tpv/setViewSectionItems', 'topChoice');
				store.commit('tpv/setIsLoading', false)
				closeDropdown('dropdown-selected-tag');
				hideAllModals()
			} else {
				store.commit('tpv/setIsLoading', false)
				store.commit("copilot/setToast", {toast: {toastMessage: 'No se han podido agregar la zona en barra.', showToast: true, idToast: "incorrectlyToast"}});
			}
		},
	}
}
</script>

<style scoped>
.custom-dropdown-menu {
	position: fixed !important;
	max-height: 300px;
	overflow-y: auto;
	z-index: 1050;
}

</style>

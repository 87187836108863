class DateFormatterService {
	constructor() {

	}

	formattedDate(dateString) {
		const selectedDate = new Date(dateString);
		const today = new Date();
		const yesterday = new Date(today);
		yesterday.setDate(yesterday.getDate() - 1);
		const tomorrow = new Date(today);
		tomorrow.setDate(tomorrow.getDate() + 1);

		const getMonthName = (monthIndex) => {
			const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
			return months[monthIndex];
		};

		const getDayName = (dayIndex) => {
			const days = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
			return days[dayIndex];
		};

		if (selectedDate.toDateString() === today.toDateString()) {
			return `HOY ${getDayName(selectedDate.getDay())} ${selectedDate.getDate()} de ${getMonthName(selectedDate.getMonth())}`;
		} else if (selectedDate.toDateString() === yesterday.toDateString()) {
			return `AYER ${getDayName(selectedDate.getDay())} ${selectedDate.getDate()} de ${getMonthName(selectedDate.getMonth())}`;
		} else if (selectedDate.toDateString() === tomorrow.toDateString()) {
			return `MAÑANA ${getDayName(selectedDate.getDay())} ${selectedDate.getDate()} de ${getMonthName(selectedDate.getMonth())}`;
		} else {
			const dayOfWeek = getDayName(selectedDate.getDay());
			return `${dayOfWeek} ${selectedDate.getDate()} de ${getMonthName(selectedDate.getMonth())}`;
		}
	}

	formattedTime(dateString) {
		const dateTime = dateString.split(' ');
		const [hour, minutes] = dateTime[1].split(':');

		const formattedTime = `${hour}:${minutes}`;

		return formattedTime;
	}

	formattedPrice(price) {
		if (isNaN(price)) {
			return price;
		}
		let formatted = parseFloat(price).toFixed(2).replace(".", ",") + '€';

		if (formatted.endsWith('00€')) {
			formatted = formatted.slice(0, -4) + '€';
		}

		if (formatted.includes(',') && formatted.endsWith('0€')) {
			formatted = formatted.slice(0, -2);
			formatted = formatted.replace(',', ',') + '€';
		}
		return formatted;
	}

	formattedPriceEmployed(price) {
		if (isNaN(price)) {
			return price;
		}
		// Aseguramos que el precio sea un número flotante y lo convertimos a texto con dos decimales
		let formatted = parseFloat(price).toFixed(2).replace(".", ",");
		return formatted;
	}


	formattedTimeChronometer(time){
		if (!time) {
			return;
		}
		const startTimeFormatted = this.formattedTime(time);

		const currentDate = new Date();

		const startDate = new Date(currentDate.toDateString() + ' ' + startTimeFormatted);

		const currentTime = new Date();

		const difference = Math.floor((currentTime.getTime() - startDate.getTime()) / 1000);

		const hours = Math.floor(difference / 3600);
		const minutes = Math.floor((difference % 3600) / 60);
		const seconds = difference % 60;

		if (hours > 0) {
			return `${hours}${hours > 1 ? 'h' : 'h'}`;
		} else if (minutes > 0) {
			return `${minutes}${minutes > 1 ? '\'' : '\''}`;
		} else if (seconds > 0) {
			// return `${seconds} ${seconds > 1 ? '' : ''}`
			return `New`;
		} else {
			return startTimeFormatted
		}
	}

}

export const dateFormatterService = new DateFormatterService();

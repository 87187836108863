<template>
	<ul class="list-group">
		<li class="list-group-item">
			<div class="row">
				<div class="col-2">
					Hora
				</div>
				<div class="col-2">
					Pax
				</div>
				<div class="col-5">
					Zona <span class="vr mx-1"></span> Mesa
				</div>
			</div>
		</li>
	</ul>

	<TpvListAccounts :reservationsActive="activeAccounts"/>

	<div class="btn-group btn-group-lg w-100" v-if="listProductsSelected.length">
		<button class="btn btn-primary btn-tpv shadow-none d-flex flex-column align-items-center justify-content-center" @click="lossProduct">
			<BrokenCupIcon/>
			Mermar
		</button>
		<button class="btn btn-primary btn-tpv shadow-none d-flex flex-column align-items-center justify-content-center" @click="removeProduct">
			<i class="fa-solid fa-xmark"></i>
			Quitar
		</button>
		<button class="btn btn-primary btn-tpv shadow-none d-flex flex-column align-items-center justify-content-center" @click="handleMoveProducts">
			<i class="fa-solid fa-share"></i>
			Mover
		</button>
		<button class="btn btn-primary btn-tpv shadow-none d-flex flex-column align-items-center justify-content-center" @click="handlePartialPayment">
			Pago parcial
		</button>
	</div>
</template>

<script>
import store from "@/store";
import {mapActions, mapGetters} from "vuex";

import {hideAllModals, showModal} from "@/utils";
import BrokenCupIcon from "@/core/icons/BrokenCupIcon.vue";
import {reservationRepository} from "@/service/repository/reservationRepository";
import TpvListAccounts from "@/components/tpv/tpvListAccounts/TpvListAccounts.vue";

export default {
	name: "TpvAccounts",
	components: {TpvListAccounts, BrokenCupIcon},
	inject: {
		cartSessionRepository: 'cartSessionRepository',
		copilotRepository: 'copilotRepository',
		reservationRepository: 'reservationRepository',
	},
	data() {
		return {

			activeAccountId: null,

		}
	},
	computed: {
		...mapGetters('tpv', ['accountSelectedId', 'viewSectionItems', 'viewSectionAccounts', 'isChangeTable', 'listProductsSelected']),
		...mapGetters('copilot', ['reservations']),
		...mapGetters('user', ['user']),

		activeAccounts() {
			return this.reservations.filter(reservation =>
				reservation.menuAccessEnabled && !reservation.finishedDateTime
			);
		},


	},
	methods: {
		...mapActions('weWingman', ['printTicket', 'printBill']),


		handleMoveProducts(reservationId) {
			console.log(this.selectedProducts);
			store.commit('tpv/setAccountSelectedId', reservationId);
			store.commit('tpv/setIsAccountMoveProducts', true)

			this.$nextTick(() => {
				showModal('tpv-modal-selected-table');
			})
		},

		async lossProduct() {
			store.commit('tpv/setIsLoading', true);

			const result = await this.copilotRepository.markProductOrderAsLoss({productOrderIds: this.listProductsSelected});
			console.log({result: result})
			if (result.result === 0) {
				store.commit('tpv/clearListProductsSelected')
				store.commit('tpv/setIsLoading', false);
			} else {
				store.commit("copilot/setToast", {
					toast: {
						toastMessage: `No se ha podido Mermar el producto correctamente.`,
						showToast: true,
						idToast: "incorrectlyToast"
					}
				});
				store.commit('tpv/setIsLoading', false);

			}
		},

		async removeProduct() {
			store.commit('tpv/setIsLoading', true)
			const result = await this.copilotRepository.markProductOrderAsCancelled({productOrderIds: this.listProductsSelected});
			console.log({result: result})
			if (result.result === 0) {
				store.commit('tpv/clearListProductsSelected')
				store.commit('tpv/setIsLoading', false)
			} else {
				store.commit("copilot/setToast", {
					toast: {
						toastMessage: `No se ha podido quitar el producto correctamente.`,
						showToast: true,
						idToast: "incorrectlyToast"
					}
				});
				store.commit('tpv/setIsLoading', false)
			}
		},

		async handlePartialPayment() {
			store.commit('tpv/setIsLoading', true)
			const data = {
				productOrderIds: this.listProductsSelected
			}
			const result = await reservationRepository.markProductOrdersPaid(data);
			if (result.result === 0) {
				if (result.bill != null) {
					console.log("Todo ha ido bien, imprimiendo bill")
					await this.printBill(result.bill);
				}
				store.commit('tpv/setViewSectionAccounts', 'allAccounts');
				store.commit('tpv/setViewSectionItems', 'topChoice');
				store.commit('tpv/setIsLoading', false)
				store.commit('tpv/clearListProductsSelected')
				store.commit('tpv/clearListProductsSelected')
				hideAllModals()
			} else {
				console.log("Error imprimiendo bill")
				store.commit('tpv/setIsLoading', false)
				store.commit("copilot/setToast", {toast: {toastMessage: 'No se han podido marcar los productos como pagados.', showToast: true, idToast: "incorrectlyToast"}});
			}
		},


	}
}
</script>
